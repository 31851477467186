import "./youtube-embed.scss";

export interface YoutubeEmbedProps {
  videoId: string;
  width?: string;
  height?: string;
}

export function YoutubeEmbed({ videoId, width, height }: YoutubeEmbedProps) {
  return (
    <div id="YoutubeEmbed" className="youtube-embed">
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube"
      />
    </div>
  );
}

export default YoutubeEmbed;
