import { ReactNode, createContext, useContext } from "react";
import { useFilterPayroll } from "./filter-payroll.hook";

const FilterPayrollContext = createContext(
  {} as ReturnType<typeof useFilterPayroll>
);
export function useFilterPayrollContext() {
  return useContext(FilterPayrollContext);
}

interface FilterPayrollProviderProps {
  children: ReactNode;
}
export function FilterPayrollProvider({
  children,
}: FilterPayrollProviderProps) {
  return (
    <FilterPayrollContext.Provider value={useFilterPayroll()}>
      {children}
    </FilterPayrollContext.Provider>
  );
}