import { zodResolver } from "@hookform/resolvers/zod";
import { UtilBranchControllerGet200Response } from "@api/api";
import { BranchFormRule, BranchFormSchema } from "@core/form/form.rule";
import {
  useQyDeleteBranch,
  useQyEditBranch,
  useQyGetBranchById,
} from "@core/query/branch.query";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { FieldErrors, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ActionEnum } from "@core/model/actions.enum";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useFormDefaultBranch } from "@core/form/form-default-branch.hook";
import { confirmDialog } from "primereact/confirmdialog";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { LabelValue } from "@shared/models/label-value.interface";
import { useUserIdentityContext } from "@core/services/user-identity.context";

export function useEditBranch() {
  // LOCAL DECLARATION
  const { userIsAdmin } = useUserIdentityContext();
  const { setSelectedReportingTo } = useFormDropdownPersonContext();
  const { getDefault } = useFormDefaultBranch();
  const navigate = useNavigate();
  const { mapEditBranch, mapDeleteBranch } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { branchId } = useParams();
  const branchActions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];
  const employeeActions: ButtonAction[] = [
    { label: "New Employee", type: "secondary", value: ActionEnum.NewEmployee },
  ];

  // QUERY DECLARATION
  // GET REQUEST API
  const handleGetApiSuccess = (data: UtilBranchControllerGet200Response) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("code", responseData?.code || "");
      setValue("description", responseData?.description || "");
      setValue("name", responseData?.name || "");
      setValue("head", responseData?.head || "");

      const isHead = !!responseData?.head;
      if (isHead) {
        setSelectedReportingTo({
          label: `${responseData?.head_last_name} ${responseData?.head_first_name}`,
          value: responseData?.head,
        } as LabelValue);
      } else {
        setSelectedReportingTo(null);
      }
    }
  };
  const {
    data: branchResponse,
    isLoading,
    isError: branchError,
  } = useQyGetBranchById(branchId || "", handleGetApiSuccess);
  const branchData = branchResponse?.data?.[0];

  // API UPDATE BRANCH
  const handleUpdateApiSuccess = () => {
    showSuccess("Branch updated successfully");
  };
  const { mutate: editBranch, isLoading: isEditLoading } = useQyEditBranch(
    handleUpdateApiSuccess
  );

  // API DELETE Branch
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Branch deleted successfully");
  };
  const { mutate: deleteBranch } = useQyDeleteBranch(handleDeleteApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<BranchFormSchema>({
    defaultValues: getDefault(branchData),
    resolver: zodResolver(BranchFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: BranchFormSchema) => {
    if (!branchData) {
      throw new Error("No branch data");
    }

    const formData = mapEditBranch(form, branchData.code);
    editBranch(formData);
  };
  const handleValidateError = (err: FieldErrors<BranchFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!branchData) {
      throw new Error("No branch data found");
    }

    const form = mapDeleteBranch(branchData.code);
    deleteBranch(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.NewEmployee:
        navigate("employee/new");
        break;
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };
  return {
    branchId,
    branchData,
    isLoading,
    branchError,
    formMethod,
    branchActions,
    employeeActions,
    isEditLoading,
    userIsAdmin,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
