import { ReactNode, createContext, useContext } from "react";
import { useFilterStatus } from "./filter-status.hook";

const FilterStatusContext = createContext(
  {} as ReturnType<typeof useFilterStatus>
);
export function useFilterStatusContext() {
  return useContext(FilterStatusContext);
}

interface FilterStatusProviderProps {
  children: ReactNode;
}
export function FilterStatusProvider({
  children,
}: FilterStatusProviderProps) {
  return (
    <FilterStatusContext.Provider value={useFilterStatus()}>
      {children}
    </FilterStatusContext.Provider>
  );
}