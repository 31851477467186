import "./edit-holiday.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormHoliday from "../form-holiday/form-holiday";
import { useEditHoliday } from "./edit-holiday.hook";
import { ConfirmDialog } from "primereact/confirmdialog";

export function Holiday() {
  const {
    holidayData,
    isLoading,
    holidayError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditHoliday();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormHoliday />
      </section>
    </div>
  );

  return (
    <div id="Holiday" className="holiday">
      <HeaderContent
        title={holidayData?.name || "Edit Holiday"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <FormProvider {...formMethod}>
          <ConfirmDialog />
          {isLoading && displayLoading}
          {holidayError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default Holiday;
