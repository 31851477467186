import { Sidebar } from "primereact/sidebar";
import "./amend-break.scss";
import HeaderContent from "@shared/ui/header-content/header-content";
import { Button } from "primereact/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import FormEmployeeRequestBreak from "@domain/employee-request/form-employee-request-break/form-employee-request-break";
import {
  EmployeeRequestFormRule,
  EmployeeRequestFormSchema,
} from "@core/form/form.rule";
import { employeeRequestFormDefault } from "@core/form/form.default";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormDropdownRequestType } from "@domain/request-type/form-dropdown-request-type/form-dropdown-request-type.hook";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyBreakAmendmentEmployeeRequest } from "@core/query/employee-request.query";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { getFormErrorMessage } from "@core/utility/get-error-message";

/* eslint-disable-next-line */
export interface AmendBreakProps {}

export function AmendBreak() {
  const [searchParam] = useSearchParams();
  const breakDate = searchParam.get("breakdate");
  const { getAmendBreakMap } = useFormDropdownRequestType();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { mapBreakEmployeeRequest } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const navigate = useNavigate();

  // QUERIES
  const handleApiSuccess = () => {
    showSuccess(`New Employee Request for Break Amend is created`);
    navigate(`../`);
  };
  const { mutate: breakRequest, isLoading: isLoadingBreak } =
    useQyBreakAmendmentEmployeeRequest(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeRequestFormSchema>({
    defaultValues: {
      ...employeeRequestFormDefault,
    },
    resolver: zodResolver(EmployeeRequestFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeRequestFormSchema) => {
    const defaultFormData = {
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
    };

    const requestType = getAmendBreakMap()?.value;
    const mutatedForm = {
      ...form,
      userCode: defaultFormData.userCode,
      bucketCode: defaultFormData.bucketCode,
      requestType: requestType,
    } as EmployeeRequestFormSchema;

    const breakAmendPayload = mapBreakEmployeeRequest(mutatedForm);
    breakRequest(breakAmendPayload);
  };
  const handleValidateError = (err: FieldErrors<EmployeeRequestFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <Sidebar
      visible={true}
      onHide={() => navigate("../")}
      className="w-full md:w-[700px]"
    >
      <div id="AmendBreak" className="amend-dtr">
        <HeaderContent
          title="Amend Break"
          onBack={() => navigate("../")}
          headingType={"h2"}
        >
          <Button
            className="w-full block"
            label="Save"
            disabled={isLoadingBreak}
            onClick={handleSubmit(handleValidate, handleValidateError)}
          ></Button>
        </HeaderContent>

        <section className="p-7">
          <FormProvider {...formMethod}>
            <FormEmployeeRequestBreak defaultBreakDate={breakDate || ""} />
          </FormProvider>
        </section>
      </div>
    </Sidebar>
  );
}

export default AmendBreak;
