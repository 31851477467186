import { EditJobGradeDto } from "@api/api";
import { jobGradeFormDefault } from "./form.default";
import { JobGradeFormSchema } from "./form.rule";

export const useFormDefaultJobGrade = () => {
  const getDefault = (cachedValue: EditJobGradeDto | undefined) => {
    return !cachedValue
      ? jobGradeFormDefault
     : ({
				name: cachedValue.name,
				description: cachedValue.description,
      } as JobGradeFormSchema);
  };

  return {
    getDefault,
  };
};
