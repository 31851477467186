import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetDepartmentModel } from "@core/model/query-model-department";
import { departmentFormDefault } from "./form.default";
import { DepartmentFormSchema } from "./form.rule";

export const useFormDefaultDepartment = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetDepartmentModel | undefined) => {
    return !cachedValue
      ? departmentFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
        head: cachedValue.head,
      } as DepartmentFormSchema);
  };

  return {
    getDefault,
  };
};
