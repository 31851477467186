import { ReactNode, createContext, useContext } from "react";
import { useFilterShift } from "./filter-shift.hook";

const FilterShiftContext = createContext(
  {} as ReturnType<typeof useFilterShift>
);
export function useFilterShiftContext() {
  return useContext(FilterShiftContext);
}

interface FilterShiftProviderProps {
  children: ReactNode;
}
export function FilterShiftProvider({
  children,
}: FilterShiftProviderProps) {
  return (
    <FilterShiftContext.Provider value={useFilterShift()}>
      {children}
    </FilterShiftContext.Provider>
  );
}