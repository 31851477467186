import { Outlet } from "react-router-dom";

import { FilterEmployeeIncentiveProvider } from "./filter-employee-incentive/filter-employee-incentive.context";
import './employee-incentive.scss';

export function EmployeeIncentive() {
  return (
    <div id="EmployeeIncentive" className="employee-incentive">
       <FilterEmployeeIncentiveProvider>
        <Outlet />
      </FilterEmployeeIncentiveProvider>
    </div>
  );
}

export default EmployeeIncentive;
