import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownPayroll } from "./form-dropdown-payroll.hook";

const FormDropdownPayrollContext = createContext(
  {} as ReturnType<typeof useFormDropdownPayroll>
);
export function useFormDropdownPayrollContext() {
  return useContext(FormDropdownPayrollContext);
}

interface FormDropdownPayrollProviderProps {
  children: ReactNode;
}
export function FormDropdownPayrollProvider({
  children,
}: FormDropdownPayrollProviderProps) {
  return (
    <FormDropdownPayrollContext.Provider value={useFormDropdownPayroll()}>
      {children}
    </FormDropdownPayrollContext.Provider>
  );
}
