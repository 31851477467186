import './new-request-type.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateRequestType } from "@core/query/request-type.query";
import { RequestTypeFormRule, RequestTypeFormSchema } from "@core/form/form.rule";
import { requestTypeFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormRequestType from "../form-request-type/form-request-type";

export function RequestType() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateRequestType } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New RequestType is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addRequestType, isLoading: isCreating } =
    useQyCreateRequestType(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<RequestTypeFormSchema>({
    defaultValues: requestTypeFormDefault,
    resolver: zodResolver(RequestTypeFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: RequestTypeFormSchema) => {
    const formData = mapCreateRequestType(form);
    addRequestType(formData);
  };
  const handleValidateError = (err: FieldErrors<RequestTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="RequestType" className="request-type">
      <HeaderContent
        title="New Request Type"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormRequestType />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default RequestType;