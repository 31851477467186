import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import "./form-employee-request-break.scss";
import { EmployeeRequestFormSchema } from "@core/form/form.rule";
import { useFormContext } from "react-hook-form";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useQyGetBreakDailyTimeRecord } from "@core/query/daily-time-record.query";
import { GetResponseBreakModel } from "@core/model/query-model-daily-time-record";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";

export interface FormEmployeeRequestBreakProps {
  isView?: boolean;
  defaultBreakDate?: string;
}

type InputFormSchema = EmployeeRequestFormSchema;
export function FormEmployeeRequestBreak({
  isView = false,
  defaultBreakDate = "",
}: FormEmployeeRequestBreakProps) {
  const { formatDateTimeForInput } = useDateTimeContext();
  const { control, setValue, watch } = useFormContext<InputFormSchema>();
  const [breakDate, setBreakDate] = useState(defaultBreakDate);
  const { selectedPersonData } = useFormDropdownPersonContext();

  // QUERIES
  const bucketId = selectedPersonData?.bucket.code;
  const handleApiSuccess = (response: GetResponseBreakModel) => {
    const breakData = response?.data?.[0];
    if (!breakData) return;

    setValue("amendBreakStart", formatDateTimeForInput(breakData.break_start));
    setValue("amendBreakEnd", formatDateTimeForInput(breakData.break_end));
    setValue("breakCode", breakData.code);
  };
  useQyGetBreakDailyTimeRecord(
    breakDate,
    bucketId || "",
    !!breakDate && !!bucketId,
    handleApiSuccess
  );

  const dateFilterElement = (
    <span className="flex flex-col-reverse gap-1">
      <InputText
        value={breakDate}
        type="date"
        id="date"
        onInput={(e: any) => {
          setBreakDate(e.target.value);
        }}
      />

      <label className="text-xs text-gray-500" htmlFor="date">
        Select Date of Break to Amend
      </label>
    </span>
  );

  return (
    <div id="FormEmployeeRequestBreak" className="form-employee-request-break">
      <section className="mt-4">
        <ImageControl<InputFormSchema>
          control={control}
          name="file"
          label="File Attachment"
        />

        {!isView && dateFilterElement}
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 bg-white">
        <InputDateControl<InputFormSchema>
          control={control}
          type="datetime-local"
          name="amendBreakStart"
          label="Start of Break"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter Start Break"
          disabled={isView}
        />
        <InputDateControl<InputFormSchema>
          control={control}
          type="datetime-local"
          name="amendBreakEnd"
          label="End of Break"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter End Break"
          disabled={isView}
        />
        <InputTextareaControl<InputFormSchema>
          control={control}
          name="requestReason"
          label="Request Reason"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter requestReason"
          disabled={isView}
        />
        <CheckboxControl<InputFormSchema>
          control={control}
          name="isBreakPermission"
          label="Is Break Permission"
          containerClassName="pb-2"
          disabled={isView}
        />
      </section>
    </div>
  );
}

export default FormEmployeeRequestBreak;
