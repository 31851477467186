import { EditJobTitleDto } from "@api/api";
import { jobTitleFormDefault } from "./form.default";
import { JobTitleFormSchema } from "./form.rule";

export const useFormDefaultJobTitle = () => {
  const getDefault = (cachedValue: EditJobTitleDto | undefined) => {
    return !cachedValue
      ? jobTitleFormDefault
     : ({
				name: cachedValue.name,
				description: cachedValue.description,
      } as JobTitleFormSchema);
  };

  return {
    getDefault,
  };
};
