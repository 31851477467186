import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownShiftTypeContext } from "./form-dropdown-shift-type.context";

export interface FormDropdownShiftTypeProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownShiftType<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownShiftTypeProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const {
    sidebar,
    newShiftType,
    mappedShiftType,
    isCreating,
    setSidebar,
    setFilter,
    setNewShiftType,
    handleFilterInput,
    handleAdd,
  } = useFormDropdownShiftTypeContext();
  
  return (
    <div className="shift-type">
      {newShiftType && (
        <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
          <h2>Create new shiftType</h2>
          <p>
            You are creating a new shiftType. Please, fill the fields to create a
            new shiftType and apply to current item creation.
          </p>
          <div className="flex flex-col gap-2 mt-4">
            <InputText
              placeholder="ShiftType Name"
              value={newShiftType.name}
              onChange={(e: any) =>
                setNewShiftType({ ...newShiftType, name: e.target.value })
              }
            />
            <InputText
              placeholder="ShiftType Description"
              value={newShiftType.description}
              onChange={(e: any) =>
                setNewShiftType({ ...newShiftType, description: e.target.value })
              }
            />

            <Button
              label="Create"
              onClick={handleAdd}
              className="block"
              disabled={isCreating}
            />
          </div>
        </Sidebar>
      )}
      <DropdownControl<T>
        control={control}
        name={name}
        label="ShiftType"
        options={ mappedShiftType }
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your shiftType"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
        onKeyDown={allowCreate ? handleFilterInput : () => {}}
      />
    </div>
  );
}

export default FormDropdownShiftType;