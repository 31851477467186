import { useFilterEmployeeBucketContext } from "./filter-employee-bucket.context";

export function FilterEmployeeBucket() {
  const { roleFilterElement, clearFiltersElement } =
    useFilterEmployeeBucketContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {roleFilterElement}
      {clearFiltersElement}
    </div>
  );
}
