import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownInfraction } from "./form-dropdown-infraction.hook";

const FormDropdownInfractionContext = createContext(
  {} as ReturnType<typeof useFormDropdownInfraction>
);
export function useFormDropdownInfractionContext() {
  return useContext(FormDropdownInfractionContext);
}

interface FormDropdownInfractionProviderProps {
  children: ReactNode;
}
export function FormDropdownInfractionProvider({
  children,
}: FormDropdownInfractionProviderProps) {
  return (
    <FormDropdownInfractionContext.Provider value={useFormDropdownInfraction()}>
      {children}
    </FormDropdownInfractionContext.Provider>
  );
}
