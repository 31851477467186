import { ReactNode, createContext, useContext } from "react";
import { useFilterRole } from "./filter-role.hook";

const FilterRoleContext = createContext(
  {} as ReturnType<typeof useFilterRole>
);
export function useFilterRoleContext() {
  return useContext(FilterRoleContext);
}

interface FilterRoleProviderProps {
  children: ReactNode;
}
export function FilterRoleProvider({
  children,
}: FilterRoleProviderProps) {
  return (
    <FilterRoleContext.Provider value={useFilterRole()}>
      {children}
    </FilterRoleContext.Provider>
  );
}