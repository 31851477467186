import HeaderContent from "@shared/ui/header-content/header-content";
import { useEditEmployee } from "./edit-employee.hook";
import "./edit-employee.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { LabelValue } from "@shared/models/label-value.interface";
import { InputText } from "primereact/inputtext";

export function EditEmployee() {
  const {
    quickSummaryHoliday,
    quickSummaryAbsent,
    quickSummaryTardiness,
    quickSummarySick,
    handleBack,
  } = useEditEmployee();

  const meterSection = (item: LabelValue<number>) => (
    <section className="flex gap-4">
      <span className="flex items-center justify-center rounded-full bg-purple-400 text-white w-10 h-10">
        <label>{item.value}</label>
      </span>
      <span>
        <ProgressBar
          value={item.payload}
          style={{ width: "300px", height: "25px", fontSize: "12px" }}
        ></ProgressBar>
        <small>{item.label}</small>
      </span>
    </section>
  );

  return (
    <div id="EditEmployee" className="edit-employee">
      <HeaderContent
        title="Edit Employee"
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <span className="flex gap-2">
          <Button label="Update" />
        </span>
      </HeaderContent>

      <section className="p-7">
        <section className="flex items-center justify-center gap-24 flex-wrap">
          <div className="flex items-center justify-center gap-4 flex-wrap">
            <img
              className="h-auto max-w-full"
              src="/icon-192x192.png"
              alt="image description"
            />
            <div>
              <h3>Kakashi Hatake</h3>
              <p>hatakikakashi88@gmail.com</p>
              <p>Cashier</p>
              <p>Jagna Branch</p>
              <p>Finance Department</p>
              <p>Hired on 2019-02-23</p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {meterSection(quickSummaryHoliday as LabelValue<number>)}
            {meterSection(quickSummaryAbsent as LabelValue<number>)}
            {meterSection(quickSummaryTardiness as LabelValue<number>)}
            {meterSection(quickSummarySick as LabelValue<number>)}
          </div>
        </section>
        <TabView>
          <TabPanel header="General">
            <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <section className="flex flex-col gap-4">
                <InputText value={"Kakashi"} />
                <InputText value={"Hatake"} />
                <InputText value={"hatakikakashi88@gmail.com"} />
                <InputText value={"21 Front Side"} />
                <InputText value={"Baker Street"} />
                <InputText value={"Quezon City"} />
              </section>
              <section className="flex flex-col gap-4">
                <InputText value={"Cashier"} />
                <InputText value={"Jagna Branch"} />
                <InputText value={"2019-02-23"} />
                <InputText value={"31,000"} />
                <InputText value={"Permanent"} />
                <InputText value={"Active"} />
              </section>
            </section>
          </TabPanel>
          <TabPanel header="Score"></TabPanel>
          <TabPanel header="Leaves"></TabPanel>
          <TabPanel header="Schedule"></TabPanel>
          <TabPanel header="Incident"></TabPanel>
          <TabPanel header="Loan"></TabPanel>
          <TabPanel header="Attachment"></TabPanel>
        </TabView>
      </section>
    </div>
  );
}

export default EditEmployee;
