import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { FaqFormRule, FaqFormSchema } from "@core/form/form.rule";
import { useFormDefaultFaq } from "@core/form/form-default-faq.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateFaq, 
  useQyGetFaqById, 
  useQyDeleteFaq 
} from "@core/query/faq.query";
import { GetResponseFaqModel } from "@core/model/query-model-faq";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditFaq() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultFaq();
  const navigate = useNavigate();
  const { mapUpdateFaq, mapDeleteFaq } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { faqId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Faq
  const handleGetApiSuccess = (data: GetResponseFaqModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("title", responseData?.title || "");
      setValue("description", responseData?.description || "");
      setValue("link", responseData?.link || "");
    }
  };
  const {
    data: faqResponse,
    isLoading,
    isError: faqError,
  } = useQyGetFaqById(faqId || "", handleGetApiSuccess);
  const faqData = faqResponse?.data?.[0];

  // API UPDATE Faq
  const handleUpdateApiSuccess = () => {
    showSuccess("Faq updated successfully");
  };
  const { mutate: updateFaq, isLoading: isEditLoading } = useQyUpdateFaq(
    handleUpdateApiSuccess
  );

  // API DELETE Faq
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Faq deleted successfully");
  };
  const { mutate: deleteFaq } = useQyDeleteFaq(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<FaqFormSchema>({
    defaultValues: getDefault(faqData),
    resolver: zodResolver(FaqFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: FaqFormSchema) => {
    if (!faqData) {
      throw new Error("No faq data");
    }

    const formData = mapUpdateFaq(form, faqData.code);
    updateFaq(formData);
  };
  const handleValidateError = (err: FieldErrors<FaqFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!faqData) {
      throw new Error("No faq data found");
    }

    const form = mapDeleteFaq(faqData.code);
    deleteFaq(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    faqId,
    faqData,
    isLoading,
    faqError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}