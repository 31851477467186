import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeTimeline } from "./filter-employee-timeline.hook";

const FilterEmployeeTimelineContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeTimeline>
);
export function useFilterEmployeeTimelineContext() {
  return useContext(FilterEmployeeTimelineContext);
}

interface FilterEmployeeTimelineProviderProps {
  children: ReactNode;
}
export function FilterEmployeeTimelineProvider({
  children,
}: FilterEmployeeTimelineProviderProps) {
  return (
    <FilterEmployeeTimelineContext.Provider value={useFilterEmployeeTimeline()}>
      {children}
    </FilterEmployeeTimelineContext.Provider>
  );
}