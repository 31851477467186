import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { RequestTypeFormRule, RequestTypeFormSchema } from "@core/form/form.rule";
import { useFormDefaultRequestType } from "@core/form/form-default-request-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateRequestType, 
  useQyGetRequestTypeById, 
  useQyDeleteRequestType 
} from "@core/query/request-type.query";
import { GetResponseRequestTypeModel } from "@core/model/query-model-request-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditRequestType() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultRequestType();
  const navigate = useNavigate();
  const { mapUpdateRequestType, mapDeleteRequestType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { requestTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID RequestType
  const handleGetApiSuccess = (data: GetResponseRequestTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: requestTypeResponse,
    isLoading,
    isError: requestTypeError,
  } = useQyGetRequestTypeById(requestTypeId || "", handleGetApiSuccess);
  const requestTypeData = requestTypeResponse?.data?.[0];

  // API UPDATE RequestType
  const handleUpdateApiSuccess = () => {
    showSuccess("RequestType updated successfully");
  };
  const { mutate: updateRequestType, isLoading: isEditLoading } = useQyUpdateRequestType(
    handleUpdateApiSuccess
  );

  // API DELETE RequestType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("RequestType deleted successfully");
  };
  const { mutate: deleteRequestType } = useQyDeleteRequestType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<RequestTypeFormSchema>({
    defaultValues: getDefault(requestTypeData),
    resolver: zodResolver(RequestTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: RequestTypeFormSchema) => {
    if (!requestTypeData) {
      throw new Error("No requestType data");
    }

    const formData = mapUpdateRequestType(form, requestTypeData.code);
    updateRequestType(formData);
  };
  const handleValidateError = (err: FieldErrors<RequestTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!requestTypeData) {
      throw new Error("No requestType data found");
    }

    const form = mapDeleteRequestType(requestTypeData.code);
    deleteRequestType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    requestTypeId,
    requestTypeData,
    isLoading,
    requestTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}