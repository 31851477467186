import { useFilterPersonAddressContext } from "./filter-person-address.context";

export function FilterPersonAddress() {
  const { dateFilterElement, reportFilterElements, clearFiltersElement } =
    useFilterPersonAddressContext();

  return (
    <div>
      <h2>Filters</h2>
      <p className="mb-4">
        Select the following filters you want to apply to the current table.
      </p>

      {dateFilterElement}
      {reportFilterElements}
      {clearFiltersElement}
    </div>
  );
}