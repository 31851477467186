import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetPenaltyScheduleModel } from "@core/model/query-model-penalty-schedule";
import { penaltyScheduleFormDefault } from "./form.default";
import { PenaltyScheduleFormSchema } from "./form.rule";

export const useFormDefaultPenaltySchedule = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetPenaltyScheduleModel | undefined) => {
    return !cachedValue
      ? penaltyScheduleFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as PenaltyScheduleFormSchema);
  };

  return {
    getDefault,
  };
};
