import { useFormContext } from "react-hook-form";
import "./form-employee.scss";
import { EmployeeFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";

export function FormEmployee() {
  const { control } = useFormContext<EmployeeFormSchema>();
  return (
    <div id="FormEmployee" className="form-employee">
      <InputControl<EmployeeFormSchema>
        control={control}
        name="firstName"
        label="First Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter first name"
      />
      <InputControl<EmployeeFormSchema>
        control={control}
        name="lastName"
        label="First Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter first name"
      />
      <InputControl<EmployeeFormSchema>
        control={control}
        name="email"
        label="Email"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter email"
      />
      <InputDateControl<EmployeeFormSchema>
        control={control}
        name="birthday"
        label="Birthday"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default FormEmployee;
