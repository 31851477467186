import { ReactNode, createContext, useContext } from "react";
import { useFilterAttachmentType } from "./filter-attachment-type.hook";

const FilterAttachmentTypeContext = createContext(
  {} as ReturnType<typeof useFilterAttachmentType>
);
export function useFilterAttachmentTypeContext() {
  return useContext(FilterAttachmentTypeContext);
}

interface FilterAttachmentTypeProviderProps {
  children: ReactNode;
}
export function FilterAttachmentTypeProvider({
  children,
}: FilterAttachmentTypeProviderProps) {
  return (
    <FilterAttachmentTypeContext.Provider value={useFilterAttachmentType()}>
      {children}
    </FilterAttachmentTypeContext.Provider>
  );
}