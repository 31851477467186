import "./form-employee-ledger.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeLedgerFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { LabelValue } from "@shared/models/label-value.interface";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";

type InputFormSchema = EmployeeLedgerFormSchema;
export function EmployeeLedger() {
  const mappedActions = [
    { label: "Debit", value: "DEBIT" },
    { label: "Credit", value: "CREDIT" },
  ] as LabelValue[];
  const { control } = useFormContext<EmployeeLedgerFormSchema>();
  return (
    <div id="EmployeeLedger" className="employee-ledger">
      <InputDigitControl<InputFormSchema>
        control={control}
        name="ledgerValue"
        label="Ledger Value"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter ledgerValue"
      />
      <DropdownControl<InputFormSchema>
        control={control}
        name="ledgerAction"
        label="Action"
        options={mappedActions}
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter the ledger action"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="ledgerRemarks"
        label="Ledger Remarks"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter ledger remarks"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default EmployeeLedger;
