import './form-job-title.scss';
import { useFormContext } from "react-hook-form";
import { JobTitleFormSchema } from "@core/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";

type InputFormSchema = JobTitleFormSchema;
export function JobTitle() {
  const { control } = useFormContext<JobTitleFormSchema>();
  return (
    <div id="JobTitle" className="job-title">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
    </div>
  );
}

export default JobTitle;