import { Outlet } from "react-router-dom";
import "./event-page.scss";
import { FilterEventPageProvider } from "./filter-event-page/filter-event-page.context";
import { FormDropdownBranchProvider } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import { FormDropdownFavoriteProvider } from "@domain/employee-favorite/form-dropdown-favorite/form-dropdown-favorite.context";

export function EventPage() {
  return (
    <div id="EventPage" className="event-page">
      <FormDropdownFavoriteProvider>
        <FormDropdownBranchProvider>
          <FilterEventPageProvider>
            <Outlet />
          </FilterEventPageProvider>
        </FormDropdownBranchProvider>
      </FormDropdownFavoriteProvider>
    </div>
  );
}

export default EventPage;
