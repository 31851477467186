import { 
    MessageResponseDto, 
    CreateStatusDto, 
    EditStatusDto,
    UtilStatusControllerGet200Response,
    UtilityStatusApiFp,
    DeleteStatusDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseStatusModel = UtilStatusControllerGet200Response;
export type GetStatusModel = EditStatusDto;
export type MutateResponseStatusModel = MessageResponseDto;
export type CreateStatusModel = CreateStatusDto;
export type UpdateStatusModel = EditStatusDto;
export type DeleteStatusModel = DeleteStatusDto;

export const apiStatusFp =  UtilityStatusApiFp(apiConfig);
export const getStatusController = apiStatusFp.utilStatusControllerGet;
export const createStatusController = apiStatusFp.utilStatusControllerCreate;
export const updateStatusController = apiStatusFp.utilStatusControllerEdit;
export const deleteStatusController = apiStatusFp.utilStatusControllerDelete;

export const QueryKeyStatus = QueryKey.Status;