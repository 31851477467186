import { LocalAuth } from "@core/model/local-auth";
import { AUTH, SETTINGS } from "@core/utility/settings";
import StorageService from "@shared/services/storage.service";
import { differenceInSeconds, toDate } from "date-fns";
import { useQyRenew } from "./auth.query";
import { ApiToFormService } from "@core/services/api-to-form.service";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useCallback } from "react";

export function useSession() {
  const navigate = useNavigate();
  const { showInfo } = useNotificationContext();
  let initiateRenew = false;

  const handleApiSuccess = (response: any) => {
    const mappedData = ApiToFormService.MapLocalAuth(response);
    StorageService.save<LocalAuth>(AUTH, mappedData);
  };
  const { mutateAsync: renew } = useQyRenew(handleApiSuccess);

  const getTimeDifference = useCallback(() => {
    const authStorage = StorageService.load<LocalAuth>(AUTH);
    if (!authStorage) {
      throw new Error("no tokens available");
    }

    const serverExpiryDateTime = toDate(authStorage.accessExpiry * 1000);
    const currentDateTime = new Date();

    const diffInSec = differenceInSeconds(
      serverExpiryDateTime,
      currentDateTime
    );

    return diffInSec;
  }, []);

  const shouldRenew = useCallback(() => {
    const timeDifference = getTimeDifference();
    const shouldRenew =
      timeDifference < SETTINGS.minTimeForRenew &&
      timeDifference > SETTINGS.maxTimeForTimeout;
    return shouldRenew;
  }, []);

  const shouldLogout = useCallback(() => {
    const timeDifference = getTimeDifference();
    const shouldLogout = timeDifference < SETTINGS.maxTimeForTimeout;
    return shouldLogout;
  }, []);

  const checkSession = () => {
    if (shouldRenew() && !initiateRenew) {
      initiateRenew = true;
      console.log("renew now");
      return renew();
    } else if (shouldLogout()) {
      console.log("relogin now");
      showInfo(
        `Your session expired. You will need to enter your credentials again.`
      );
      navigate("/login");
    }

    return Promise.resolve("ok");
  };

  return { initiateRenew, checkSession, shouldRenew, shouldLogout };
}
