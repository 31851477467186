import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeScore } from "./filter-employee-score.hook";

const FilterEmployeeScoreContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeScore>
);
export function useFilterEmployeeScoreContext() {
  return useContext(FilterEmployeeScoreContext);
}

interface FilterEmployeeScoreProviderProps {
  children: ReactNode;
}
export function FilterEmployeeScoreProvider({
  children,
}: FilterEmployeeScoreProviderProps) {
  return (
    <FilterEmployeeScoreContext.Provider value={useFilterEmployeeScore()}>
      {children}
    </FilterEmployeeScoreContext.Provider>
  );
}