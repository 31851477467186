import "./form-employee-score.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeScoreFormSchema } from "@core/form/form.rule";
import InputRatingControl from "@shared/ui/hook-form/input-rating-control/input-rating-control";

type InputFormSchema = EmployeeScoreFormSchema;
export function EmployeeScore() {
  const { control, getValues } = useFormContext<EmployeeScoreFormSchema>();
  const questionDisplay = getValues("questionLabel") || "Score";

  return (
    <div id="EmployeeScore" className="employee-score">
      <InputRatingControl<InputFormSchema>
        control={control}
        name="score"
        label={questionDisplay}
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default EmployeeScore;
