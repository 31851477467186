import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import axios, { AxiosError } from "axios";
import {
  CreatePersonModel,
  DeletePersonModel,
  GetResponsePersonModel,
  MutateResponsePersonModel,
  QueryKeyPerson,
  UpdatePersonModel,
  deletePersonController,
  getPersonController,
  UploadPersonModel,
} from "@core/model/query-model-person";
import { useSessionContext } from "./session.context";
import { SETTINGS } from "@core/utility/settings";
import { BASE_PATH } from "@api/base";
import { apiConfig } from "./config.query";

export function useQyGetPerson(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  dateFilter?: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponsePersonModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined,
  byPassCheckSession?: boolean
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    dateFilter: string | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    if (!byPassCheckSession) {
      await checkSession();
    }

    showProgress();
    const operation = await getPersonController(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      dateFilter,
      startDate,
      endDate,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyPerson]);
    return response["data"] as GetResponsePersonModel;
  };

  return useQuery({
    enabled,
    queryKey: [
      QueryKeyPerson,
      search,
      limit,
      offset,
      order,
      filter,
      dateFilter,
      startDate,
      endDate,
    ],
    queryFn: () =>
      apiFn(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate
      ),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetPersonById(
  id: string,
  onSuccess?:
    | ((data: GetResponsePersonModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (id: string, search = "", limit = 1, offset = 0) => {
    await checkSession();

    showProgress();
    const operation = await getPersonController(
      search,
      limit,
      offset,
      undefined,
      { filter: JSON.stringify({ code: id }) },
      undefined,
      undefined,
      undefined,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyPerson, id]);
    return response["data"] as GetResponsePersonModel;
  };

  return useQuery({
    queryKey: [QueryKeyPerson, id],
    enabled: !!id,
    queryFn: () => apiFn(id),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
    staleTime: SETTINGS.staleTime,
  });
}

export function useQyCreatePerson(
  onSuccess?:
    | ((data: MutateResponsePersonModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreatePersonModel) => {
    await checkSession();

    showProgress();

    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("person", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }

    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/person/create`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyPerson);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdatePerson(
  onSuccess?:
    | ((data: MutateResponsePersonModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdatePersonModel) => {
    await checkSession();

    showProgress();

    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("person", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }

    const operation = await axios.put(
      `${apiConfig.basePath}/api/v1/person/edit`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyPerson);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyDeletePerson(
  onSuccess?:
    | ((data: MutateResponsePersonModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DeletePersonModel) => {
    await checkSession();

    showProgress();
    const operation = await deletePersonController(payload, authHeaders());
    const response = (await operation()).data;
    return response["message"] as MutateResponsePersonModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyPerson);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUploadFile(
  onSuccess?:
    | ((data: MutateResponsePersonModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UploadPersonModel) => {
    await checkSession();

    showProgress();

    const formdata = new FormData();
    const { file, code, attachment_type } = payload;
    formdata.append("code", code);
    formdata.append("attachment_type", attachment_type);
    if (file) {
      const payloadFile = file as File;
      formdata.append("file", payloadFile, payloadFile.name);
    }

    const operation = await axios.put(
      `${apiConfig.basePath}/api/v1/person/attach-file`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyPerson);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}