import { CalendarDate } from "@core/model/calendar-event.interface";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { InputText } from "primereact/inputtext";
import { useState } from "react";

export function useDateFilterDailyTimeRecord() {
  const { formatDate } = useDateTimeContext();
  const [calendarDate, setCalendarDate] = useState<CalendarDate>({
    start: formatDate(new Date()),
    end: formatDate(new Date()),
  });

  const dateElement = (
    <div className="flex gap-4 items-center mb-2">
      <div className="flex flex-col">
        <label className="text-xs text-gray-500" htmlFor="start">
          Start
        </label>
        <InputText
          type="date"
          id="start"
          value={calendarDate.start}
          onChange={(e) => {
            setCalendarDate({
              ...calendarDate,
              start: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-col">
        <label className="text-xs text-gray-500" htmlFor="end">
          End
        </label>
        <InputText
          type="date"
          id="end"
          value={calendarDate.end}
          onChange={(e) => {
            setCalendarDate({
              ...calendarDate,
              end: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );

  return {
    calendarDate,
    dateElement,
    setCalendarDate,
  };
}
