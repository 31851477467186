import { ReactNode, createContext, useContext } from "react";
import { useFilterDepartment } from "./filter-department.hook";

const FilterDepartmentContext = createContext(
  {} as ReturnType<typeof useFilterDepartment>
);
export function useFilterDepartmentContext() {
  return useContext(FilterDepartmentContext);
}

interface FilterDepartmentProviderProps {
  children: ReactNode;
}
export function FilterDepartmentProvider({
  children,
}: FilterDepartmentProviderProps) {
  return (
    <FilterDepartmentContext.Provider value={useFilterDepartment()}>
      {children}
    </FilterDepartmentContext.Provider>
  );
}