import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownEmployeeLoanContext } from "./form-dropdown-employee-loan.context";

export interface FormDropdownEmployeeLoanProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownEmployeeLoan<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownEmployeeLoanProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const { mappedEmployeeLoan } =
    useFormDropdownEmployeeLoanContext();

  return (
    <div className="employee-loan">
      <DropdownControl<T>
        control={control}
        name={name}
        label="Employee Loan"
        options={mappedEmployeeLoan}
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your employee loan"
        hint={hintText}
        filter
      />
    </div>
  );
}

export default FormDropdownEmployeeLoan;
