import { ReactNode, createContext, useContext } from "react";
import { useFilterFaq } from "./filter-faq.hook";

const FilterFaqContext = createContext(
  {} as ReturnType<typeof useFilterFaq>
);
export function useFilterFaqContext() {
  return useContext(FilterFaqContext);
}

interface FilterFaqProviderProps {
  children: ReactNode;
}
export function FilterFaqProvider({
  children,
}: FilterFaqProviderProps) {
  return (
    <FilterFaqContext.Provider value={useFilterFaq()}>
      {children}
    </FilterFaqContext.Provider>
  );
}