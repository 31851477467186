import {
  addDays,
  compareAsc,
  differenceInMinutes,
  isValid,
  setHours,
  setMinutes,
} from "date-fns";

export type DayType = "today" | "tomorrow";

export const calculateDurationInMinutes = (
  start: string,
  end: string
): number => {
  const [startHours, startMinutes] = start.split(":").map(Number);
  const [endHours, endMinutes] = end.split(":").map(Number);

  const now = new Date();

  const startDate = new Date(now);
  startDate.setHours(startHours, startMinutes, 0, 0);

  let endDate = new Date(now);
  endDate.setHours(endHours, endMinutes, 0, 0);

  // If the end time is before the start time, it means it's the next day
  if (endDate <= startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }

  const duration = (endDate.getTime() - startDate.getTime()) / 1000 / 60; // Convert milliseconds to minutes
  return duration;
};

export const getDateFromTime = (time: string, isNextDay = false) => {
  const [hours, minutes] = time.split(":").map(Number);

  // Create a new Date object with the current date
  let date = new Date();

  if (isNextDay) {
    date = addDays(date, 1);
  }

  // Set the hours and minutes using date-fns
  date = setHours(date, hours);
  date = setMinutes(date, minutes);

  return date;
};
