import { Outlet } from "react-router-dom";

import { FilterHolidayTypeProvider } from "./filter-holiday-type/filter-holiday-type.context";
import './holiday-type.scss';

export function HolidayType() {
  return (
    <div id="HolidayType" className="holiday-type">
       <FilterHolidayTypeProvider>
        <Outlet />
      </FilterHolidayTypeProvider>
    </div>
  );
}

export default HolidayType;
