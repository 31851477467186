import {
  MessageResponseDto,
  GetPayrollDto,
  PayrollControllerGet200Response,
  PayrollApiFp,
  PayrollControllerSummary200Response,
  GetPayrollSummaryDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponsePayrollModel = PayrollControllerGet200Response;
export type GetResponsePayrollSummaryModel =
  PayrollControllerSummary200Response;
export type GetPayrollSummaryModel = GetPayrollSummaryDto;
export type GetPayrollModel = GetPayrollDto;
export type MutateResponsePayrollModel = MessageResponseDto;
export interface GeneratePayrollModel {
  dateFrom: string;
  dateTo: string;
  branchCode: string;
  employmentType?: string;
}

export const apiPayrollFp = PayrollApiFp(apiConfig);
export const getPayrollController = apiPayrollFp.payrollControllerGet;
export const getPayrollSummmaryController =
  apiPayrollFp.payrollControllerSummary;

export const QueryKeyPayroll = QueryKey.Payroll;
export const QueryKeyPayrollSummary = QueryKey.PayrollSummary;
