import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useSessionContext } from "./session.context";
import {
  GetBranchDailyModel,
  GetBranchDailyModelV2,
  QueryKeyReportBranchDaily,
  getBranchDaily,
  getBranchDailyV2,
} from "@core/model/query-model-report";
import { authHeaders } from "./auth-header";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { SETTINGS } from "@core/utility/settings";

export function useQyGetReportBranchDaily(
  workDate: string,
  branchId: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetBranchDailyModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (workDate: string, branchId: string) => {
    await checkSession();

    showProgress();
    const operation = await getBranchDaily(workDate, branchId, authHeaders());
    const response = (await operation()).data;
    console.info("Query", [QueryKeyReportBranchDaily]);
    return response["data"] as GetBranchDailyModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyReportBranchDaily, workDate, branchId],
    queryFn: () => apiFn(workDate, branchId),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
    staleTime: SETTINGS.staleTime,
  });
}

export function useQyGetReportBranchDailyV2(
  startDate: string,
  endDate: string,
  branchId: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetBranchDailyModelV2) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    startDate: string,
    endDate: string,
    branchId: string
  ) => {
    await checkSession();

    showProgress();
    const operation = await getBranchDailyV2(
      startDate,
      endDate,
      branchId,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyReportBranchDaily]);
    return response["data"] as GetBranchDailyModelV2;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyReportBranchDaily, startDate, endDate, branchId],
    queryFn: () => apiFn(startDate, endDate, branchId),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
    staleTime: SETTINGS.staleTime,
  });
}
