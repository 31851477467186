import { Sidebar } from "primereact/sidebar";
import "./file-overtime.scss";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  EmployeeRequestFormRule,
  EmployeeRequestFormSchema,
} from "@core/form/form.rule";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useFormDropdownRequestType } from "@domain/request-type/form-dropdown-request-type/form-dropdown-request-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQyOvertimeEmployeeRequest } from "@core/query/employee-request.query";
import { employeeRequestFormDefault } from "@core/form/form.default";
import HeaderContent from "@shared/ui/header-content/header-content";
import { Button } from "primereact/button";
import FormEmployeeRequestOvertime from "@domain/employee-request/form-employee-request-overtime/form-employee-request-overtime";

export function FileOvertime() {
  const [searchParam] = useSearchParams();
  const totalTime = searchParam.get("total-time");
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotificationContext();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { mapOvertimeEmployeeRequest } = useFormApiMapper();
  const { getAmendDtrMap } = useFormDropdownRequestType();

  // QUERIES
  const handleApiSuccess = () => {
    showSuccess(`New Employee Request is created`);
    navigate(`../`);
  };
  const { mutate: overtimeRequest, isLoading: isLoadingOt } =
    useQyOvertimeEmployeeRequest(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeRequestFormSchema>({
    defaultValues: {
      ...employeeRequestFormDefault,
      otTime: parseInt(totalTime || "0", 10),
    },
    resolver: zodResolver(EmployeeRequestFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeRequestFormSchema) => {
    const defaultFormData = {
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
    };

    const requestType = getAmendDtrMap()?.value;
    const mutatedForm = {
      ...form,
      userCode: defaultFormData.userCode,
      bucketCode: defaultFormData.bucketCode,
      requestType: requestType,
    } as EmployeeRequestFormSchema;

    const amendmendPayload = mapOvertimeEmployeeRequest(mutatedForm);
    overtimeRequest(amendmendPayload);
  };
  const handleValidateError = (err: FieldErrors<EmployeeRequestFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <Sidebar
      visible={true}
      onHide={() => navigate("../")}
      className="w-full md:w-[700px]"
    >
      <div id="FileOvertime" className="file-overtime">
        <HeaderContent
          title="File Overtime"
          onBack={() => navigate("../")}
          headingType={"h2"}
        >
          <Button
            className="w-full block"
            label="Save"
            disabled={isLoadingOt}
            onClick={handleSubmit(handleValidate, handleValidateError)}
          ></Button>
        </HeaderContent>

        <section className="p-7">
          <FormProvider {...formMethod}>
            <FormEmployeeRequestOvertime />
          </FormProvider>
        </section>
      </div>
    </Sidebar>
  );
}

export default FileOvertime;
