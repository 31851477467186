import "./form-employee-diciplinary.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeDiciplinaryFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import { FormAutocompletePerson } from "@domain/person/form-dropdown-person/form-autocomplete-person";
import { LabelValue } from "@shared/models/label-value.interface";
import FormDropdownViolation from "@domain/violation/form-dropdown-violation/form-dropdown-violation";
import FormDropdownInfraction from "@domain/infraction/form-dropdown-infraction/form-dropdown-infraction";
import FormDropdownPenaltySchedule from "@domain/penalty-schedule/form-dropdown-penalty-schedule/form-dropdown-penalty-schedule";

type InputFormSchema = EmployeeDiciplinaryFormSchema;
export function FormEmployeeDiciplinary() {
  const { control, getValues } =
    useFormContext<EmployeeDiciplinaryFormSchema>();

  const issuerAutocompleteLabel = getValues("issuerAutocompleteLabel");
  const issuer = getValues("issuer");
  const getIssuerAutocomplete = () => {
    if (!issuerAutocompleteLabel || !issuer) return undefined;
    return {
      label: issuerAutocompleteLabel,
      value: issuer,
    } as LabelValue;
  };

  const supervisorAutocompleteLabel = getValues("supervisorAutocompleteLabel");
  const supervisor = getValues("supervisor");
  const getSupervisorAutocomplete = () => {
    if (!supervisorAutocompleteLabel || !supervisor) return undefined;
    return {
      label: supervisorAutocompleteLabel,
      value: supervisor,
    } as LabelValue;
  };

  return (
    <div id="EmployeeDiciplinary" className="employee-diciplinary">
      <InputDateControl<InputFormSchema>
        control={control}
        name="incidentDate"
        label="Incident Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="issuedDate"
        label="Issued Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <FormDropdownViolation<InputFormSchema> name="violationCode" />
      <FormDropdownInfraction<InputFormSchema> name="infractionCode" />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="details"
        label="Details"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter details"
      />
      <FormDropdownPenaltySchedule<InputFormSchema> name="penaltyScheduleCode" />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="actionPlan"
        label="Action Plan"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter actionPlan"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="followupDate"
        label="Followup Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter followupDate"
      />
      <FormAutocompletePerson<InputFormSchema>
        name="issuer"
        label="Issuer"
        defaultAutocompleteValue={getIssuerAutocomplete()}
        hint="Type and wait for the suggestion dropdown to appear and then select the person"
      />
      <FormAutocompletePerson<InputFormSchema>
        name="supervisor"
        label="Supervisor"
        defaultAutocompleteValue={getSupervisorAutocomplete()}
        hint="Type and wait for the suggestion dropdown to appear and then select the person"
      />
      {/* <InputControl<InputFormSchema>
        control={control}
        name="supervisor"
        label="Supervisor"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter supervisor"
      /> */}
    </div>
  );
}

export default FormEmployeeDiciplinary;
