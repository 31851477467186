import { useState } from "react";
import { CreateShiftTypeModel } from "@core/model/query-model-shift-type";
import { useQyCreateShiftType, useQyGetShiftType } from "@core/query/shift-type.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownShiftType = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newShiftType, setNewShiftType] = useState<CreateShiftTypeModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewShiftType({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newShiftType.name) {
      showWarning("Please fill in ShiftType details");
      return;
    }
    setIsCreating(true);
    addShiftType(newShiftType);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New ShiftType is added. Check and select the ShiftType in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addShiftType } = useQyCreateShiftType(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: shiftTypeResponse } = useQyGetShiftType('');
  const mappedShiftType = (shiftTypeResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newShiftType,
    mappedShiftType,
    isCreating,
    shiftTypeResponse,
    setSidebar,
    setFilter,
    setNewShiftType,
    handleFilterInput,
    handleAdd,
    addShiftType,
  };
};