import { 
    MessageResponseDto, 
    GetDepartmentDto, 
    CreateDepartmentDto, 
    EditDepartmentDto,
    UtilDepartmentControllerGet200Response,
    UtilityDepartmentApiFp,
    DeleteDepartmentDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseDepartmentModel = UtilDepartmentControllerGet200Response;
export type GetDepartmentModel = GetDepartmentDto;
export type MutateResponseDepartmentModel = MessageResponseDto;
export type CreateDepartmentModel = CreateDepartmentDto;
export type UpdateDepartmentModel = EditDepartmentDto;
export type DeleteDepartmentModel = DeleteDepartmentDto;

export const apiDepartmentFp =  UtilityDepartmentApiFp(apiConfig);
export const getDepartmentController = apiDepartmentFp.utilDepartmentControllerGet;
export const createDepartmentController = apiDepartmentFp.utilDepartmentControllerCreate;
export const updateDepartmentController = apiDepartmentFp.utilDepartmentControllerEdit;
export const deleteDepartmentController = apiDepartmentFp.utilDepartmentControllerDelete;

export const QueryKeyDepartment = QueryKey.Department;