import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeLedger } from "./filter-employee-ledger.hook";

const FilterEmployeeLedgerContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeLedger>
);
export function useFilterEmployeeLedgerContext() {
  return useContext(FilterEmployeeLedgerContext);
}

interface FilterEmployeeLedgerProviderProps {
  children: ReactNode;
}
export function FilterEmployeeLedgerProvider({
  children,
}: FilterEmployeeLedgerProviderProps) {
  return (
    <FilterEmployeeLedgerContext.Provider value={useFilterEmployeeLedger()}>
      {children}
    </FilterEmployeeLedgerContext.Provider>
  );
}