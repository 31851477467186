import { 
    MessageResponseDto, 
    GetIncidentTypeDto, 
    CreateIncidentTypeDto, 
    EditIncidentTypeDto,
    UtilIncidentTypeControllerGet200Response,
    UtilityIncidentTypeApiFp,
    DeleteIncidentTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseIncidentTypeModel = UtilIncidentTypeControllerGet200Response;
export type GetIncidentTypeModel = GetIncidentTypeDto;
export type MutateResponseIncidentTypeModel = MessageResponseDto;
export type CreateIncidentTypeModel = CreateIncidentTypeDto;
export type UpdateIncidentTypeModel = EditIncidentTypeDto;
export type DeleteIncidentTypeModel = DeleteIncidentTypeDto;

export const apiIncidentTypeFp =  UtilityIncidentTypeApiFp(apiConfig);
export const getIncidentTypeController = apiIncidentTypeFp.utilIncidentTypeControllerGet;
export const createIncidentTypeController = apiIncidentTypeFp.utilIncidentTypeControllerCreate;
export const updateIncidentTypeController = apiIncidentTypeFp.utilIncidentTypeControllerEdit;
export const deleteIncidentTypeController = apiIncidentTypeFp.utilIncidentTypeControllerDelete;

export const QueryKeyIncidentType = QueryKey.IncidentType;