import { RxHamburgerMenu } from "react-icons/rx";
import "./hamburger-menu.scss";
import { Sidebar } from "primereact/sidebar";
import { ReactNode, useState } from "react";
import { Menu } from "primereact/menu";
import { NavigationProps } from "@shared/ui/navigation/navigation.interface";
import { useNavigate } from "react-router-dom";
import config from "../../../../config.json";

export interface HamburgerMenuProps {
  logoutElement?: ReactNode;
  menu: NavigationProps[];
  username?: string;
  userRole?: string;
  userDepartment?: string;
  onLogout?: () => void;
}

export function HamburgerMenu({
  logoutElement,
  menu,
  username,
  userRole,
  userDepartment,
  onLogout,
}: HamburgerMenuProps) {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (item: any) => {
    navigate(item.path);
    setVisible(false);
  };
  const itemRenderer = (item: any) => (
    <div className="p-menuitem-content">
      <a
        className="flex align-items-center p-menuitem-link"
        onClick={() => handleNavigate(item)}
      >
        {item.icon}
        <span className="mx-2">{item.label}</span>
      </a>
    </div>
  );
  const items = menu.map((itemMenu) => {
    return {
      label: itemMenu.title,
      icon: itemMenu.icon,
      template: itemRenderer,
      path: itemMenu.path,
    };
  });

  const logoutDisplay = (title: string) => {
    return logoutElement && onLogout ? (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => onLogout()}
      >
        {logoutElement} {title}
      </div>
    ) : null;
  };
  return (
    <div
      id="HamburgerMenu"
      className="hamburger-menu p-2 bg-green-500 text-white flex justify-between items-center md:hidden"
    >
      <span
        className="m-0 block cursor-pointer p-3 hover:bg-green-800 rounded"
        onClick={() => setVisible(true)}
      >
        <RxHamburgerMenu />
      </span>
      <h4>{config.company}</h4>
      {logoutDisplay("Logout")}

      <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
        <Menu model={items} className="block w-full" />
        <div className="p-4 border-1 border-gray-400 border-solid flex justify-between bg-green-600 text-white">
          <div>
            {username && <h4 className="font-semibold">{username}</h4>}
            {userRole && <span className="text-sm text-white">{userRole}</span>}
            {userDepartment && (
              <span className="text-xs text-gray-100 block">
                {userDepartment}
              </span>
            )}
          </div>
          {logoutDisplay("Logout")}
        </div>
      </Sidebar>
    </div>
  );
}

export default HamburgerMenu;
