import { ReactNode, createContext, useContext } from "react";
import { useFilterPerson } from "./filter-person.hook";

const FilterPersonContext = createContext(
  {} as ReturnType<typeof useFilterPerson>
);
export function useFilterPersonContext() {
  return useContext(FilterPersonContext);
}

interface FilterPersonProviderProps {
  children: ReactNode;
}
export function FilterPersonProvider({
  children,
}: FilterPersonProviderProps) {
  return (
    <FilterPersonContext.Provider value={useFilterPerson()}>
      {children}
    </FilterPersonContext.Provider>
  );
}