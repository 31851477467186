import { Outlet } from "react-router-dom";

import { FilterEmployeeRequestProvider } from "./filter-employee-request/filter-employee-request.context";
import "./employee-request.scss";
import { FormDropdownStatusProvider } from "@domain/status/form-dropdown-status/form-dropdown-status.context";
import { FormDropdownShiftProvider } from "@domain/shift/form-dropdown-shift/form-dropdown-shift.context";

export function EmployeeRequest() {
  return (
    <div id="EmployeeRequest" className="employee-request">
      <FormDropdownShiftProvider>
        <FormDropdownStatusProvider>
          <FilterEmployeeRequestProvider>
            <Outlet />
          </FilterEmployeeRequestProvider>
        </FormDropdownStatusProvider>
      </FormDropdownShiftProvider>
    </div>
  );
}

export default EmployeeRequest;
