import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeAttachmentFormSchema,
  EmployeeOverageFormRule,
  EmployeeOverageFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeOverage } from "@core/form/form-default-employee-overage.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeOverage,
  useQyGetEmployeeOverageById,
  useQyDeleteEmployeeOverage,
} from "@core/query/employee-overage.query";
import { GetResponseEmployeeOverageModel } from "@core/model/query-model-employee-overage";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useState } from "react";
import { useQyUpdateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { useQyGetGoogleCloudAssetPreview } from "@core/query/google-cloud-storage.query";

export function useEditEmployeeOverage() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeOverage();
  const navigate = useNavigate();
  const {
    mapUpdateEmployeeOverage,
    mapDeleteEmployeeOverage,
    mapUpdateEmployeeAttachment,
  } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeOverageId } = useParams();
  const [attachmentInfo, setAttachmentInfo] =
    useState<EmployeeAttachmentFormSchema | null>(null);
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION

  // API UPDATE ATTACHMENT
  const { mutate: updateEmployeeAttachment } = useQyUpdateEmployeeAttachment();

  // API VIEW ASSET
  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API GETBYID EmployeeOverage
  const handleGetApiSuccess = (data: GetResponseEmployeeOverageModel) => {
    if (!data || data.count === 0) {
      return;
    }

    const responseData = data.data?.[0];
    setValue("userCode", responseData?.user_code || "");
    setValue("bucketCode", responseData?.bucket_code || "");
    setValue("amount", responseData?.amount);
    setValue(
      "effectivityDate",
      formatDate(responseData?.effectivity_date) as any,
      undefined
    );
    setValue("remarks", responseData?.remarks || "");

    const attachment = (responseData as any)?.attachments as string;
    const attachmentJson = JSON.parse(attachment);
    if (attachmentJson?.length > 0 && attachmentJson?.[0]?.file) {
      const attachmentFirst = attachmentJson[0];
      const attachmentInfoForm = {
        attachmentType: attachmentFirst.attachment_type,
        bucketCode: attachmentFirst.bucket_code,
        userCode: attachmentFirst.user_code,
        requestCode: attachmentFirst.request_code,
        source: attachmentFirst.source,
        code: attachmentFirst.code,
      } as EmployeeAttachmentFormSchema;
      setAttachmentInfo(attachmentInfoForm);

      viewAsset(attachmentFirst.file, {
        onSuccess(data) {
          const asset = URL.createObjectURL(data);
          setValue("file", asset);
        },
      });
    }
  };
  const {
    data: employeeOverageResponse,
    isLoading,
    isError: employeeOverageError,
  } = useQyGetEmployeeOverageById(employeeOverageId || "", handleGetApiSuccess);
  const employeeOverageData = employeeOverageResponse?.data?.[0];

  // API UPDATE EmployeeOverage
  const handleUpdateApiSuccess = () => {
    if (typeof fileInput !== "string") {
      const formData = mapUpdateEmployeeAttachment(
        { ...attachmentInfo, file: fileInput } as EmployeeAttachmentFormSchema,
        attachmentInfo?.code || ""
      );
      updateEmployeeAttachment(formData);
    }

    showSuccess("EmployeeOverage updated successfully");
  };
  const { mutate: updateEmployeeOverage, isLoading: isEditLoading } =
    useQyUpdateEmployeeOverage(handleUpdateApiSuccess);

  // API DELETE EmployeeOverage
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeOverage deleted successfully");
  };
  const { mutate: deleteEmployeeOverage } = useQyDeleteEmployeeOverage(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeOverageFormSchema>({
    defaultValues: getDefault(employeeOverageData),
    resolver: zodResolver(EmployeeOverageFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const fileInput = watch("file");
  const handleValidate = (form: EmployeeOverageFormSchema) => {
    if (!employeeOverageData) {
      throw new Error("No employeeOverage data");
    }

    const formData = mapUpdateEmployeeOverage(form, employeeOverageData.code);
    updateEmployeeOverage(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeOverageFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeOverageData) {
      throw new Error("No employeeOverage data found");
    }

    const form = mapDeleteEmployeeOverage(employeeOverageData.code);
    deleteEmployeeOverage(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeOverageId,
    employeeOverageData,
    isLoading,
    employeeOverageError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
