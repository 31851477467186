import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeAttachmentFormSchema,
  EmployeeLoanFormRule,
  EmployeeLoanFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeLoan } from "@core/form/form-default-employee-loan.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeLoan,
  useQyGetEmployeeLoanById,
  useQyDeleteEmployeeLoan,
  useQyProcessEmployeeLoan,
} from "@core/query/employee-loan.query";
import { GetResponseEmployeeLoanModel } from "@core/model/query-model-employee-loan";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useUserIdentityContext } from "@core/services/user-identity.context";
import { useCallback, useState } from "react";
import { useFormDropdownStatusContext } from "@domain/status/form-dropdown-status/form-dropdown-status.context";
import { useQyUpdateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { useQyGetGoogleCloudAssetPreview } from "@core/query/google-cloud-storage.query";

export function useEditEmployeeLoan() {
  // LOCAL DECLARATION
  const { mappedStatus } = useFormDropdownStatusContext();
  const { userIsAdmin } = useUserIdentityContext();
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeLoan();
  const navigate = useNavigate();
  const {
    mapUpdateEmployeeLoan,
    mapDeleteEmployeeLoan,
    mapUpdateEmployeeAttachment,
  } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const [attachmentInfo, setAttachmentInfo] =
    useState<EmployeeAttachmentFormSchema | null>(null);
  const { employeeLoanId } = useParams();
  const [showDecision, setShowDecision] = useState(false);
  const [selectedDecisionStatus, setSelectedDecisionStatus] = useState("");
  const [decisionReason, setDecisionReason] = useState("");
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API UPDATE ATTACHMENT
  const { mutate: updateEmployeeAttachment } = useQyUpdateEmployeeAttachment();

  // API VIEW ASSET
  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API GETBYID EmployeeLoan
  const handleGetApiSuccess = (data: GetResponseEmployeeLoanModel) => {
    if (!data || data.count === 0) {
      return;
    }

    const responseData = data.data?.[0];
    setValue("userCode", responseData?.user_code || "");
    setValue("bucketCode", responseData?.bucket_code || "");
    setValue("amount", responseData?.amount);
    setValue(
      "effectivityDate",
      formatDate(responseData?.effectivity_date) as any,
      undefined
    );
    setValue("subject", responseData?.subject || "");
    setValue("purpose", responseData?.purpose || "");
    setValue("intervals", (responseData as any)?.intervals);
    setValue(
      "paymentStart",
      formatDate((responseData as any)?.payment_start) as any
    );

    const attachment = (responseData as any)?.attachments as string;
    const attachmentJson = JSON.parse(attachment);
    if (attachmentJson?.length > 0 && attachmentJson?.[0]?.file) {
      const attachmentFirst = attachmentJson[0];
      const attachmentInfoForm = {
        attachmentType: attachmentFirst.attachment_type,
        bucketCode: attachmentFirst.bucket_code,
        userCode: attachmentFirst.user_code,
        requestCode: attachmentFirst.request_code,
        source: attachmentFirst.source,
        code: attachmentFirst.code,
      } as EmployeeAttachmentFormSchema;
      setAttachmentInfo(attachmentInfoForm);

      viewAsset(attachmentFirst.file, {
        onSuccess(data) {
          const asset = URL.createObjectURL(data);
          setValue("file", asset);
        },
      });
    }
  };
  const {
    data: employeeLoanResponse,
    isLoading,
    isError: employeeLoanError,
  } = useQyGetEmployeeLoanById(employeeLoanId || "", handleGetApiSuccess);
  const employeeLoanData = employeeLoanResponse?.data?.[0];

  // API UPDATE EmployeeLoan
  const handleUpdateApiSuccess = () => {
    if (typeof fileInput !== "string") {
      const formData = mapUpdateEmployeeAttachment(
        { ...attachmentInfo, file: fileInput } as EmployeeAttachmentFormSchema,
        attachmentInfo?.code || ""
      );
      updateEmployeeAttachment(formData);
    }

    showSuccess("EmployeeLoan updated successfully");
  };
  const { mutate: updateEmployeeLoan, isLoading: isEditLoading } =
    useQyUpdateEmployeeLoan(handleUpdateApiSuccess);

  // API DELETE EmployeeLoan
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeLoan deleted successfully");
  };
  const { mutate: deleteEmployeeLoan } = useQyDeleteEmployeeLoan(
    handleDeleteApiSuccess
  );

  // API PROCESS EMPLOYEE LOAN
  const processEmployeeLoanSuccess = () => {
    handleBack();
    showSuccess("Loan processed successfully");
  };
  const { mutate: processEmployeeLoan } = useQyProcessEmployeeLoan(
    processEmployeeLoanSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeLoanFormSchema>({
    defaultValues: getDefault(employeeLoanData),
    resolver: zodResolver(EmployeeLoanFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const fileInput = watch("file");
  const handleValidate = (form: EmployeeLoanFormSchema) => {
    if (!employeeLoanData) {
      throw new Error("No employeeLoan data");
    }

    const formData = mapUpdateEmployeeLoan(form, employeeLoanData.code);
    updateEmployeeLoan(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeLoanFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeLoanData) {
      throw new Error("No employeeLoan data found");
    }

    const form = mapDeleteEmployeeLoan(employeeLoanData.code);
    deleteEmployeeLoan(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;

      case ActionEnum.Process:
        setShowDecision(true);
        break;
    }
  };
  const handleSubmitDecision = () => {
    const code = employeeLoanData?.code;
    const bucket_code = employeeLoanData?.bucket_code;
    if (!code || !bucket_code) return;

    processEmployeeLoan({
      code,
      bucket_code,
      status: selectedDecisionStatus,
      reason: decisionReason,
    });
  };
  const getActionButtons = useCallback(() => {
    if (userIsAdmin()) {
      return [
        ...actions,
        {
          label: "Process",
          type: "primary",
          value: ActionEnum.Process,
        } as ButtonAction,
      ];
    }

    return actions;
  }, [userIsAdmin]);

  return {
    employeeLoanId,
    employeeLoanData,
    isLoading,
    employeeLoanError,
    formMethod,
    actions,
    isEditLoading,
    showDecision,
    selectedDecisionStatus,
    decisionReason,
    mappedStatus,
    handleSubmitDecision,
    setSelectedDecisionStatus,
    setDecisionReason,
    setShowDecision,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
    getActionButtons,
  };
}
