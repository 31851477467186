import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeBucketModel } from "@core/model/query-model-employee-bucket";
import { employeeBucketFormDefault } from "./form.default";
import { EmployeeBucketFormSchema } from "./form.rule";

export const useFormDefaultEmployeeBucket = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeBucketModel | undefined) => {
    return !cachedValue
      ? employeeBucketFormDefault
      : ({
          code: cachedValue.code,
          userCode: cachedValue.user_code,
          employeeNo: cachedValue.employee_no,
          signedDate: formatDate(cachedValue.signed_date) as any,
          startDate: formatDate(cachedValue.start_date) as any,
          endDate: formatDate(cachedValue.end_date) as any,
          jobTitle: cachedValue.job_title,
          jobGrade: cachedValue.job_grade,
          employmentType: cachedValue.employment_type,
          department: cachedValue.department,
          branch: cachedValue.branch,
          reportingTo: cachedValue.reporting_to,
          offboardReason: cachedValue.offboard_reason,
          resignationDate: formatDate(
            cachedValue.resignation_date
          ) as any,
          role: cachedValue.role,
          pagibig: cachedValue.pagibig,
          sss: cachedValue.sss,
          philhealth: cachedValue.philhealth
        } as EmployeeBucketFormSchema);
  };

  return {
    getDefault,
  };
};
