import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownEmployeeLoan } from "./form-dropdown-employee-loan.hook";

const FormDropdownEmployeeLoanContext = createContext(
  {} as ReturnType<typeof useFormDropdownEmployeeLoan>
);
export function useFormDropdownEmployeeLoanContext() {
  return useContext(FormDropdownEmployeeLoanContext);
}

interface FormDropdownEmployeeLoanProviderProps {
  children: ReactNode;
  userCode?: string;
}
export function FormDropdownEmployeeLoanProvider({
  children,
  userCode,
}: FormDropdownEmployeeLoanProviderProps) {
  return (
    <FormDropdownEmployeeLoanContext.Provider
      value={useFormDropdownEmployeeLoan({ userCode })}
    >
      {children}
    </FormDropdownEmployeeLoanContext.Provider>
  );
}
