import "./edit-employee-ledger.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormEmployeeLedger from "../form-employee-ledger/form-employee-ledger";
import { useEditEmployeeLedger } from "./edit-employee-ledger.hook";

export function EmployeeLedger() {
  const { formMethod, actions, handleBack, handleAction } =
    useEditEmployeeLedger();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormEmployeeLedger />
      </section>
    </div>
  );

  return (
    <div id="EmployeeLedger" className="employee-ledger">
      <HeaderContent
        title={"Edit Employee Ledger"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>{mainContent}</FormProvider>
      </section>
    </div>
  );
}

export default EmployeeLedger;
