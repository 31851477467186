import "./edit-employee-salary.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormEmployeeSalary from "../form-employee-salary/form-employee-salary";
import { useEditEmployeeSalary } from "./edit-employee-salary.hook";

export function EmployeeSalary() {
  const {
    employeeSalaryData,
    isLoading,
    employeeSalaryError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditEmployeeSalary();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormEmployeeSalary />
      </section>
    </div>
  );

  return (
    <div id="EmployeeSalary" className="employee-salary">
      <HeaderContent
        title={"Edit Employee Salary"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {employeeSalaryError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EmployeeSalary;
