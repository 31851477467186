import { GetPayrollSummaryDto } from "@api/api";
import { GetPayrollModel } from "@core/model/query-model-payroll";
import { LabelValue } from "@shared/models/label-value.interface";
import TextBlock from "@shared/ui/text-block/text-block";
import { currencyFormat } from "@shared/utility/formats/currency-format";
import { Avatar } from "primereact/avatar";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFormDropdownPayrollContext } from "./form-dropdown-payroll.context";
import { Button } from "primereact/button";
import { numberFormat } from "@shared/utility/formats/number-format";
import { TableExportField } from "@core/model/table-export-field.interface";
import { keyBy } from "lodash-es";

interface CellSummationProps {
  cell: GetPayrollModel;
  onDeduction?: (
    paymentData: TableExportField,
    deductions: LabelValue<number>[],
    type: "government" | "extra"
  ) => void;
  onPayslip?: (slip: TableExportField) => void;
}
export function CellSummation({
  cell,
  onDeduction,
  onPayslip,
}: CellSummationProps) {
  const { getSum, payrollExport } = useFormDropdownPayrollContext();
  const payrollSummaryEntity = keyBy(payrollExport, "userId");
  const paymentData = payrollSummaryEntity?.[cell.user_code];

  const [selectedGovFees, setSelectedGovFees] = useState<LabelValue<number>[]>(
    []
  );
  const [selectedLoanIncident, setSelectedLoanIncident] = useState<
    LabelValue<number>[]
  >([]);

  if (!paymentData) {
    return <div></div>;
  }

  const totalBonus =
    paymentData.bonusHoliday + paymentData.incentives + paymentData.otPay;
  const totalBonusText = currencyFormat(totalBonus, "PHP");
  const holidayText = currencyFormat(paymentData.bonusHoliday, "PHP");
  const incentiveText = currencyFormat(paymentData.incentives, "PHP");
  const otPayText = currencyFormat(paymentData.otPay, "PHP");

  const dailySalaryText = currencyFormat(paymentData.dailySalary, "PHP");
  const grossSalaryText = currencyFormat(paymentData.grossSalary || 0, "PHP");

  const absentsText = `${numberFormat(paymentData.absents)} day`;
  const breaksText = `${numberFormat(paymentData.breaks)} min`;
  const latesText = `${numberFormat(paymentData.lates)} min`;
  const overtimesText = `${numberFormat(paymentData.overtimes)} min`;
  const undertimesText = `${numberFormat(paymentData.undertimes)} min`;
  const leavesText = `${numberFormat(paymentData.leaves)} day(s)`;

  const deductionAbsentText = currencyFormat(paymentData.absentPenalty, "PHP");
  const deductionIncidentText = currencyFormat(
    paymentData.incidentPenalty,
    "PHP"
  );
  const deductionLateText = currencyFormat(paymentData.latePenalty, "PHP");
  const deductionBreakText = currencyFormat(paymentData.breakPenalty, "PHP");
  const deductionOverageText = currencyFormat(
    paymentData.overagePenalty,
    "PHP"
  );
  const deductionPenaltyText = currencyFormat(
    paymentData.penaltyPenalty,
    "PHP"
  );
  const deductionPaymentText = currencyFormat(paymentData.loanPayment, "PHP");
  const deductionUndertimeText = currencyFormat(
    paymentData.undertimePenalty,
    "PHP"
  );
  const grossDeductionText = currencyFormat(paymentData.grossDeduction, "PHP");

  // Gov Fees Implementation
  const pagibigValue = paymentData?.pagibig || 0;
  const philhealthValue = paymentData?.philhealth || 0;
  const sssValue = paymentData?.sss || 0;
  const govFeesCollection = [
    {
      label: `PAGIBIG`,
      value: pagibigValue,
    },
    {
      label: `Philhealth`,
      value: philhealthValue,
    },
    { label: `SSS`, value: sssValue },
  ] as LabelValue<number>[];

  const onGovFeeChange = (e: CheckboxChangeEvent) => {
    let _selectedGovFees = [...selectedGovFees] as LabelValue<number>[];

    if (e.checked) _selectedGovFees.push(e.value);
    else {
      _selectedGovFees = _selectedGovFees.filter(
        (govFee) => govFee?.label !== e.value.label
      );
    }

    if (onDeduction) {
      onDeduction(paymentData, _selectedGovFees, "government");
    }
    setSelectedGovFees(_selectedGovFees);
  };
  const selectedGovFeeAmount = selectedGovFees.map((x) => x.value);
  const totalGovDeduction = getSum(selectedGovFeeAmount);

  // Extra Payment Implementation
  // Loan and incident implementation
  // if (paymentData?.user_code === "1c2ad27a-f505-49f7-89ab-286e21a11abe") {
  //   paymentData.loan_balance = 300;
  // }

  // const optinalLoanPayment =
  //   paymentData?.deduction_payment === 0 && paymentData?.loan_balance > 0
  //     ? paymentData?.loan_balance
  //     : 0;
  // const optionalIncidentPayment =
  // paymentData?.deduction_incident === 0 && paymentData?.incident_balance > 0
  //     ? paymentData?.loan_balance
  //     : 0;
  // const loanIncidentCollection = [
  //   { label: "Loan", value: 0 },
  //   { label: "Incident", value: 0 },
  // ] as LabelValue<number>[];
  // const filteredLoanIncidentCollection = loanIncidentCollection.filter(
  //   (x) => x.value != 0
  // );
  // const onExtraPaymentChange = (e: CheckboxChangeEvent) => {
  //   let _selectedLoanIncident = [
  //     ...selectedLoanIncident,
  //   ] as LabelValue<number>[];

  //   if (e.checked) _selectedLoanIncident.push(e.value);
  //   else {
  //     _selectedLoanIncident = _selectedLoanIncident.filter(
  //       (extraFee) => extraFee?.label > e.value.label
  //     );
  //   }

  //   if (onDeduction) {
  //     onDeduction(paymentData, _selectedLoanIncident, "extra");
  //   }
  //   setSelectedLoanIncident(_selectedLoanIncident);
  // };
  const totalApplicableExtraPaymentAmount = selectedLoanIncident.map(
    (x) => x.value
  );
  const totalApplicableLoan = getSum(totalApplicableExtraPaymentAmount);
  const netDeduction =
    paymentData.grossDeduction + totalGovDeduction + totalApplicableLoan;
  const netDeductionText = currencyFormat(netDeduction, "PHP");
  const grandSalary = paymentData.grossSalary + totalBonus - netDeduction;
  const grandSalaryText = currencyFormat(grandSalary, "PHP");

  return (
    <div className="relative top-[-30px] ml-10 flex gap-4">
      <Avatar
        label={cell.person_first_name[0] || "-"}
        style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
        shape="circle"
      />
      <section id="person-info" className="flex-col">
        <span className="font-bold text-base">
          {cell.person_first_name} {cell.person_last_name}
        </span>
        <small className="block">{cell?.job_title_name}</small>
        <small className="block">{cell?.employment_type_name}</small>
        <small className="block">{cell?.department_name}</small>
      </section>
      <div className="ml-8">
        <div className="flex justify-between">
          <h5>Summation</h5>
          <Button
            label="Payslip"
            size="small"
            outlined
            onClick={() => {
              if (onPayslip) {
                onPayslip(paymentData);
              }
            }}
          />
        </div>
        <Divider />
        <section className="flex gap-8">
          <section id="salary">
            <TextBlock
              label="Daily Rate"
              value={dailySalaryText}
              mode="horizontal"
            />
            <TextBlock
              label="Gross Salary"
              value={grossSalaryText}
              mode="horizontal"
            />
            <TextBlock
              label="Additional Salary"
              value={totalBonusText}
              mode="horizontal"
            />
            <TextBlock
              className="mt-4"
              label="Gross Deduction"
              value={grossDeductionText}
              mode="horizontal"
            />
            <TextBlock
              label="Net Deduction"
              value={netDeductionText}
              mode="horizontal"
            />
            <TextBlock
              className="mt-4"
              label="Overall Salary"
              value={grandSalaryText}
              mode="horizontal"
            />
          </section>
          <section id="additional-salary">
            <span className="text-gray-400 font-bold text-sm">Additional</span>
            <TextBlock label="Holiday" value={holidayText} mode="horizontal" />
            <TextBlock
              label="Incentives"
              value={incentiveText}
              mode="horizontal"
            />
            <TextBlock label="OT Pay" value={otPayText} mode="horizontal" />
          </section>
          <section id="time">
            <span className="text-gray-400 font-bold text-sm">Time</span>
            <TextBlock label="Absent" value={absentsText} mode="horizontal" />
            <TextBlock label="Break" value={breaksText} mode="horizontal" />
            <TextBlock label="Late" value={latesText} mode="horizontal" />
            <TextBlock
              label="Overtime"
              value={overtimesText}
              mode="horizontal"
            />
            <TextBlock
              label="Undertime"
              value={undertimesText}
              mode="horizontal"
            />
            <TextBlock label="Leave" value={leavesText} mode="horizontal" />
          </section>
          <section id="deduction">
            <span className="text-gray-400 font-bold text-sm">Deductions</span>
            <TextBlock
              label="Absent"
              value={deductionAbsentText}
              mode="horizontal"
            />
            <TextBlock
              label="Incident"
              value={deductionIncidentText}
              mode="horizontal"
            />
            <TextBlock
              label="Late"
              value={deductionLateText}
              mode="horizontal"
            />
            <TextBlock
              label="Overbreak"
              value={deductionBreakText}
              mode="horizontal"
            />
            <TextBlock
              label="Overage"
              value={deductionOverageText}
              mode="horizontal"
            />
            <TextBlock
              label="Loan Payment"
              value={deductionPaymentText}
              mode="horizontal"
            />
            <TextBlock
              label="Penalty"
              value={deductionPenaltyText}
              mode="horizontal"
            />
            <TextBlock
              label="Undertime"
              value={deductionUndertimeText}
              mode="horizontal"
            />
          </section>
          <section id="govFees">
            <span className="text-gray-400 font-bold text-sm">
              Government Fees
            </span>
            {govFeesCollection.map((collection) => {
              const id = uuidv4();
              return (
                <div key={id} className="flex mt-1 align-items-center">
                  <Checkbox
                    inputId={id}
                    name="collection"
                    value={collection}
                    onChange={onGovFeeChange}
                    checked={selectedGovFees.some(
                      (item) => item.label === collection.label
                    )}
                  />
                  <label
                    htmlFor={id}
                    className="ml-2 text-gray-500 mb-0 text-sm font-bold"
                  >
                    {collection.label} (
                    {currencyFormat(collection.value, "PHP")})
                  </label>
                </div>
              );
            })}
          </section>
          {/* {filteredLoanIncidentCollection.length > 0 && (
            <section id="extraFees">
              <span className="text-gray-400 font-bold text-sm">
                Extra Fees
              </span>
              {filteredLoanIncidentCollection.map((collection) => {
                const id = uuidv4();
                return (
                  <div key={id} className="flex mt-1 align-items-center">
                    <Checkbox
                      inputId={id}
                      name="collection"
                      value={collection}
                      onChange={onExtraPaymentChange}
                      checked={selectedLoanIncident.some(
                        (item) => item.label === collection.label
                      )}
                    />
                    <label
                      htmlFor={id}
                      className="ml-2 text-gray-500 mb-0 text-sm font-bold"
                    >
                      {collection.label} (
                      {currencyFormat(collection.value, "PHP")})
                    </label>
                  </div>
                );
              })}
            </section>
          )} */}
        </section>
      </div>
    </div>
  );
}
