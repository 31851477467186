import "./new-employee-incentive.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeIncentive } from "@core/query/employee-incentive.query";
import {
  EmployeeAttachmentFormSchema,
  EmployeeIncentiveFormRule,
  EmployeeIncentiveFormSchema,
} from "@core/form/form.rule";
import { employeeIncentiveFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeIncentive from "../form-employee-incentive/form-employee-incentive";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useQyCreateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { AttachmentSource } from "@core/model/query-model-employee-attachment";

export function NewEmployeeIncentive() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateEmployeeIncentive, mapCreateEmployeeAttachment } =
    useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();
  const { selectedPersonData } = useFormDropdownPersonContext();

  // QUERIES
  // API ATTACHMENT
  const { mutate: addEmployeeAttachment } = useQyCreateEmployeeAttachment();

  // API ADD INCENTIVE
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    if (typeof incentiveFile === "object") {
      const mutatedForm = {
        userCode: selectedPersonData?.code,
        bucketCode: selectedPersonData?.bucket.code,
        isActive: true,
        source: AttachmentSource.Incentive,
        file: incentiveFile,
        requestCode: data.code,
      } as EmployeeAttachmentFormSchema;

      const formData = mapCreateEmployeeAttachment(mutatedForm);
      addEmployeeAttachment(formData);
    }

    showSuccess(`New EmployeeIncentive is created`);
    navigate(`../`);
  };
  const { mutate: addEmployeeIncentive, isLoading: isCreating } =
    useQyCreateEmployeeIncentive(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeIncentiveFormSchema>({
    defaultValues: employeeIncentiveFormDefault,
    resolver: zodResolver(EmployeeIncentiveFormRule),
  });
  const { handleSubmit, watch } = formMethod;
  const incentiveFile = watch("file");

  const handleValidate = (form: EmployeeIncentiveFormSchema) => {
    const defaultFormData = {
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
    };

    const updatedForm = {
      ...form,
      userCode: defaultFormData.userCode,
      bucketCode: defaultFormData.bucketCode,
    } as EmployeeIncentiveFormSchema;
    const formData = mapCreateEmployeeIncentive(updatedForm);
    addEmployeeIncentive(formData);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeIncentiveFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeIncentive" className="employee-incentive">
      <HeaderContent
        title="New Employee Incentive"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeeIncentive />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeIncentive;
