import "./public-leave-request.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaChevronLeft } from "react-icons/fa6";
import HeaderContent from "@shared/ui/header-content/header-content";
import { Button } from "primereact/button";
import {
  EmployeeRequestFormRule,
  EmployeeRequestFormSchema,
} from "@core/form/form.rule";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { employeeRequestFormDefault } from "@core/form/form.default";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyPublicLeaveEmployeeRequest } from "@core/query/employee-request.query";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import FormEmployeeRequestLeave from "../form-employee-request-leave/form-employee-request-leave";

/* eslint-disable-next-line */
export interface PublicLeaveRequestProps {}

export function PublicLeaveRequest() {
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotificationContext();
  const { mapLeaveEmployeeRequest } = useFormApiMapper();
  const [searchParam] = useSearchParams();
  const bucket = searchParam.get("bucket");
  const name = searchParam.get("name");

  const handleApiSuccess = () => {
    showSuccess(`New Employee Request is created`);
    navigate(`/clock`);
  };
  const { mutate: leaveRequest, isLoading: isLoadingLeave } =
    useQyPublicLeaveEmployeeRequest(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeRequestFormSchema>({
    defaultValues: employeeRequestFormDefault,
    resolver: zodResolver(EmployeeRequestFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeRequestFormSchema) => {
    const mutatedForm = {
      ...form,
      userCode: "",
      bucketCode: bucket,
      requestType: "selectedRequestType",
    } as EmployeeRequestFormSchema;

    const leavePayload = mapLeaveEmployeeRequest(mutatedForm);
    leaveRequest(leavePayload);
  };
  const handleValidateError = (err: FieldErrors<EmployeeRequestFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="PublicLeaveRequest" className="public-leave-request w-[500px]">
      <HeaderContent
        title={`Please fill all the fields, ${name}`}
        backIcon={<FaChevronLeft />}
      >
        <Button
          className="w-full block"
          label="Save"
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <FormEmployeeRequestLeave standalone={true} />
        </FormProvider>
      </div>
    </div>
  );
}

export default PublicLeaveRequest;
