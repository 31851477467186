import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeLoanModel } from "@core/model/query-model-employee-loan";
import { employeeLoanFormDefault } from "./form.default";
import { EmployeeLoanFormSchema } from "./form.rule";

export const useFormDefaultEmployeeLoan = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeLoanModel | undefined) => {
    return !cachedValue
      ? employeeLoanFormDefault
     : ({
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        amount: cachedValue.amount,
        effectivityDate: formatDate(cachedValue.effectivity_date) as any,
        subject: cachedValue.subject,
        purpose: cachedValue.purpose,
      } as EmployeeLoanFormSchema);
  };

  return {
    getDefault,
  };
};
