import { ReactNode, createContext, useContext } from "react";
import { useFilterDailyTimeRecord } from "./filter-daily-time-record.hook";

const FilterDailyTimeRecordContext = createContext(
  {} as ReturnType<typeof useFilterDailyTimeRecord>
);
export function useFilterDailyTimeRecordContext() {
  return useContext(FilterDailyTimeRecordContext);
}

interface FilterDailyTimeRecordProviderProps {
  children: ReactNode;
}
export function FilterDailyTimeRecordProvider({
  children,
}: FilterDailyTimeRecordProviderProps) {
  return (
    <FilterDailyTimeRecordContext.Provider value={useFilterDailyTimeRecord()}>
      {children}
    </FilterDailyTimeRecordContext.Provider>
  );
}