import { 
    MessageResponseDto, 
    GetEmployeeIncidentDto, 
    CreateEmployeeIncidentDto, 
    EditEmployeeIncidentDto,
    EmployeeIncidentControllerGet200Response,
    EmployeeIncidentApiFp,
    DeleteEmployeeIncidentDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeIncidentModel = EmployeeIncidentControllerGet200Response;
export type GetEmployeeIncidentModel = GetEmployeeIncidentDto;
export type MutateResponseEmployeeIncidentModel = MessageResponseDto;
export type CreateEmployeeIncidentModel = CreateEmployeeIncidentDto;
export type UpdateEmployeeIncidentModel = EditEmployeeIncidentDto;
export type DeleteEmployeeIncidentModel = DeleteEmployeeIncidentDto;

export const apiEmployeeIncidentFp = EmployeeIncidentApiFp(apiConfig);
export const getEmployeeIncidentController = apiEmployeeIncidentFp.employeeIncidentControllerGet;
export const createEmployeeIncidentController = apiEmployeeIncidentFp.employeeIncidentControllerCreate;
export const updateEmployeeIncidentController = apiEmployeeIncidentFp.employeeIncidentControllerEdit;
export const deleteEmployeeIncidentController = apiEmployeeIncidentFp.employeeIncidentControllerDelete;

export const QueryKeyEmployeeIncident = QueryKey.EmployeeIncident;