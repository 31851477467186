import './new-payment-method.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreatePaymentMethod } from "@core/query/payment-method.query";
import { PaymentMethodFormRule, PaymentMethodFormSchema } from "@core/form/form.rule";
import { paymentMethodFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormPaymentMethod from "../form-payment-method/form-payment-method";

export function PaymentMethod() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreatePaymentMethod } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New PaymentMethod is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addPaymentMethod, isLoading: isCreating } =
    useQyCreatePaymentMethod(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<PaymentMethodFormSchema>({
    defaultValues: paymentMethodFormDefault,
    resolver: zodResolver(PaymentMethodFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: PaymentMethodFormSchema) => {
    const formData = mapCreatePaymentMethod(form);
    addPaymentMethod(formData);
  };
  const handleValidateError = (err: FieldErrors<PaymentMethodFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="PaymentMethod" className="payment-method">
      <HeaderContent
        title="New Payment Method"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormPaymentMethod />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default PaymentMethod;