import { Outlet } from "react-router-dom";

import { FilterEmployeeSalaryProvider } from "./filter-employee-salary/filter-employee-salary.context";
import "./employee-salary.scss";
import { FormDropdownSalaryTypeProvider } from "@domain/salary-type/form-dropdown-salary-type/form-dropdown-salary-type.context";

export function EmployeeSalary() {
  return (
    <div id="EmployeeSalary" className="employee-salary">
      <FormDropdownSalaryTypeProvider>
        <FilterEmployeeSalaryProvider>
          <Outlet />
        </FilterEmployeeSalaryProvider>
      </FormDropdownSalaryTypeProvider>
    </div>
  );
}

export default EmployeeSalary;
