import { EditHolidayTypeDto } from "@api/api";
import { holidayTypeFormDefault } from "./form.default";
import { HolidayTypeFormSchema } from "./form.rule";

export const useFormDefaultHolidayType = () => {
  const getDefault = (cachedValue: EditHolidayTypeDto | undefined) => {
    return !cachedValue
      ? holidayTypeFormDefault
     : ({
				name: cachedValue.name,
				description: cachedValue.description,
        percentage: cachedValue.percentage
      } as HolidayTypeFormSchema);
  };

  return {
    getDefault,
  };
};
