import { useState } from "react";
import { CreateInfractionModel } from "@core/model/query-model-infraction";
import {
  useQyCreateInfraction,
  useQyGetInfraction,
} from "@core/query/infraction.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export const useFormDropdownInfraction = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newInfraction, setNewInfraction] = useState<CreateInfractionModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewInfraction({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newInfraction.name) {
      showWarning("Please fill in Infraction details");
      return;
    }
    setIsCreating(true);
    addInfraction(newInfraction);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New Infraction is added. Check and select the Infraction in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addInfraction } = useQyCreateInfraction(
    handleAddApiSuccess,
    handleAddApiError
  );
  const { data: infractionResponse } = useQyGetInfraction("");
  const mappedInfraction = (infractionResponse?.data || []).map(
    (item) =>
      ({
        label: item.description,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    newInfraction,
    mappedInfraction,
    isCreating,
    infractionResponse,
    setSidebar,
    setFilter,
    setNewInfraction,
    handleFilterInput,
    handleAdd,
    addInfraction,
  };
};
