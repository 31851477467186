import { Outlet, useParams } from "react-router-dom";

import { FilterEmployeeDeductionProvider } from "./filter-employee-deduction/filter-employee-deduction.context";
import "./employee-deduction.scss";
import { FormDropdownEmployeeIncidentProvider } from "@domain/employee-incident/form-dropdown-employee-incident/form-dropdown-employee-incident.context";

export function EmployeeDeduction() {
  const { personId } = useParams();
  return (
    <div id="EmployeeDeduction" className="employee-deduction">
      <FormDropdownEmployeeIncidentProvider userCode={personId}>
        <FilterEmployeeDeductionProvider>
          <Outlet />
        </FilterEmployeeDeductionProvider>
      </FormDropdownEmployeeIncidentProvider>
    </div>
  );
}

export default EmployeeDeduction;
