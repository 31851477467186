import { ReactNode, createContext, useContext } from "react";
import { useFilterRequestType } from "./filter-request-type.hook";

const FilterRequestTypeContext = createContext(
  {} as ReturnType<typeof useFilterRequestType>
);
export function useFilterRequestTypeContext() {
  return useContext(FilterRequestTypeContext);
}

interface FilterRequestTypeProviderProps {
  children: ReactNode;
}
export function FilterRequestTypeProvider({
  children,
}: FilterRequestTypeProviderProps) {
  return (
    <FilterRequestTypeContext.Provider value={useFilterRequestType()}>
      {children}
    </FilterRequestTypeContext.Provider>
  );
}