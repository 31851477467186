import classNames from "classnames";
import { SyntheticEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Chip, ChipRemoveEvent } from "primereact/chip";
import { useFormDropdownRoleContext } from "@domain/role/form-dropdown-role/form-dropdown-role.context";
import { Dropdown } from "primereact/dropdown";
import { LabelValue } from "@shared/models/label-value.interface";

export function useFilterPerson() {
  // THIS IS THE LOCAL DECLARATION
  const { mappedRole } = useFormDropdownRoleContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const reportsParam = searchParams.get("reports");
  const dateNameParam = searchParams.get("dateName") || undefined;
  const startDateParam = searchParams.get("startDate") || undefined;
  const endDateParam = searchParams.get("endDate") || undefined;
  const [filteredRole, setFilteredRole] = useState("");
  const [filterEntity, setFilterEntity] = useState<Record<string, LabelValue>>(
    {}
  );

  // ADD/REPLACE DEFAULT FILTER STATE
  const [selectedDate, setSelectedDate] = useState("");

  // THIS IS THE LOCAL FUNCTION
  const getFilterEntityValues = useCallback(() => {
    const transformed = LabelValue.TransformAsRecord(filterEntity);
    return transformed;
  }, [filterEntity]);
  const getFilterCount = () => {
    const values = Object.values(filterEntity).filter((x) => !!x);
    let filterEntityCount = values.length || 0;

    if (!!dateNameParam) {
      filterEntityCount++;
    }
    if (!!startDateParam) {
      filterEntityCount++;
    }
    if (!!endDateParam) {
      filterEntityCount++;
    }

    return filterEntityCount;
  };
  const applyFilter = (field: string, data: LabelValue) => {
    const filterVal = {
      ...filterEntity,
      [field]: data,
    } as Record<string, LabelValue>;

    setFilterEntity(filterVal);
  };
  const removeAllFilters = () => {
    const filterVal = {} as Record<string, LabelValue>;
    setSearchParams({});
    setFilterEntity(filterVal);
    setFilteredRole("");
    setSelectedDate("");
  };
  const removeFilterBy = (filterName: string) => {
    const updatedFilterEntity = {
      ...filterEntity,
      [filterName]: {} as LabelValue,
    };
    setFilterEntity(updatedFilterEntity);

    if (filterName === "date") {
      setSelectedDate("");
    }
  };
  const handleRemoveChip = (e: ChipRemoveEvent) => {
    if (e.value.indexOf(":") < 0) {
      return;
    }

    const filterName = e.value.split(":")[0];
    removeFilterBy(filterName);
  };
  const handleClear = (e: SyntheticEvent) => {
    e.preventDefault();
    removeAllFilters();
  };

  const hasReportFilterElements =
    !!dateNameParam && !!startDateParam && !!endDateParam;
  const reportFilterElements = hasReportFilterElements ? (
    <div>
      <h5>Report filters:</h5>
      {!!dateNameParam ? (
        <Tag severity="info">
          <div className="flex align-items-center gap-2">
            <span className="text-base">Date Name: {dateNameParam}</span>
          </div>
        </Tag>
      ) : null}
      {!!startDateParam ? (
        <Tag severity="info">
          <div className="flex align-items-center gap-2">
            <span className="text-base">Start Date: {startDateParam}</span>
          </div>
        </Tag>
      ) : null}
      {!!endDateParam ? (
        <Tag severity="info">
          <div className="flex align-items-center gap-2">
            <span className="text-base">End Date: {endDateParam}</span>
          </div>
        </Tag>
      ) : null}
    </div>
  ) : null;
  const clearFiltersElement = (
    <Button
      label="Clear Filters"
      severity="secondary"
      className="block mt-4"
      outlined
      onClick={handleClear}
    />
  );
  const hasAppliedFilters =
    Object.values(filterEntity).filter((i) => !!i).length > 0;
  const filterChipsElement = hasAppliedFilters ? (
    <section>
      <small>Applied Filters:</small>
      <div className="flex">
        {Object.keys(filterEntity).map((i) => {
          if (filterEntity[i] === ({} as LabelValue)) {
            return null;
          }
          return (
            <Chip
              key={i}
              label={`${i}: ${filterEntity[i].label}`}
              removable
              onRemove={handleRemoveChip}
            />
          );
        })}
      </div>
    </section>
  ) : null;

  // ADD / REPLACE FILTER ELEMENT
  const dateFilterElement = (
    <span className={classNames("flex flex-col-reverse gap-1")}>
      <InputText
        value={selectedDate}
        type="date"
        id="date"
        onInput={(e: any) => {
          setSelectedDate(e.target.value);
          applyFilter("date", e.target.value);
        }}
      />

      <label className="text-xs text-gray-500" htmlFor="date">
        Date
      </label>
    </span>
  );
  const roleFilterElement = (
    <div className="flex flex-col">
      <label className="text-xs text-gray-500" htmlFor="start">
        Role
      </label>
      <Dropdown
        value={filteredRole}
        onChange={(e) => {
          setFilteredRole(e.value);
          const getLabel = mappedRole.find(
            (item) => item.value === e.value
          )?.label;
          applyFilter("role", {
            label: getLabel || "Unknown",
            value: e.value,
          } as LabelValue);
        }}
        options={mappedRole}
        optionLabel="label"
        placeholder="Select a role"
        className="w-full md:w-14rem"
        filter
      />
    </div>
  );

  return {
    roleFilterElement,
    dateFilterElement,
    filterEntity,
    reportFilterElements,
    clearFiltersElement,
    filterChipsElement,
    removeAllFilters,
    getFilterCount,
    getFilterEntityValues,
  };
}
