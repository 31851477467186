import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeDeductionModel } from "@core/model/query-model-employee-deduction";
import { employeeDeductionFormDefault } from "./form.default";
import { EmployeeDeductionFormSchema } from "./form.rule";

export const useFormDefaultEmployeeDeduction = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeDeductionModel | undefined) => {
    return !cachedValue
      ? employeeDeductionFormDefault
      : ({
          userCode: cachedValue.user_code,
          bucketCode: cachedValue.bucket_code,
          incident: cachedValue.incident_code,
          amount: cachedValue.amount,
          effectivityDate: formatDate(cachedValue.effectivity_date) as any,
          remarks: cachedValue.remarks,
        } as EmployeeDeductionFormSchema);
  };

  return {
    getDefault,
  };
};
