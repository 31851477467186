import { useState } from "react";
import { CreateFavoriteModel } from "@core/model/query-model-favorite";
import {
  useQyCreateFavorite,
  useQyGetFavorite,
} from "@core/query/favorite.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useQyGetEmployeeFavorite } from "@core/query/employee-favorite.query";
import { groupBy } from "lodash-es";
import { GetResponseEmployeeFavoriteModel } from "@core/model/query-model-employee-favorite";
import { GetEmployeeFavoriteDto } from "@api/api";

export const useFormDropdownFavorite = () => {
  const { showWarning, showSuccess } = useNotificationContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [newFavorite, setNewFavorite] = useState<CreateFavoriteModel>({
    name: "",
    description: "",
  });
  const [isCreating, setIsCreating] = useState(false);
  const [groupRecord, setGroupRecord] =
    useState<Record<string, GetEmployeeFavoriteDto[]>>();
  const [employeeGroup, setEmployeeGroup] = useState<LabelValue[]>();

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setNewFavorite({
        name: filter,
        description: "",
      });
      setSidebar(true);
    }
  };
  const handleAdd = () => {
    if (!newFavorite.name) {
      showWarning("Please fill in Favorite details");
      return;
    }
    setIsCreating(true);
    addFavorite(newFavorite);
  };

  const handleAddApiSuccess = () => {
    showSuccess(
      "New Favorite is added. Check and select the Favorite in the form."
    );
    setSidebar(false);
    setIsCreating(false);
  };
  const handleAddApiError = () => {
    setIsCreating(false);
  };
  const { mutate: addFavorite } = useQyCreateFavorite(
    handleAddApiSuccess,
    handleAddApiError
  );

  const handleApiGet = (favoriteResponse: GetResponseEmployeeFavoriteModel) => {
    const employeeGroupData = favoriteResponse?.data || [];
    const grouped = groupBy(employeeGroupData, (item) => item.favorite_code);
    const employeeGroupList = Object.keys(grouped).map(
      (key) =>
        ({
          label: grouped[key]?.[0].favorite_name,
          value: key,
        } as LabelValue)
    );
    setGroupRecord(grouped);
    setEmployeeGroup(employeeGroupList);
  };
  const { data: favoriteResponse } = useQyGetEmployeeFavorite(
    "",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
    handleApiGet
  );

  return {
    sidebar,
    filter,
    newFavorite,
    groupRecord,
    employeeGroup,
    isCreating,
    favoriteResponse,
    setSidebar,
    setFilter,
    setNewFavorite,
    handleFilterInput,
    handleAdd,
    addFavorite,
  };
};
