import { Outlet } from "react-router-dom";

import { FilterShiftTypeProvider } from "./filter-shift-type/filter-shift-type.context";
import './shift-type.scss';

export function ShiftType() {
  return (
    <div id="ShiftType" className="shift-type">
       <FilterShiftTypeProvider>
        <Outlet />
      </FilterShiftTypeProvider>
    </div>
  );
}

export default ShiftType;
