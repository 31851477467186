import { ReactNode, createContext, useContext } from "react";
import { useFilterIncidentType } from "./filter-incident-type.hook";

const FilterIncidentTypeContext = createContext(
  {} as ReturnType<typeof useFilterIncidentType>
);
export function useFilterIncidentTypeContext() {
  return useContext(FilterIncidentTypeContext);
}

interface FilterIncidentTypeProviderProps {
  children: ReactNode;
}
export function FilterIncidentTypeProvider({
  children,
}: FilterIncidentTypeProviderProps) {
  return (
    <FilterIncidentTypeContext.Provider value={useFilterIncidentType()}>
      {children}
    </FilterIncidentTypeContext.Provider>
  );
}