import "./list-event.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  EventSourceFuncArg,
} from "@fullcalendar/core";
import interactionPlugin, {
  DateClickArg,
  Draggable,
} from "@fullcalendar/interaction";
import classNames from "classnames";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import QuickViewEvent from "../quick-view-event/quick-view-event";
import HeaderContent from "@shared/ui/header-content/header-content";
import { FilterEventPage } from "../filter-event-page/filter-event-page";
import { useFilterEventPageContext } from "../filter-event-page/filter-event-page.context";
import { useQyGetDailyTimeShifts } from "@core/query/daily-time-record.query";
import { useCalendarEvent } from "../calendar-helper.hook";
import {
  GetResponseShiftDataModel,
  ShiftResponseModel,
} from "@core/model/query-model-daily-time-record";
import { Outlet, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { ShiftEvent } from "@core/model/shift-event.enum";
import { BlockUI } from "primereact/blockui";
import DotLabel from "@shared/ui/dot-label/dot-label";
import { getShouldFetchShifts } from "@core/utility/fetch-shift";
import { CalendarEvent } from "../../../core/model/calendar-event.interface";

export function ListEvent() {
  const { calendarMap, calendarGetShiftStyle } = useCalendarEvent();
  const { formatDate, formatTime } = useDateTimeContext();
  const draggableEl = document.getElementById("external-events");
  const navigate = useNavigate();
  const {
    calendarDate,
    selectedBranch,
    selectedEvent,
    detailSidebar,
    setDetailSidebar,
    setSelectedEvent,
    setCalendarDate,
    getEmployeeBuckets,
  } = useFilterEventPageContext();
  const [eventData, setEventData] = useState<CalendarEvent[]>([]);
  const selectedEmployeeBuckets = getEmployeeBuckets();
  const shouldFetchShifts = getShouldFetchShifts(
    calendarDate,
    selectedEmployeeBuckets
  );

  // QUERIES
  const handleShiftSuccess = (response: ShiftResponseModel) => {
    const mappedEvent = (response.data || [])
      .filter((item: GetResponseShiftDataModel) => !!item.shift_code)
      .map((item: GetResponseShiftDataModel) => {
        const mappedCalendar = calendarMap(item);
        const isTemporary =
          mappedCalendar.extendedProps?.originalBranchId !== selectedBranch;

        if (isTemporary) {
          mappedCalendar.backgroundColor = calendarGetShiftStyle(
            ShiftEvent.TemporaryWorkday
          ).color;
          mappedCalendar.borderColor = calendarGetShiftStyle(
            ShiftEvent.TemporaryWorkday
          ).color;
          mappedCalendar.extendedProps = {
            ...mappedCalendar.extendedProps,
            isTemporary: true,
          };
        }

        return mappedCalendar;
      });
    setEventData(mappedEvent);
  };
  const { isFetching } = useQyGetDailyTimeShifts(
    calendarDate.start,
    calendarDate.end,
    selectedEmployeeBuckets,
    shouldFetchShifts,
    handleShiftSuccess
  );

  // LOCAL FUNCTION
  useEffect(() => {
    if (!draggableEl) return;

    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let id = eventEl.dataset.id;
        let title = eventEl.getAttribute("title");
        let color = eventEl.dataset.color;

        return {
          id: id,
          title: title,
          color: color,
          create: true,
        };
      },
    });
  }, [draggableEl]);

  const handleCalendarDateSet = (event: DatesSetArg) => {
    console.log("handleCalendarDateSet", event);
    setCalendarDate({
      start: event.startStr,
      end: event.endStr,
    });
  };
  const handleCalendarEvent = (
    event: EventSourceFuncArg,
    success: any,
    failed: any
  ) => {
    success(eventData);
  };
  const handleEventContent = (info: EventContentArg) => {
    const origBranch = info.event.extendedProps?.originalBranchName || "";
    const isTemporary =
      info.event.extendedProps?.isTemporary && origBranch !== "";
    const startTime = info.event.start ? formatTime(info.event.start) : null;
    const endTime = info.event.end ? formatTime(info.event.end) : null;
    let timeText = `${startTime}-${endTime}`;
    const shiftName = info.event.extendedProps.shiftName;
    const shiftNameDisplay = <small>({shiftName})</small>;

    if (!startTime && !endTime) {
      timeText = "";
    }

    const timeTextElement = (
      <>
        <div
          className={classNames("fc-daygrid-event-dot")}
          style={{ borderColor: info.borderColor }}
        ></div>
        <div>{timeText}</div>
      </>
    );
    return (
      <div className="flex gap-1 w-full items-center px-2 flex-wrap border-b pb-2">
        {info.timeText ? timeTextElement : null}
        {shiftName ? shiftNameDisplay : null}
        <div className="font-bold">{info.event.title}</div>
        {isTemporary && <div>({origBranch})</div>}
      </div>
    );
  };
  const handleMoreLinkClick = (info: any) => {
    console.log({ info, dateStr: info.date.toISOString() });
    navigate(`list?date=${info.date.toISOString()}&branch=${selectedBranch}`);
    return undefined;
  };
  const handleDateClick = (info: DateClickArg) => {
    navigate(`list?date=${info.date.toISOString()}&branch=${selectedBranch}`);
  };
  const handleEventClicked = (info: EventClickArg) => {
    const _event = info.event as unknown as CalendarEvent;
    setSelectedEvent({
      name: _event.title,
      branchId: _event.extendedProps?.branchId,
      bucketId: _event.extendedProps?.bucketCode,
      userId: _event.extendedProps?.userId,
      eventType: _event.extendedProps?.eventType,
      startShift: _event.start,
      endShift: _event.end,
      shiftCode: _event.id,
      shiftName: _event.extendedProps?.shiftName,
    });
    setDetailSidebar(true);
  };
  // This is for drag and dropping events between days already in the calendar
  const handleEventDrop = (info: any) => {
    console.log("handleEventDrop: Do API Update here", info);
  };
  // This is for external drag and drop events
  const handleExternalEventDrop = (info: any) => {
    const newEvent = {
      title: info.draggedEl.getAttribute("title"),
      bucketId: info.draggedEl.getAttribute("data-bucket-id"),
      userId: info.draggedEl.getAttribute("data-user-id"),
      color: info.draggedEl.getAttribute("data-color"),
      date: info.event.start,
    };
    const title = `New Shift ${newEvent.title}`;
    const bucketCode = newEvent.bucketId;
    const userCode = newEvent.userId;
    const day = format(newEvent.date, "iiii");
    const effectivityDate = formatDate(newEvent.date);

    navigate(
      `new-shift?title=${title}&bucketCode=${bucketCode}&userCode=${userCode}&day=${day}&effectivityDate=${effectivityDate}`
    );
  };

  // HTML
  const eventDetailSection = (
    <Sidebar
      visible={detailSidebar}
      onHide={() => setDetailSidebar(false)}
      className="w-full md:w-[700px]"
    >
      {!!selectedEvent && (
        <QuickViewEvent
          eventData={selectedEvent}
          onClose={() => setDetailSidebar(false)}
        />
      )}
    </Sidebar>
  );
  const legendSection = (
    <section className="mb-4">
      <section className="flex justify-between p-4 rounded bg-gray-100">
        <section>
          <small>Legend</small>
          <section className="flex gap-4">
            <DotLabel
              label="Work Day"
              classBg={calendarGetShiftStyle(ShiftEvent.Workday).classColor}
            />
            <DotLabel
              label="Work Day (Temporary / Different Branch)"
              classBg={
                calendarGetShiftStyle(ShiftEvent.TemporaryWorkday).classColor
              }
            />
            <DotLabel
              label="Rest Day"
              classBg={calendarGetShiftStyle(ShiftEvent.Restday).classColor}
            />
          </section>
        </section>
      </section>
    </section>
  );
  const mainContent = (
    <section className="flex gap-4">
      <div className="w-full">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          customButtons={{
            myCustomButton: {
              text: "Get Refreshment",
              click: function (e) {
                alert("Marvz is super gay!");
              },
            },
          }}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={handleCalendarEvent}
          moreLinkContent={() => "See More"}
          moreLinkClick={handleMoreLinkClick}
          datesSet={handleCalendarDateSet}
          eventContent={handleEventContent}
          eventClick={handleEventClicked}
          dateClick={handleDateClick}
          editable={true}
          eventStartEditable={false}
          eventDrop={handleEventDrop}
          droppable={true}
          eventReceive={handleExternalEventDrop}
        />
      </div>
      <div className="w-72" id="external-events">
        <FilterEventPage />
      </div>
    </section>
  );

  return (
    <div id="ListEvent" className="list-event">
      <HeaderContent title="Event"></HeaderContent>
      {eventDetailSection}

      <section className="p-7">
        {legendSection}

        <BlockUI blocked={isFetching}>{mainContent}</BlockUI>
      </section>
      <Outlet />
    </div>
  );
}

export default ListEvent;
