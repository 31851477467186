import { Outlet } from "react-router-dom";

import { FilterEmployeeIncidentProvider } from "./filter-employee-incident/filter-employee-incident.context";
import "./employee-incident.scss";
import { FormDropdownIncidentTypeProvider } from "@domain/incident-type/form-dropdown-incident-type/form-dropdown-incident-type.context";
import { FormDropdownPaymentMethodProvider } from "@domain/payment-method/form-dropdown-payment-method/form-dropdown-payment-method.context";

export function EmployeeIncident() {
  return (
    <div id="EmployeeIncident" className="employee-incident">
      <FormDropdownPaymentMethodProvider>
        <FormDropdownIncidentTypeProvider>
          <FilterEmployeeIncidentProvider>
            <Outlet />
          </FilterEmployeeIncidentProvider>
        </FormDropdownIncidentTypeProvider>
      </FormDropdownPaymentMethodProvider>
    </div>
  );
}

export default EmployeeIncident;
