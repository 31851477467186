import './form-person-address.scss';
import { useFormContext } from "react-hook-form";
import { PersonAddressFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = PersonAddressFormSchema;
export function PersonAddress() {
  const { control } = useFormContext<PersonAddressFormSchema>();
  return (
    <div id="PersonAddress" className="person-address">
      <InputControl<InputFormSchema>
        control={control}
        name="userCode"
        label="User Code"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter userCode"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="streetName"
        label="Street Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter streetName"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="subdivision"
        label="Subdivision"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter subdivision"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="zone"
        label="Zone"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter zone"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="sitio"
        label="Sitio"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter sitio"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="purok"
        label="Purok"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter purok"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="barangay"
        label="Barangay"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter barangay"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="municipality"
        label="Municipality"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter municipality"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="province"
        label="Province"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter province"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="region"
        label="Region"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter region"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="country"
        label="Country"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter country"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="zip"
        label="Zip"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter zip"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="longitude"
        label="Longitude"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter longitude"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="latitude"
        label="Latitude"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter latitude"
      />
    </div>
  );
}

export default PersonAddress;