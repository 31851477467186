import { FieldValues, Path, useFormContext } from "react-hook-form";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownEmployeeIncidentContext } from "./form-dropdown-employee-incident.context";

export interface FormDropdownEmployeeIncidentProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownEmployeeIncident<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownEmployeeIncidentProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const { mappedEmployeeIncident, setFilter } =
    useFormDropdownEmployeeIncidentContext();

  return (
    <div className="employee-incident">
      <DropdownControl<T>
        control={control}
        name={name}
        label="Employee Incident"
        options={mappedEmployeeIncident}
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your employeeIncident"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
      />
    </div>
  );
}

export default FormDropdownEmployeeIncident;
