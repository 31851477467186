import { Controller, FieldValues } from "react-hook-form";
import { FormControllerProp } from "../form-controller-prop.interface";
import { Checkbox } from "primereact/checkbox";
import classNames from "classnames";
import FormError from "../form-error/form-error";

export interface CheckboxControlProps<FieldsType extends FieldValues>
  extends FormControllerProp<FieldsType> {
  label?: string;
  className?: string;
  containerClassName?: string;
  hint?: string;
  disabled?: boolean;
}

export function CheckboxControl<FieldsType extends FieldValues>({
  rules,
  label,
  control,
  name,
  className,
  containerClassName,
  hint,
  disabled,
}: CheckboxControlProps<FieldsType>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div
          className={classNames(
            "field mt-2 flex gap-2 items-center",
            containerClassName
          )}
        >
          <Checkbox
            inputId={field.name}
            checked={field.value}
            inputRef={field.ref}
            disabled={disabled}
            className={classNames(
              {
                "p-invalid": fieldState.error,
              },
              className
            )}
            onChange={(e) => field.onChange(e.checked)}
          />
          <label className="cursor-pointer" htmlFor={field.name}>
            {label}
          </label>
          {hint && <small className="text-gray-400 mb-1">{hint}</small>}
          {fieldState.error && (
            <FormError error={fieldState.error} className="mt-1" />
          )}
        </div>
      )}
    />
  );
}

export default CheckboxControl;
