import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeePenalty } from "./filter-employee-penalty.hook";

const FilterEmployeePenaltyContext = createContext(
  {} as ReturnType<typeof useFilterEmployeePenalty>
);
export function useFilterEmployeePenaltyContext() {
  return useContext(FilterEmployeePenaltyContext);
}

interface FilterEmployeePenaltyProviderProps {
  children: ReactNode;
}
export function FilterEmployeePenaltyProvider({
  children,
}: FilterEmployeePenaltyProviderProps) {
  return (
    <FilterEmployeePenaltyContext.Provider value={useFilterEmployeePenalty()}>
      {children}
    </FilterEmployeePenaltyContext.Provider>
  );
}