import { ReactNode, createContext, useContext } from "react";
import { useFilterQuestionnaire } from "./filter-questionnaire.hook";

const FilterQuestionnaireContext = createContext(
  {} as ReturnType<typeof useFilterQuestionnaire>
);
export function useFilterQuestionnaireContext() {
  return useContext(FilterQuestionnaireContext);
}

interface FilterQuestionnaireProviderProps {
  children: ReactNode;
}
export function FilterQuestionnaireProvider({
  children,
}: FilterQuestionnaireProviderProps) {
  return (
    <FilterQuestionnaireContext.Provider value={useFilterQuestionnaire()}>
      {children}
    </FilterQuestionnaireContext.Provider>
  );
}