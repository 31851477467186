import "./header-button.scss";
import { ButtonAction } from "./button-action";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";

export interface HeaderButtonProps {
  actions: ButtonAction[];
  onAction: (action: ButtonAction) => void;
}

export function HeaderButton({ actions, onAction }: HeaderButtonProps) {
  const primaryBtns = actions.filter((i) => i.type === "primary");
  const outlinedBtns = actions.filter((i) => i.type === "outlined");
  const secondaryBtns = actions.filter((i) => i.type === "secondary");
  const dangerBtns = actions.filter((i) => i.type === "danger");
  const splitBtns = actions.filter((i) => i.type === "split");
  const moreBtns = actions
    .filter((i) => i.type === "more")
    .map((item) => ({
      label: item.label,
      command: () => {
        onAction(item);
      },
    }));

  const primaryBtnElements =
    primaryBtns.length > 0
      ? primaryBtns.map((i, id) => (
          <Button key={id} label={i.label} onClick={() => onAction(i)} />
        ))
      : null;
  const outlinedBtnElements =
    outlinedBtns.length > 0
      ? outlinedBtns.map((i, id) => (
          <Button
            key={id}
            label={i.label}
            onClick={() => onAction(i)}
            outlined
          />
        ))
      : null;
  const secondaryBtnElements =
    secondaryBtns.length > 0
      ? secondaryBtns.map((i, id) => (
          <Button
            key={id}
            label={i.label}
            onClick={() => onAction(i)}
            outlined
            severity="secondary"
          />
        ))
      : null;
  const dangerBtnElements =
    dangerBtns.length > 0
      ? dangerBtns.map((i, id) => (
          <Button
            key={id}
            label={i.label}
            onClick={() => onAction(i)}
            outlined
            severity="danger"
          />
        ))
      : null;
  const splitBtnElement = splitBtns.length > 0 && (
    <SplitButton
      label={splitBtns[0].label}
      onClick={() => onAction(splitBtns[0])}
      model={moreBtns}
      outlined
    />
  );

  return (
    <div id="HeaderButton" className="header-button flex gap-2 flex-wrap">
      {primaryBtnElements}
      {outlinedBtnElements}
      {secondaryBtnElements}
      {dangerBtnElements}
      {splitBtnElement}
    </div>
  );
}

export default HeaderButton;
