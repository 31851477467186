import { 
    MessageResponseDto, 
    GetLeaveSubcategoryDto, 
    CreateLeaveSubcategoryDto, 
    EditLeaveSubcategoryDto,
    UtilLeaveSubcategoryControllerGet200Response,
    UtilityLeaveSubcategoryApiFp,
    DeleteLeaveSubcategoryDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseLeaveSubcategoryModel = UtilLeaveSubcategoryControllerGet200Response;
export type GetLeaveSubcategoryModel = GetLeaveSubcategoryDto;
export type MutateResponseLeaveSubcategoryModel = MessageResponseDto;
export type CreateLeaveSubcategoryModel = CreateLeaveSubcategoryDto;
export type UpdateLeaveSubcategoryModel = EditLeaveSubcategoryDto;
export type DeleteLeaveSubcategoryModel = DeleteLeaveSubcategoryDto;

export const apiLeaveSubcategoryFp =  UtilityLeaveSubcategoryApiFp(apiConfig);
export const getLeaveSubcategoryController = apiLeaveSubcategoryFp.utilLeaveSubcategoryControllerGet;
export const createLeaveSubcategoryController = apiLeaveSubcategoryFp.utilLeaveSubcategoryControllerCreate;
export const updateLeaveSubcategoryController = apiLeaveSubcategoryFp.utilLeaveSubcategoryControllerEdit;
export const deleteLeaveSubcategoryController = apiLeaveSubcategoryFp.utilLeaveSubcategoryControllerDelete;

export const QueryKeyLeaveSubcategory = QueryKey.LeaveSubcategory;