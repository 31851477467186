import { GetResponsePersonModel } from "@core/model/query-model-person";
import { useQyGetPerson } from "@core/query/person.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useState } from "react";

export function useFormAutocompletePerson() {
  const [searchTerm, setSearchTerm] = useState("");
  const [enabledGet, setEnabledGet] = useState(false);
  const [suggestions, setSuggestions] = useState<LabelValue[]>([]);

  const handleSuggestions = (response: GetResponsePersonModel) => {
    const mappedSuggestions = (response?.data || [])
      .map(
        (item) =>
          ({
            label: `${item.last_name} ${item.first_name}`,
            value: item.code,
          } as LabelValue)
      )
      .filter((x) => !!x.label);

    setSuggestions(mappedSuggestions);
  };
  const { data: personSearchResponse } = useQyGetPerson(
    searchTerm,
    10,
    0,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    enabledGet,
    handleSuggestions
  );

  const onSearch = (term: AutoCompleteCompleteEvent) => {
    setSearchTerm(term.query);
    setEnabledGet(true);
  };

  return {
    suggestions,
    personSearchResponse,
    onSearch,
  };
}
