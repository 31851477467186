import { Outlet } from "react-router-dom";

import { FilterEmploymentTypeProvider } from "./filter-employment-type/filter-employment-type.context";
import './employment-type.scss';

export function EmploymentType() {
  return (
    <div id="EmploymentType" className="employment-type">
       <FilterEmploymentTypeProvider>
        <Outlet />
      </FilterEmploymentTypeProvider>
    </div>
  );
}

export default EmploymentType;
