import "./new-employee-penalty.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeePenalty } from "@core/query/employee-penalty.query";
import {
  EmployeeAttachmentFormSchema,
  EmployeePenaltyFormRule,
  EmployeePenaltyFormSchema,
} from "@core/form/form.rule";
import { employeePenaltyFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeePenalty from "../form-employee-penalty/form-employee-penalty";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { useQyCreateEmployeeAttachment } from "@core/query/employee-attachment.query";
import { AttachmentSource } from "@core/model/query-model-employee-attachment";

export function EmployeePenalty() {
  // LOCAL DECLARATION
  const { selectedPersonData } = useFormDropdownPersonContext();
  const navigate = useNavigate();
  const { mapCreateEmployeePenalty, mapCreateEmployeeAttachment } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();

  // QUERIES
  // API ATTACHMENT
  const { mutate: addEmployeeAttachment } = useQyCreateEmployeeAttachment();

  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    if (typeof fileInput === "object") {
      const mutatedForm = {
        userCode: selectedPersonData?.code,
        bucketCode: selectedPersonData?.bucket.code,
        isActive: true,
        source: AttachmentSource.Penalty,
        file: fileInput,
        requestCode: data.code,
      } as EmployeeAttachmentFormSchema;

      const formData = mapCreateEmployeeAttachment(mutatedForm);
      addEmployeeAttachment(formData);
    }

    showSuccess(`New Employee Penalty is created`);
    navigate(`../`);
  };
  const { mutate: addEmployeePenalty, isLoading: isCreating } =
    useQyCreateEmployeePenalty(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeePenaltyFormSchema>({
    defaultValues: employeePenaltyFormDefault,
    resolver: zodResolver(EmployeePenaltyFormRule),
  });
  const { handleSubmit, watch } = formMethod;
  const fileInput = watch("file");

  const handleValidate = (form: EmployeePenaltyFormSchema) => {
    const updatedForm = {
      ...form,
      bucketCode: selectedPersonData?.bucket.code,
      userCode: selectedPersonData?.code,
    } as EmployeePenaltyFormSchema;
    const formData = mapCreateEmployeePenalty(updatedForm);
    addEmployeePenalty(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeePenaltyFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeePenalty" className="employee-penalty">
      <HeaderContent
        title="New Employee Penalty"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeePenalty />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default EmployeePenalty;
