import { useState } from "react";
import { useQyGetEmployeeIncident } from "@core/query/employee-incident.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { currencyFormat } from "@shared/utility/formats/currency-format";

export interface FormDropdownEmployeeIncidentProps {
  userCode?: string;
}
export const useFormDropdownEmployeeIncident = ({
  userCode,
}: FormDropdownEmployeeIncidentProps) => {
  const { formatDate } = useDateTimeContext();
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");

  const queryFilter = !!userCode ? { user_code: userCode } : undefined;
  const { data: employeeIncidentResponse } = useQyGetEmployeeIncident(
    "",
    999,
    0,
    undefined,
    queryFilter,
    undefined,
    undefined,
    undefined,
    !!userCode
  );
  const mappedEmployeeIncident = (employeeIncidentResponse?.data || []).map(
    (item) =>
      ({
        label: `${formatDate(item.effectivity_date)}: ${
          item.incident_type_name
        }-${currencyFormat(item.amount, "PHP")}`,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    mappedEmployeeIncident,
    employeeIncidentResponse,
    setSidebar,
    setFilter,
  };
};
