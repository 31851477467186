import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownPaymentMethod } from "./form-dropdown-payment-method.hook";

const FormDropdownPaymentMethodContext = createContext(
  {} as ReturnType<typeof useFormDropdownPaymentMethod>
);
export function useFormDropdownPaymentMethodContext() {
  return useContext(FormDropdownPaymentMethodContext);
}

interface FormDropdownPaymentMethodProviderProps {
  children: ReactNode;
}
export function FormDropdownPaymentMethodProvider({
  children,
}: FormDropdownPaymentMethodProviderProps) {
  return (
    <FormDropdownPaymentMethodContext.Provider value={useFormDropdownPaymentMethod()}>
      {children}
    </FormDropdownPaymentMethodContext.Provider>
  );
}
