import "./form-employee-deduction.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeDeductionFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownEmployeeIncident from "@domain/employee-incident/form-dropdown-employee-incident/form-dropdown-employee-incident";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";

type InputFormSchema = EmployeeDeductionFormSchema;
export function EmployeeDeduction() {
  const { control } = useFormContext<EmployeeDeductionFormSchema>();
  return (
    <div id="EmployeeDeduction" className="employee-deduction">
      <FormDropdownEmployeeIncident<InputFormSchema> name="incident" />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="amount"
        label="Amount"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter amount"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="effectivityDate"
        label="Effectivity Date"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="remarks"
        label="Remarks"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter remarks"
      />
    </div>
  );
}

export default EmployeeDeduction;
