import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeBranchModel } from "@core/model/query-model-employee-branch";
import { employeeBranchFormDefault } from "./form.default";
import { EmployeeBranchFormSchema } from "./form.rule";

export const useFormDefaultEmployeeBranch = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeBranchModel | undefined) => {
    return !cachedValue
      ? employeeBranchFormDefault
     : ({
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        branchCode: cachedValue.branch_code,
        effectivityDate: formatDate(cachedValue.effectivity_date) as any,
      } as EmployeeBranchFormSchema);
  };

  return {
    getDefault,
  };
};
