import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownHolidayType } from "./form-dropdown-holiday-type.hook";

const FormDropdownHolidayTypeContext = createContext(
  {} as ReturnType<typeof useFormDropdownHolidayType>
);
export function useFormDropdownHolidayTypeContext() {
  return useContext(FormDropdownHolidayTypeContext);
}

interface FormDropdownHolidayTypeProviderProps {
  children: ReactNode;
}
export function FormDropdownHolidayTypeProvider({
  children,
}: FormDropdownHolidayTypeProviderProps) {
  return (
    <FormDropdownHolidayTypeContext.Provider value={useFormDropdownHolidayType()}>
      {children}
    </FormDropdownHolidayTypeContext.Provider>
  );
}
