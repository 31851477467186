import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { PaymentMethodFormRule, PaymentMethodFormSchema } from "@core/form/form.rule";
import { useFormDefaultPaymentMethod } from "@core/form/form-default-payment-method.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdatePaymentMethod, 
  useQyGetPaymentMethodById, 
  useQyDeletePaymentMethod 
} from "@core/query/payment-method.query";
import { GetResponsePaymentMethodModel } from "@core/model/query-model-payment-method";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditPaymentMethod() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultPaymentMethod();
  const navigate = useNavigate();
  const { mapUpdatePaymentMethod, mapDeletePaymentMethod } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { paymentMethodId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID PaymentMethod
  const handleGetApiSuccess = (data: GetResponsePaymentMethodModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: paymentMethodResponse,
    isLoading,
    isError: paymentMethodError,
  } = useQyGetPaymentMethodById(paymentMethodId || "", handleGetApiSuccess);
  const paymentMethodData = paymentMethodResponse?.data?.[0];

  // API UPDATE PaymentMethod
  const handleUpdateApiSuccess = () => {
    showSuccess("PaymentMethod updated successfully");
  };
  const { mutate: updatePaymentMethod, isLoading: isEditLoading } = useQyUpdatePaymentMethod(
    handleUpdateApiSuccess
  );

  // API DELETE PaymentMethod
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("PaymentMethod deleted successfully");
  };
  const { mutate: deletePaymentMethod } = useQyDeletePaymentMethod(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<PaymentMethodFormSchema>({
    defaultValues: getDefault(paymentMethodData),
    resolver: zodResolver(PaymentMethodFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: PaymentMethodFormSchema) => {
    if (!paymentMethodData) {
      throw new Error("No paymentMethod data");
    }

    const formData = mapUpdatePaymentMethod(form, paymentMethodData.code);
    updatePaymentMethod(formData);
  };
  const handleValidateError = (err: FieldErrors<PaymentMethodFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!paymentMethodData) {
      throw new Error("No paymentMethod data found");
    }

    const form = mapDeletePaymentMethod(paymentMethodData.code);
    deletePaymentMethod(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    paymentMethodId,
    paymentMethodData,
    isLoading,
    paymentMethodError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}