import "./new-device-authorization.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateDeviceAuthorization } from "@core/query/device-authorization.query";
import {
  DeviceAuthorizationFormRule,
  DeviceAuthorizationFormSchema,
} from "@core/form/form.rule";
import { deviceAuthorizationFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import StorageService from "@shared/services/storage.service";
import { DEVICETOKEN } from "@core/utility/settings";
import { useCallback, useState } from "react";
import { useUserIdentityContext } from "@core/services/user-identity.context";
import { Checkbox } from "primereact/checkbox";

export function NewDeviceAuthorization() {
  // LOCAL DECLARATION
  const { getUser, userIsOwner } = useUserIdentityContext();
  const { mapCreateDeviceAuthorization } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();
  const deviceToken = StorageService.load(DEVICETOKEN);
  const [checked, setChecked] = useState(false);

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    setChecked(false);
    if (data?.access_token) {
      StorageService.save<string>(DEVICETOKEN, data.access_token);
      showSuccess(`Device is now registered`);
    } else {
      showSuccess(`Device is now deactivated`);
      StorageService.clear(DEVICETOKEN);
    }
  };
  const { mutate: addDeviceAuthorization, isLoading: isCreating } =
    useQyCreateDeviceAuthorization(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<DeviceAuthorizationFormSchema>({
    defaultValues: deviceAuthorizationFormDefault,
    resolver: zodResolver(DeviceAuthorizationFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: DeviceAuthorizationFormSchema) => {
    if (!checked) {
      showWarning("Please verify your statement by clicking the checkbox.");
      return;
    }

    let authorize = false;
    if (!!deviceToken) {
      StorageService.clear(DEVICETOKEN);
    } else {
      authorize = true;
    }

    const formData = mapCreateDeviceAuthorization({
      authorize,
    });
    addDeviceAuthorization(formData);
  };
  const handleValidateError = (
    err: FieldErrors<DeviceAuthorizationFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  const getLabel = useCallback(() => {
    const name = <b>{getUser().name}</b>;
    const action = !deviceToken ? (
      <span>
        hereby <b>AUTHORIZE</b> this device.
      </span>
    ) : (
      <span>
        hereby <b>DEACTIVATE</b> this device.
      </span>
    );
    const assurance = `I know what I'm
      doing and will take full responsibility and liabilities for this action.
      `;

    return (
      <div>
        I {name}, {action} {assurance}
      </div>
    );
  }, [deviceToken]);

  return (
    <div id="DeviceAuthorization" className="device-authorization">
      <HeaderContent title="Device Authorization">
        {userIsOwner() ? (
          <Button
            className="w-full block"
            label="Save"
            disabled={isCreating}
            onClick={handleSubmit(handleValidate, handleValidateError)}
          ></Button>
        ) : null}
      </HeaderContent>

      <div className="p-7">
        <span className="text-lg">{getLabel()}</span>
        <div className="flex align-items-center mt-4">
          <Checkbox
            inputId="ingredient1"
            name="pizza"
            value="Cheese"
            onChange={(e) => setChecked(e.checked as any)}
            checked={checked}
          />
          <label htmlFor="ingredient1" className="ml-2">
            I Verify the following statement
          </label>
        </div>
      </div>
    </div>
  );
}

export default NewDeviceAuthorization;
