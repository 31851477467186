import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownFavorite } from "./form-dropdown-favorite.hook";

const FormDropdownFavoriteContext = createContext(
  {} as ReturnType<typeof useFormDropdownFavorite>
);
export function useFormDropdownFavoriteContext() {
  return useContext(FormDropdownFavoriteContext);
}

interface FormDropdownFavoriteProviderProps {
  children: ReactNode;
}
export function FormDropdownFavoriteProvider({
  children,
}: FormDropdownFavoriteProviderProps) {
  return (
    <FormDropdownFavoriteContext.Provider value={useFormDropdownFavorite()}>
      {children}
    </FormDropdownFavoriteContext.Provider>
  );
}
