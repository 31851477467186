import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeDiciplinary } from "./filter-employee-diciplinary.hook";

const FilterEmployeeDiciplinaryContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeDiciplinary>
);
export function useFilterEmployeeDiciplinaryContext() {
  return useContext(FilterEmployeeDiciplinaryContext);
}

interface FilterEmployeeDiciplinaryProviderProps {
  children: ReactNode;
}
export function FilterEmployeeDiciplinaryProvider({
  children,
}: FilterEmployeeDiciplinaryProviderProps) {
  return (
    <FilterEmployeeDiciplinaryContext.Provider value={useFilterEmployeeDiciplinary()}>
      {children}
    </FilterEmployeeDiciplinaryContext.Provider>
  );
}