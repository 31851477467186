import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import axios, { AxiosError } from "axios";
import { useSessionContext } from "./session.context";
import {
  CreateEmployeeRequestModel,
  DeleteEmployeeRequestModel,
  GetResponseEmployeeRequestModel,
  MutateResponseEmployeeRequestModel,
  QueryKeyEmployeeRequest,
  UpdateEmployeeRequestModel,
  deleteEmployeeRequestController,
  createEmployeeRequestController,
  getEmployeeRequestController,
  updateEmployeeRequestController,
  ProcessEmployeeRequestModel,
  processEmployeeRequestController,
  OvertimeEmployeeRequestModel,
  LeaveEmployeeRequestModel,
  AmendmentEmployeeRequestModel,
  ChangeShiftEmployeeRequestModel,
  BreakAmendmentEmployeeRequestModel,
} from "@core/model/query-model-employee-request";
import { SETTINGS } from "@core/utility/settings";
import { apiConfig } from "./config.query";

export function useQyGetEmployeeRequest(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  dateFilter?: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    dateFilter: string | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    await checkSession();

    showProgress();
    const operation = await getEmployeeRequestController(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      dateFilter,
      startDate,
      endDate,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyEmployeeRequest]);
    return response["data"] as GetResponseEmployeeRequestModel;
  };

  return useQuery({
    enabled,
    queryKey: [
      QueryKeyEmployeeRequest,
      search,
      limit,
      offset,
      order,
      filter,
      dateFilter,
      startDate,
      endDate,
    ],
    queryFn: () =>
      apiFn(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate
      ),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
    staleTime: SETTINGS.staleTime,
  });
}

export function useQyGetEmployeeRequestById(
  id: string,
  onSuccess?:
    | ((data: GetResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (id: string, search = "", limit = 1, offset = 0) => {
    await checkSession();

    showProgress();
    const operation = await getEmployeeRequestController(
      search,
      limit,
      offset,
      undefined,
      { filter: JSON.stringify({ code: id }) },
      undefined,
      undefined,
      undefined,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyEmployeeRequest, id]);
    return response["data"] as GetResponseEmployeeRequestModel;
  };

  return useQuery({
    queryKey: [QueryKeyEmployeeRequest, id],
    queryFn: () => apiFn(id),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyCreateEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreateEmployeeRequestModel) => {
    await checkSession();

    showProgress();
    const operation = await createEmployeeRequestController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseEmployeeRequestModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdateEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdateEmployeeRequestModel) => {
    await checkSession();

    showProgress();
    const operation = await updateEmployeeRequestController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseEmployeeRequestModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyDeleteEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DeleteEmployeeRequestModel) => {
    await checkSession();

    showProgress();
    const operation = await deleteEmployeeRequestController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseEmployeeRequestModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyProcessEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ProcessEmployeeRequestModel) => {
    await checkSession();

    showProgress();
    const operation = await processEmployeeRequestController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseEmployeeRequestModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyOvertimeEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: OvertimeEmployeeRequestModel) => {
    await checkSession();

    showProgress();
    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("overtime", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }
    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/employee-request/overtime`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyLeaveEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: LeaveEmployeeRequestModel) => {
    await checkSession();

    showProgress();
    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("leave", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }
    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/employee-request/leave`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyPublicLeaveEmployeeRequest(
  onSuccess?:
    | ((data: MutateResponseEmployeeRequestModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: LeaveEmployeeRequestModel) => {
    showProgress();
    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("leave", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }
    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/employee-request/public/leave`,
      formdata
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyAmendmentEmployeeRequest(
  onSuccess?: ((data: any) => void | Promise<unknown>) | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: AmendmentEmployeeRequestModel) => {
    await checkSession();

    showProgress();

    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("amendment", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }
    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/employee-request/amendment`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyBreakAmendmentEmployeeRequest(
  onSuccess?: ((data: any) => void | Promise<unknown>) | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: BreakAmendmentEmployeeRequestModel) => {
    await checkSession();

    showProgress();

    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("break", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }
    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/employee-request/break/amendment`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyChangeShiftEmployeeRequest(
  onSuccess?: ((data: any) => void | Promise<unknown>) | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: ChangeShiftEmployeeRequestModel) => {
    await checkSession();

    showProgress();

    const formdata = new FormData();
    const { file, ...remainingPayload } = payload;
    formdata.append("csr", JSON.stringify(remainingPayload));
    if (payload.file) {
      const file = payload.file as File;
      formdata.append("file", file, file.name);
    }
    const operation = await axios.post(
      `${apiConfig.basePath}/api/v1/employee-request/change-shift`,
      formdata,
      authHeaders()
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyEmployeeRequest);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
