import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeIncident } from "./filter-employee-incident.hook";

const FilterEmployeeIncidentContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeIncident>
);
export function useFilterEmployeeIncidentContext() {
  return useContext(FilterEmployeeIncidentContext);
}

interface FilterEmployeeIncidentProviderProps {
  children: ReactNode;
}
export function FilterEmployeeIncidentProvider({
  children,
}: FilterEmployeeIncidentProviderProps) {
  return (
    <FilterEmployeeIncidentContext.Provider value={useFilterEmployeeIncident()}>
      {children}
    </FilterEmployeeIncidentContext.Provider>
  );
}