import { ReactNode, createContext, useContext } from "react";
import { useFilterViolation } from "./filter-violation.hook";

const FilterViolationContext = createContext(
  {} as ReturnType<typeof useFilterViolation>
);
export function useFilterViolationContext() {
  return useContext(FilterViolationContext);
}

interface FilterViolationProviderProps {
  children: ReactNode;
}
export function FilterViolationProvider({
  children,
}: FilterViolationProviderProps) {
  return (
    <FilterViolationContext.Provider value={useFilterViolation()}>
      {children}
    </FilterViolationContext.Provider>
  );
}