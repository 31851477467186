import { AUTH, SETTINGS } from "@core/utility/settings";
import StorageService from "@shared/services/storage.service";
import { differenceInSeconds, isAfter, toDate } from "date-fns";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { LocalAuth } from "@core/model/local-auth";

interface ProtectedRouteProps {
  children: ReactNode;
}
export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const user = StorageService.load<LocalAuth>(AUTH);

  if (!user) {
    return <Navigate to="/login" />;
  }

  const serverExpiry = toDate(user.accessExpiry * 1000);
  const currentDate = new Date();
  const diffInSec = differenceInSeconds(serverExpiry, currentDate);
  console.log({serverExpiry, currentDate, diffInSec})
  
  if (diffInSec < SETTINGS.maxTimeForTimeout) {
    return <Navigate to="/login" />;
  }

  return <div>{children}</div>;
}
