import { Outlet } from "react-router-dom";

import { FilterShiftProvider } from "./filter-shift/filter-shift.context";
import "./shift.scss";
import { FormDropdownShiftTypeProvider } from "@domain/shift-type/form-dropdown-shift-type/form-dropdown-shift-type.context";

export function Shift() {
  return (
    <div id="Shift" className="shift">
      <FormDropdownShiftTypeProvider>
        <FilterShiftProvider>
          <Outlet />
        </FilterShiftProvider>
      </FormDropdownShiftTypeProvider>
    </div>
  );
}

export default Shift;
