import * as z from "zod";

const ACCEPTED_IMAGE_TYPES = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  undefined,
  null,
];
export const LoginRule = z.object({
  username: z.string().min(1, "Email/Username is required"),
  password: z.string().min(1, { message: "Password is required" }),
  captcha: z.string().optional(),
});
export const BranchFormRule = z.object({
  code: z.string().optional(),
  name: z.string().min(1, "Branch name is required"),
  description: z.string().optional(),
  head: z.string().optional(),
});
export const EmployeeFormRule = z.object({
  code: z.string().optional(),
  firstName: z.string().min(1, "First name is required"),
  middleName: z.string().optional(),
  lastName: z.string().min(1, "Last name is required"),
  username: z.string().optional(),
  email: z.string().optional(),
  password: z.string().optional(),
  avatar: z.string().optional(),
  gender: z.string().optional(),
  birthday: z.coerce.date().optional().nullable(),
});
export const HolidayFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  date: z.coerce.date().optional().nullable(),
  type: z.string().optional(),
});
export const HolidayTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  percentage: z.number().optional(),
});
export const JobTitleFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const JobGradeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const ShiftTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const StatusFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const ShiftFormRule = z.object({
  type: z.string().optional(),
  start: z.string().optional(),
  end: z.string().optional(),
  halfDay: z.number().optional(),
  gracePeriod: z.number().optional(),
  workHours: z.number().optional(),
  break: z.number().optional(),
  breakStart: z.string().optional(),
  breakEnd: z.string().optional(),
  title: z.string().optional(),
  isOpen: z.boolean().optional(),
});
export const EmploymentTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const DepartmentFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  head: z.string().optional(),
});
export const RoleFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeBucketFormRule = z.object({
  code: z.string().optional(),
  userCode: z.string().optional(),
  employeeNo: z.string().optional(),
  signedDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  startDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  endDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  jobTitle: z.string().optional(),
  jobGrade: z.string().min(1, "Job grade must be filled"),
  employmentType: z.string().min(1, "Employment type must be filled"),
  department: z.string().optional(),
  branch: z.string().optional(),
  reportingTo: z.string().optional(),
  offboardReason: z.string().optional(),
  resignationDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  role: z.string().optional(),
  sss: z.number().optional(),
  philhealth: z.number().optional(),
  pagibig: z.number().optional(),
});
export const PersonAddressFormRule = z.object({
  code: z.string().optional(),
  userCode: z.string().optional(),
  streetName: z.string().optional(),
  subdivision: z.string().optional(),
  zone: z.string().optional(),
  sitio: z.string().optional(),
  purok: z.string().optional(),
  barangay: z.string().optional(),
  municipality: z.string().optional(),
  province: z.string().optional(),
  region: z.string().optional(),
  country: z.string().optional(),
  zip: z.string().optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
});
export const PersonFormRule = z.object({
  avatar: z.string().optional(),
  picture: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
  attachmentType: z.string().optional(),
  attachment: z.any().optional(),
  firstName: z.string().min(1, "First name must be filled"),
  middleName: z.string().optional(),
  lastName: z.string().min(1, "Last name must be filled"),
  username: z.string().min(1, "Username must be filled"),
  email: z.string().optional(),
  password: z.string().optional(),
  gender: z.string().optional(),
  birthday: z.coerce.date().optional().nullish().or(z.string().max(0)),
  bucket: EmployeeBucketFormRule.optional(),
  address: PersonAddressFormRule.optional(),
});
export const EmployeeShiftFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  shiftCode: z.string().optional(),
  shiftDescription: z.string().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  endDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  day: z.string().optional(),
  isPermanent: z.boolean(),
});
export const QuestionnaireFormRule = z.object({
  jobTitle: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeScoreFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  branchCode: z.string().optional(),
  questionCode: z.string().optional(),
  questionLabel: z.string().optional(),
  score: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
});
export const EmployeeScoreArrayFormRule = z.object({
  scores: EmployeeScoreFormRule.array(),
});
export const DailyTimeRecordFormRule = z.object({
  clockIn: z.coerce.date().optional().nullish().or(z.string().max(0)),
  clockOut: z.coerce.date().optional().nullish().or(z.string().max(0)),
});
export const LeaveSubcategoryFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const LeaveCategoryFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const LeaveTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const RequestTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeRequestFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  requestType: z.string().optional(),
  requestReason: z.string().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  effectivityDateEnd: z.coerce
    .date()
    .optional()
    .nullish()
    .or(z.string().max(0)),
  leaveType: z.string().optional(),
  leaveCategory: z.string().optional(),
  leaveSubcategory: z.string().optional(),
  amendCurrentClockin: z.string().optional(),
  amendCurrentClockout: z.string().optional(),
  amendClockin: z.string().optional(),
  amendClockout: z.string().optional(),
  shiftCurrentSchedule: z.string().optional(),
  shiftSchedule: z.string().optional(),
  isPermanent: z.boolean().optional(),
  shiftDay: z.string().optional(),
  endDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  otTime: z.number().optional(),
  status: z.string().optional(),
  dtrCode: z.string().optional(),
  workDate: z.string().optional(),
  file: z
    .any()
    .optional()
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, and .png formats are supported."
    ),
  breakCode: z.string().optional(),
  isBreakPermission: z.boolean().optional(),
  amendBreakStart: z.string().optional(),
  amendBreakEnd: z.string().optional(),
});
export const PaymentMethodFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeIncidentFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  branchCode: z.string().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  incidentType: z.string().optional(),
  paymentMethod: z.string().optional(),
  amount: z.number().optional(),
  balance: z.number().optional(),
  isPaid: z.boolean().optional(),
  file: z.any().optional(),
  intervals: z.number().optional(),
  paymentStart: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
});
export const IncidentTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const SalaryTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeSalaryFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  salaryType: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
});
export const EmployeeDeductionFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  incident: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
});
export const EmployeePenaltyFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
  file: z.any().optional(),
});
export const EmployeeTimelineFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  source: z.string().optional(),
  action: z.string().optional(),
  message: z.string().optional(),
});
export const PayrollFormRule = z.object({
  userCode: z.string().optional(),
});
export const EmployeeLedgerFormRule = z.object({
  userId: z.string().optional(),
  bucketId: z.string().optional(),
  ledgerValue: z.number().optional(),
  ledgerAction: z.string().optional(),
  ledgerStatus: z.string().optional(),
  transactionCode: z.string().optional(),
  ledgerRemarks: z.string().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
});
export const EmployeeAttachmentFormRule = z.object({
  isActive: z.boolean().optional(),
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  source: z.string().optional(),
  attachmentType: z.string().optional(),
  requestCode: z.string().optional(),
  file: z.any().optional(),
  mimeType: z.string().optional(),
  code: z.string().optional(),
});
export const EmployeeIncentiveFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
  file: z.any().optional(),
});
export const EmployeeDiciplinaryFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  branchCode: z.string().optional(),
  incidentDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  issuedDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  violationCode: z.string().optional(),
  infractionCode: z.string().optional(),
  details: z.string().optional(),
  penaltyScheduleCode: z.string().optional(),
  actionPlan: z.string().optional(),
  followupDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  issuer: z.string().optional(),
  issuerAutocompleteLabel: z.string().optional(),
  supervisor: z.string().optional(),
  supervisorAutocompleteLabel: z.string().optional(),
});
export const ViolationFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const InfractionFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const PenaltyScheduleFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const AttachmentTypeFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeLoanFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  subject: z.string().optional(),
  purpose: z.string().optional(),
  file: z.any().optional(),
  intervals: z.number().optional(),
  paymentStart: z.coerce.date().optional().nullish().or(z.string().max(0)),
});
export const EmployeePaymentFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  loan: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
});
export const EmployeeBranchFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  branchCode: z.string().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
});
export const EmployeeOverageFormRule = z.object({
  userCode: z.string().optional(),
  bucketCode: z.string().optional(),
  amount: z.number().optional(),
  effectivityDate: z.coerce.date().optional().nullish().or(z.string().max(0)),
  remarks: z.string().optional(),
  file: z.any().optional(),
});
export const FaqFormRule = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  link: z.string().optional(),
});
export const DeviceAuthorizationFormRule = z.object({
  authorize: z.boolean().optional(),
});
export const FavoriteFormRule = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
});
export const EmployeeFavoriteFormRule = z.object({
  userCode: z.string().optional(),
  favoriteCode: z.string().optional(),
});
export const EmployeeFavoriteArrayFormRule = z.object({
  favoriteId: z.string().optional(),
  groups: EmployeeFavoriteFormRule.array(),
});
// RULE ADD

export type EmployeeFormSchema = z.infer<typeof EmployeeFormRule>;
export type LoginFormSchema = z.infer<typeof LoginRule>;
export type BranchFormSchema = z.infer<typeof BranchFormRule>;
export type HolidayFormSchema = z.infer<typeof HolidayFormRule>;
export type HolidayTypeFormSchema = z.infer<typeof HolidayTypeFormRule>;
export type JobTitleFormSchema = z.infer<typeof JobTitleFormRule>;
export type JobGradeFormSchema = z.infer<typeof JobGradeFormRule>;
export type ShiftTypeFormSchema = z.infer<typeof ShiftTypeFormRule>;
export type StatusFormSchema = z.infer<typeof StatusFormRule>;
export type ShiftFormSchema = z.infer<typeof ShiftFormRule>;
export type EmploymentTypeFormSchema = z.infer<typeof EmploymentTypeFormRule>;
export type DepartmentFormSchema = z.infer<typeof DepartmentFormRule>;
export type RoleFormSchema = z.infer<typeof RoleFormRule>;
export type EmployeeBucketFormSchema = z.infer<typeof EmployeeBucketFormRule>;
export type PersonFormSchema = z.infer<typeof PersonFormRule>;
export type PersonAddressFormSchema = z.infer<typeof PersonAddressFormRule>;
export type EmployeeShiftFormSchema = z.infer<typeof EmployeeShiftFormRule>;
export type QuestionnaireFormSchema = z.infer<typeof QuestionnaireFormRule>;
export type EmployeeScoreFormSchema = z.infer<typeof EmployeeScoreFormRule>;
export type EmployeeScoreArrayFormSchema = z.infer<
  typeof EmployeeScoreArrayFormRule
>;
export type DailyTimeRecordFormSchema = z.infer<typeof DailyTimeRecordFormRule>;
export type LeaveSubcategoryFormSchema = z.infer<
  typeof LeaveSubcategoryFormRule
>;
export type LeaveCategoryFormSchema = z.infer<typeof LeaveCategoryFormRule>;
export type LeaveTypeFormSchema = z.infer<typeof LeaveTypeFormRule>;
export type RequestTypeFormSchema = z.infer<typeof RequestTypeFormRule>;
export type EmployeeRequestFormSchema = z.infer<typeof EmployeeRequestFormRule>;
export type PaymentMethodFormSchema = z.infer<typeof PaymentMethodFormRule>;
export type EmployeeIncidentFormSchema = z.infer<
  typeof EmployeeIncidentFormRule
>;
export type IncidentTypeFormSchema = z.infer<typeof IncidentTypeFormRule>;
export type SalaryTypeFormSchema = z.infer<typeof SalaryTypeFormRule>;
export type EmployeeSalaryFormSchema = z.infer<typeof EmployeeSalaryFormRule>;
export type EmployeeDeductionFormSchema = z.infer<
  typeof EmployeeDeductionFormRule
>;
export type EmployeePenaltyFormSchema = z.infer<typeof EmployeePenaltyFormRule>;
export type EmployeeTimelineFormSchema = z.infer<
  typeof EmployeeTimelineFormRule
>;
export type PayrollFormSchema = z.infer<typeof PayrollFormRule>;
export type EmployeeLedgerFormSchema = z.infer<typeof EmployeeLedgerFormRule>;
export type EmployeeAttachmentFormSchema = z.infer<
  typeof EmployeeAttachmentFormRule
>;
export type EmployeeIncentiveFormSchema = z.infer<
  typeof EmployeeIncentiveFormRule
>;
export type EmployeeDiciplinaryFormSchema = z.infer<
  typeof EmployeeDiciplinaryFormRule
>;
export type ViolationFormSchema = z.infer<typeof ViolationFormRule>;
export type InfractionFormSchema = z.infer<typeof InfractionFormRule>;
export type PenaltyScheduleFormSchema = z.infer<typeof PenaltyScheduleFormRule>;
export type AttachmentTypeFormSchema = z.infer<typeof AttachmentTypeFormRule>;
export type EmployeeLoanFormSchema = z.infer<typeof EmployeeLoanFormRule>;
export type EmployeePaymentFormSchema = z.infer<typeof EmployeePaymentFormRule>;
export type EmployeeBranchFormSchema = z.infer<typeof EmployeeBranchFormRule>;
export type EmployeeOverageFormSchema = z.infer<typeof EmployeeOverageFormRule>;
export type FaqFormSchema = z.infer<typeof FaqFormRule>;
export type DeviceAuthorizationFormSchema = z.infer<
  typeof DeviceAuthorizationFormRule
>;
export type FavoriteFormSchema = z.infer<typeof FavoriteFormRule>;
export type EmployeeFavoriteFormSchema = z.infer<
  typeof EmployeeFavoriteFormRule
>;
export type EmployeeFavoriteArrayFormSchema = z.infer<
  typeof EmployeeFavoriteArrayFormRule
>;
// SCHEMA ADD
