import { Outlet } from "react-router-dom";
import "./branch.scss";
import { FormDropdownPersonProvider } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import { FilterBranchProvider } from "./filter-branch/filter-branch.context";

export function Branch() {
  return (
    <div id="Branch" className="branch">
      <FilterBranchProvider>
        <FormDropdownPersonProvider>
          <Outlet />
        </FormDropdownPersonProvider>
      </FilterBranchProvider>
    </div>
  );
}

export default Branch;
