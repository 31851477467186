import "./home.scss";
import { InputText } from "primereact/inputtext";
import { useCallback, useState } from "react";
import HeaderContent from "@shared/ui/header-content/header-content";
import { Dropdown } from "primereact/dropdown";
import { useUserIdentity } from "@core/services/user-identity.hook";
import { useFormDropdownBranchContext } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.context";
import { useQyGetReportBranchDailyV2 } from "@core/query/report.query";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import StatCard from "@shared/ui/stat-card/stat-card";
import { GrScorecard } from "react-icons/gr";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CalendarDate } from "@core/model/calendar-event.interface";
import { Sidebar } from "primereact/sidebar";
import { LabelValue } from "@shared/models/label-value.interface";
import { DashboardMetadata } from "@core/model/dashboard-metadata.interface";
import { useNavigate } from "react-router-dom";

/* eslint-disable-next-line */
export interface HomeProps {}

const getDefaultBranchId = (isAdmin: boolean, branchId: string) => {
  return isAdmin ? "" : branchId;
};

export function Home() {
  const [visibleList, setVisibleList] = useState(false);
  const [selectedList, setSelectedList] =
    useState<LabelValue<DashboardMetadata[]>>();
  const { formatDate } = useDateTimeContext();
  const { getUser, userIsAdmin } = useUserIdentity();
  const [calendarDate, setCalendarDate] = useState<CalendarDate>({
    start: formatDate(new Date()),
    end: formatDate(new Date()),
  });
  const [selectedBranch, setSelectedBranch] = useState(
    getDefaultBranchId(userIsAdmin(), getUser()?.branchId)
  );
  const { mappedBranch } = useFormDropdownBranchContext();
  const navigate = useNavigate();

  // QUERIES
  const { data: report, isLoading } = useQyGetReportBranchDailyV2(
    calendarDate.start,
    calendarDate.end,
    selectedBranch,
    !!calendarDate.start && !!calendarDate.end && !!selectedBranch
  );

  // LOCAL FUNCTIONS
  const handleClick = (label: string, value: string | number) => {
    let valueArray = [] as DashboardMetadata[];
    switch (label) {
      case "Employees On Duty":
        valueArray = (report?.onduty_metadata || []) as DashboardMetadata[];
        break;
      case "Employees On Leave":
        valueArray = (report?.onleave_metadata || []) as DashboardMetadata[];
        break;
      case "Employees Absent":
        valueArray = (report?.absent_metadata || []) as DashboardMetadata[];
        break;
      case "Employees On Incident":
        valueArray = (report?.incident_metadata || []) as DashboardMetadata[];
        break;
      case "Employees Suspended":
        valueArray = (report?.suspend_metadata || []) as DashboardMetadata[];
        break;
      case "Pending Request":
        valueArray = (report?.pending_metadata || []) as DashboardMetadata[];
        break;
      case "Approved Request":
        valueArray = (report?.approved_metadata || []) as DashboardMetadata[];
        break;
      case "Rejected Request":
        valueArray = (report?.rejected_metadata || []) as DashboardMetadata[];
        break;
    }

    setSelectedList({ label, value: valueArray });
    setVisibleList(true);
  };

  // HTML RENDER
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const branchElement = (
    <div className="flex flex-col">
      <label className="text-xs text-gray-500" htmlFor="start">
        Branch
      </label>
      <Dropdown
        value={selectedBranch}
        onChange={(e) => setSelectedBranch(e.value)}
        options={mappedBranch}
        optionLabel="label"
        placeholder="Select a branch"
        className="w-full md:w-14rem"
        filter
      />
    </div>
  );
  const dateElement = (
    <div className="flex gap-4 items-center">
      <div className="flex flex-col">
        <label className="text-xs text-gray-500" htmlFor="start">
          Start
        </label>
        <InputText
          type="date"
          id="start"
          value={calendarDate.start}
          onChange={(e) => {
            setCalendarDate({
              ...calendarDate,
              start: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-col">
        <label className="text-xs text-gray-500" htmlFor="end">
          End
        </label>
        <InputText
          type="date"
          id="end"
          value={calendarDate.end}
          onChange={(e) => {
            setCalendarDate({
              ...calendarDate,
              end: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
  const filterElement = (
    <div className="flex gap-4 items-center mb-2">
      {userIsAdmin() && <div>{branchElement}</div>}
      {dateElement}
    </div>
  );
  const reportElement = (
    <section className="w-full">
      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
        <StatCard
          label="Employees On Duty"
          value={report?.onduty || 0}
          icon={
            <div className="bg-green-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Employees On Leave"
          value={report?.onleave || 0}
          icon={
            <div className="bg-amber-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Employees Absent"
          value={report?.absent || 0}
          icon={
            <div className="bg-rose-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Employees On Incident"
          value={report?.incident || 0}
          icon={
            <div className="bg-sky-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Employees Suspended"
          value={report?.suspend || 0}
          icon={
            <div className="bg-violet-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Employees Average Score"
          value={report?.score || 0}
          icon={
            <div className="bg-lime-200 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Pending Request"
          value={report?.pending_request || 0}
          icon={
            <div className="bg-blue-400 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Approved Request"
          value={report?.approved_request || 0}
          icon={
            <div className="bg-lime-400 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
        <StatCard
          label="Rejected Request"
          value={report?.rejected_request || 0}
          icon={
            <div className="bg-red-400 p-4 rounded-lg">
              <GrScorecard />
            </div>
          }
          onClick={handleClick}
        />
      </section>
      <section className="grid grid-cols-1 gap-6 mt-8">
        <h3>Birthday Celebrants</h3>
        {report?.birthdays ? (
          <DataTable
            value={report?.birthdays}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              body={(cell) =>
                `${cell.person_first_name} ${cell.person_last_name}`
              }
              header="Name"
            ></Column>
            <Column field="birthday" header="Birthday"></Column>
          </DataTable>
        ) : (
          <small className="text-center">No Birthday Celebrants</small>
        )}
      </section>
    </section>
  );
  const handlePersonView = (branch: string, userCode: string) => {
    navigate(`employee/${branch}/${userCode}`);
  };
  const getBranchName = useCallback(() => {
    if (userIsAdmin()) {
      return "Dashboard";
    }

    if (mappedBranch.length === 0) {
      return "Dashboard";
    }

    const selectedBranchLabelValue = mappedBranch.find(
      (item) => item.value === selectedBranch
    );
    const branchLabel = selectedBranchLabelValue?.label || "";
    const label = branchLabel ? `${branchLabel} Branch Dashboard` : "Dashboard";
    return label;
  }, [mappedBranch]);
  const listData = (
    <Sidebar visible={visibleList} onHide={() => setVisibleList(false)}>
      <h2 className="mb-4">{selectedList?.label}</h2>
      <ul>
        {selectedList?.value.map((item, i) => (
          <li
            className="p-2 bg-gray-200 rounded mb-2 cursor-pointer"
            onClick={() => handlePersonView(item.branch_code, item.user_code)}
          >
            {i+1}: {item.person_first_name} {item.person_last_name}
          </li>
        ))}
      </ul>
    </Sidebar>
  );

  return (
    <div id="Home" className="home">
      <HeaderContent title={getBranchName()}></HeaderContent>
      <div className="p-7">
        {filterElement}
        {listData}

        {isLoading && displayLoading}
        {!isLoading && reportElement}
      </div>
    </div>
  );
}

export default Home;
