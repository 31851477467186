import { Outlet } from "react-router-dom";

import { FilterEmployeeShiftProvider } from "./filter-employee-shift/filter-employee-shift.context";
import "./employee-shift.scss";
import { FormDropdownShiftProvider } from "@domain/shift/form-dropdown-shift/form-dropdown-shift.context";

export function EmployeeShift() {
  return (
    <div id="EmployeeShift" className="employee-shift">
      <FormDropdownShiftProvider>
        <FilterEmployeeShiftProvider>
          <Outlet />
        </FilterEmployeeShiftProvider>
      </FormDropdownShiftProvider>
    </div>
  );
}

export default EmployeeShift;
