import { LocalAuth } from "@core/model/local-auth";
import { AUTH, DEVICETOKEN } from "@core/utility/settings";
import StorageService from "@shared/services/storage.service";

export function authHeaders() {
  const authStorage = StorageService.load<LocalAuth>(AUTH);
  if (!authStorage) {
    throw new Error("no tokens available");
  }

  return {
    headers: {
      Authorization: `Bearer ${authStorage.accessToken}`,
    },
  };
}

export function deviceTokenAuthHeaders() {
  const deviceToken = StorageService.load<string>(DEVICETOKEN);
  if (!deviceToken) {
    throw new Error("no deviceToken available");
  }

  return {
    headers: {
      Authorization: `Bearer ${deviceToken}`,
    },
  };
}

export function refreshHeaders() {
  const authStorage = StorageService.load<LocalAuth>(AUTH);
  if (!authStorage) {
    throw new Error("no tokens available");
  }

  return {
    headers: {
      Authorization: `Bearer ${authStorage.refreshToken}`,
    },
  };
}
