import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { EmployeeIncentiveFormRule, EmployeeIncentiveFormSchema } from "@core/form/form.rule";
import { useFormDefaultEmployeeIncentive } from "@core/form/form-default-employee-incentive.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateEmployeeIncentive, 
  useQyGetEmployeeIncentiveById, 
  useQyDeleteEmployeeIncentive 
} from "@core/query/employee-incentive.query";
import { GetResponseEmployeeIncentiveModel } from "@core/model/query-model-employee-incentive";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeeIncentive() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeIncentive();
  const navigate = useNavigate();
  const { mapUpdateEmployeeIncentive, mapDeleteEmployeeIncentive } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeIncentiveId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeIncentive
  const handleGetApiSuccess = (data: GetResponseEmployeeIncentiveModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("amount", responseData?.amount);
      setValue("effectivityDate", formatDate(responseData?.effectivity_date) as any, undefined);
      setValue("remarks", responseData?.remarks || "");
    }
  };
  const {
    data: employeeIncentiveResponse,
    isLoading,
    isError: employeeIncentiveError,
  } = useQyGetEmployeeIncentiveById(employeeIncentiveId || "", handleGetApiSuccess);
  const employeeIncentiveData = employeeIncentiveResponse?.data?.[0];

  // API UPDATE EmployeeIncentive
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeIncentive updated successfully");
  };
  const { mutate: updateEmployeeIncentive, isLoading: isEditLoading } = useQyUpdateEmployeeIncentive(
    handleUpdateApiSuccess
  );

  // API DELETE EmployeeIncentive
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeIncentive deleted successfully");
  };
  const { mutate: deleteEmployeeIncentive } = useQyDeleteEmployeeIncentive(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeIncentiveFormSchema>({
    defaultValues: getDefault(employeeIncentiveData),
    resolver: zodResolver(EmployeeIncentiveFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeIncentiveFormSchema) => {
    if (!employeeIncentiveData) {
      throw new Error("No employeeIncentive data");
    }

    const formData = mapUpdateEmployeeIncentive(form, employeeIncentiveData.code);
    updateEmployeeIncentive(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeIncentiveFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeIncentiveData) {
      throw new Error("No employeeIncentive data found");
    }

    const form = mapDeleteEmployeeIncentive(employeeIncentiveData.code);
    deleteEmployeeIncentive(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    employeeIncentiveId,
    employeeIncentiveData,
    isLoading,
    employeeIncentiveError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}