import { 
    MessageResponseDto, 
    GetFavoriteDto, 
    CreateFavoriteDto, 
    EditFavoriteDto,
    UtilFavoriteControllerGet200Response,
    UtilityFavoriteApiFp,
    DeleteFavoriteDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseFavoriteModel = UtilFavoriteControllerGet200Response;
export type GetFavoriteModel = GetFavoriteDto;
export type MutateResponseFavoriteModel = MessageResponseDto;
export type CreateFavoriteModel = CreateFavoriteDto;
export type UpdateFavoriteModel = EditFavoriteDto;
export type DeleteFavoriteModel = DeleteFavoriteDto;

export const apiFavoriteFp =  UtilityFavoriteApiFp(apiConfig);
export const getFavoriteController = apiFavoriteFp.utilFavoriteControllerGet;
export const createFavoriteController = apiFavoriteFp.utilFavoriteControllerCreate;
export const updateFavoriteController = apiFavoriteFp.utilFavoriteControllerEdit;
export const deleteFavoriteController = apiFavoriteFp.utilFavoriteControllerDelete;

export const QueryKeyFavorite = QueryKey.Favorite;