import { ReactNode, createContext, useContext } from "react";
import { useFilterPenaltySchedule } from "./filter-penalty-schedule.hook";

const FilterPenaltyScheduleContext = createContext(
  {} as ReturnType<typeof useFilterPenaltySchedule>
);
export function useFilterPenaltyScheduleContext() {
  return useContext(FilterPenaltyScheduleContext);
}

interface FilterPenaltyScheduleProviderProps {
  children: ReactNode;
}
export function FilterPenaltyScheduleProvider({
  children,
}: FilterPenaltyScheduleProviderProps) {
  return (
    <FilterPenaltyScheduleContext.Provider value={useFilterPenaltySchedule()}>
      {children}
    </FilterPenaltyScheduleContext.Provider>
  );
}