import { ReactNode, createContext, useContext } from "react";
import { useFilterPaymentMethod } from "./filter-payment-method.hook";

const FilterPaymentMethodContext = createContext(
  {} as ReturnType<typeof useFilterPaymentMethod>
);
export function useFilterPaymentMethodContext() {
  return useContext(FilterPaymentMethodContext);
}

interface FilterPaymentMethodProviderProps {
  children: ReactNode;
}
export function FilterPaymentMethodProvider({
  children,
}: FilterPaymentMethodProviderProps) {
  return (
    <FilterPaymentMethodContext.Provider value={useFilterPaymentMethod()}>
      {children}
    </FilterPaymentMethodContext.Provider>
  );
}