import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeePayment } from "./filter-employee-payment.hook";

const FilterEmployeePaymentContext = createContext(
  {} as ReturnType<typeof useFilterEmployeePayment>
);
export function useFilterEmployeePaymentContext() {
  return useContext(FilterEmployeePaymentContext);
}

interface FilterEmployeePaymentProviderProps {
  children: ReactNode;
}
export function FilterEmployeePaymentProvider({
  children,
}: FilterEmployeePaymentProviderProps) {
  return (
    <FilterEmployeePaymentContext.Provider value={useFilterEmployeePayment()}>
      {children}
    </FilterEmployeePaymentContext.Provider>
  );
}