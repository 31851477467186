import { ReactNode, createContext, useContext } from "react";
import { useFilterHoliday } from "./filter-holiday.hook";

const FilterHolidayContext = createContext(
  {} as ReturnType<typeof useFilterHoliday>
);
export function useFilterHolidayContext() {
  return useContext(FilterHolidayContext);
}

interface FilterHolidayProviderProps {
  children: ReactNode;
}
export function FilterHolidayProvider({
  children,
}: FilterHolidayProviderProps) {
  return (
    <FilterHolidayContext.Provider value={useFilterHoliday()}>
      {children}
    </FilterHolidayContext.Provider>
  );
}
