import { Outlet } from "react-router-dom";

import { FilterEmployeeLedgerProvider } from "./filter-employee-ledger/filter-employee-ledger.context";
import './employee-ledger.scss';

export function EmployeeLedger() {
  return (
    <div id="EmployeeLedger" className="employee-ledger">
       <FilterEmployeeLedgerProvider>
        <Outlet />
      </FilterEmployeeLedgerProvider>
    </div>
  );
}

export default EmployeeLedger;
