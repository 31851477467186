import { ReactNode, createContext, useContext } from "react";
import { useFilterPersonAddress } from "./filter-person-address.hook";

const FilterPersonAddressContext = createContext(
  {} as ReturnType<typeof useFilterPersonAddress>
);
export function useFilterPersonAddressContext() {
  return useContext(FilterPersonAddressContext);
}

interface FilterPersonAddressProviderProps {
  children: ReactNode;
}
export function FilterPersonAddressProvider({
  children,
}: FilterPersonAddressProviderProps) {
  return (
    <FilterPersonAddressContext.Provider value={useFilterPersonAddress()}>
      {children}
    </FilterPersonAddressContext.Provider>
  );
}