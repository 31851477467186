import {
  MessageResponseDto,
  GetEmployeeFavoriteDto,
  CreateEmployeeFavoriteDto,
  EmployeeFavoriteControllerGet200Response,
  EmployeeFavoriteApiFp,
  DeleteEmployeeFavoriteDto,
  AddEmployeeFavoriteDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeFavoriteModel =
  EmployeeFavoriteControllerGet200Response;
export type GetEmployeeFavoriteModel = GetEmployeeFavoriteDto;
export type MutateResponseEmployeeFavoriteModel = MessageResponseDto;
export type CreateEmployeeFavoriteModel = CreateEmployeeFavoriteDto;
export type AddEmployeeFavoriteModel = AddEmployeeFavoriteDto;
export type DeleteEmployeeFavoriteModel = DeleteEmployeeFavoriteDto;

export const apiEmployeeFavoriteFp = EmployeeFavoriteApiFp(apiConfig);
export const getEmployeeFavoriteController =
  apiEmployeeFavoriteFp.employeeFavoriteControllerGet;
export const createEmployeeFavoriteController =
  apiEmployeeFavoriteFp.employeeFavoriteControllerCreate;
export const deleteEmployeeFavoriteController =
  apiEmployeeFavoriteFp.employeeFavoriteControllerDelete;

export const QueryKeyEmployeeFavorite = QueryKey.EmployeeFavorite;
