import './new-penalty-schedule.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreatePenaltySchedule } from "@core/query/penalty-schedule.query";
import { PenaltyScheduleFormRule, PenaltyScheduleFormSchema } from "@core/form/form.rule";
import { penaltyScheduleFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormPenaltySchedule from "../form-penalty-schedule/form-penalty-schedule";

export function NewPenaltySchedule() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreatePenaltySchedule } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New PenaltySchedule is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addPenaltySchedule, isLoading: isCreating } =
    useQyCreatePenaltySchedule(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<PenaltyScheduleFormSchema>({
    defaultValues: penaltyScheduleFormDefault,
    resolver: zodResolver(PenaltyScheduleFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: PenaltyScheduleFormSchema) => {
    const formData = mapCreatePenaltySchedule(form);
    addPenaltySchedule(formData);
  };
  const handleValidateError = (err: FieldErrors<PenaltyScheduleFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="PenaltySchedule" className="penalty-schedule">
      <HeaderContent
        title="New Penalty Schedule"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormPenaltySchedule />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewPenaltySchedule;