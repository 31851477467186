import { format } from "date-fns";
import {
  BranchFormSchema,
  EmployeeFormSchema,
  HolidayFormSchema,
  HolidayTypeFormSchema,
  JobTitleFormSchema,
  JobGradeFormSchema,
  ShiftTypeFormSchema,
  StatusFormSchema,
  ShiftFormSchema,
  EmploymentTypeFormSchema,
  DepartmentFormSchema,
  RoleFormSchema,
  EmployeeBucketFormSchema,
  PersonFormSchema,
  PersonAddressFormSchema,
  EmployeeShiftFormSchema,
  QuestionnaireFormSchema,
  EmployeeScoreFormSchema,
  EmployeeScoreArrayFormSchema,
  DailyTimeRecordFormSchema,
  LeaveSubcategoryFormSchema,
  LeaveCategoryFormSchema,
  LeaveTypeFormSchema,
  RequestTypeFormSchema,
  EmployeeRequestFormSchema,
  PaymentMethodFormSchema,
  EmployeeIncidentFormSchema,
  IncidentTypeFormSchema,
  SalaryTypeFormSchema,
  EmployeeSalaryFormSchema,
  EmployeeDeductionFormSchema,
  EmployeePenaltyFormSchema,
  EmployeeTimelineFormSchema,
  PayrollFormSchema,
  EmployeeLedgerFormSchema,
  EmployeeAttachmentFormSchema,
  EmployeeIncentiveFormSchema,
  EmployeeDiciplinaryFormSchema,
  ViolationFormSchema,
  InfractionFormSchema,
  PenaltyScheduleFormSchema,
  AttachmentTypeFormSchema,
  EmployeeLoanFormSchema,
  EmployeePaymentFormSchema,
  EmployeeBranchFormSchema,
  EmployeeOverageFormSchema,
  FaqFormSchema,
  DeviceAuthorizationFormSchema,
  FavoriteFormSchema,
  EmployeeFavoriteFormSchema,
  EmployeeFavoriteArrayFormSchema,
  // DEFAULT IMPORT ADD
} from "./form.rule";
import { SETTINGS } from "@core/utility/settings";

export const loginFormDefault = {
  username: "",
  password: "",
};
export const branchFormDefault = {
  code: "",
  name: "",
  description: "",
  head: "",
} as BranchFormSchema;
export const employeeFormDefault = {
  code: "",
  firstName: "",
  middleName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  avatar: "",
  gender: "",
  birthday: "" as any,
} as EmployeeFormSchema;
export const holidayFormDefault = {
  name: "",
  description: "",
  date: "" as any,
  type: "",
} as HolidayFormSchema;
export const holidayTypeFormDefault = {
  name: "",
  description: "",
  percentage: 0,
} as HolidayTypeFormSchema;
export const jobTitleFormDefault = {
  name: "",
  description: "",
} as JobTitleFormSchema;
export const jobGradeFormDefault = {
  name: "",
  description: "",
} as JobGradeFormSchema;
export const shiftTypeFormDefault = {
  name: "",
  description: "",
} as ShiftTypeFormSchema;
export const statusFormDefault = {
  name: "",
  description: "",
} as StatusFormSchema;
export const shiftFormDefault = {
  type: "",
  start: "",
  end: "",
  halfDay: 0,
  gracePeriod: 0,
  workHours: 0,
  break: 0,
  breakStart: "",
  breakEnd: "",
  title: "",
  isOpen: false,
} as ShiftFormSchema;
export const employmentTypeFormDefault = {
  name: "",
  description: "",
} as EmploymentTypeFormSchema;
export const departmentFormDefault = {
  name: "",
  description: "",
  head: "",
} as DepartmentFormSchema;
export const roleFormDefault = {
  name: "",
  description: "",
} as RoleFormSchema;
export const employeeBucketFormDefault = {
  code: "",
  userCode: "",
  employeeNo: "",
  signedDate: "" as any,
  startDate: "" as any,
  endDate: "" as any,
  jobTitle: "",
  jobGrade: "",
  employmentType: "",
  department: "",
  branch: "",
  reportingTo: "",
  offboardReason: "",
  resignationDate: "" as any,
  role: "",
  pagibig: 0,
  philhealth: 0,
  sss: 0,
} as EmployeeBucketFormSchema;
export const personAddressFormDefault = {
  code: "",
  userCode: "",
  streetName: "",
  subdivision: "",
  zone: "",
  sitio: "",
  purok: "",
  barangay: "",
  municipality: "",
  province: "",
  region: "",
  country: "Philippines",
  zip: "",
  longitude: 0,
  latitude: 0,
} as PersonAddressFormSchema;
export const personFormDefault = {
  avatar: "",
  attachmentType: "",
  firstName: "",
  middleName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  gender: "",
  birthday: "" as any,
  bucket: employeeBucketFormDefault,
  address: personAddressFormDefault,
} as PersonFormSchema;
export const employeeShiftFormDefault = {
  userCode: "",
  bucketCode: "",
  shiftCode: "",
  shiftDescription: "",
  effectivityDate: format(new Date(), SETTINGS.dateFormat),
  endDate: "" as any,
  day: "",
  isPermanent: false,
} as EmployeeShiftFormSchema;
export const questionnaireFormDefault = {
  jobTitle: "",
  name: "",
  description: "",
} as QuestionnaireFormSchema;
export const employeeScoreFormDefault = {
  userCode: "",
  bucketCode: "",
  branchCode: "",
  questionCode: "",
  questionLabel: "",
  score: 0,
  effectivityDate: "" as any,
} as EmployeeScoreFormSchema;
export const employeeScoreArrayFormDefault = {
  scores: [],
} as EmployeeScoreArrayFormSchema;
export const dailyTimeRecordFormDefault = {
  clockIn: "" as any,
  clockOut: "" as any,
} as DailyTimeRecordFormSchema;
export const leaveSubcategoryFormDefault = {
  name: "",
  description: "",
} as LeaveSubcategoryFormSchema;
export const leaveCategoryFormDefault = {
  name: "",
  description: "",
} as LeaveCategoryFormSchema;
export const leaveTypeFormDefault = {
  name: "",
  description: "",
} as LeaveTypeFormSchema;
export const requestTypeFormDefault = {
  name: "",
  description: "",
} as RequestTypeFormSchema;
export const employeeRequestFormDefault = {
  userCode: "",
  bucketCode: "",
  requestType: "",
  requestReason: "",
  effectivityDate: "" as any,
  effectivityDateEnd: "" as any,
  leaveType: "",
  leaveCategory: "",
  leaveSubcategory: "",
  amendCurrentClockin: "",
  amendCurrentClockout: "",
  amendClockin: "",
  amendClockout: "",
  shiftCurrentSchedule: "",
  shiftSchedule: "",
  isPermanent: false,
  shiftDay: "",
  endDate: "" as any,
  otTime: 0,
  status: "",
  dtrCode: "",
  workDate: "",
  file: undefined,
  breakCode: "",
  isBreakPermission: false,
  amendBreakEnd: "",
  amendBreakStart: "",
} as EmployeeRequestFormSchema;
export const paymentMethodFormDefault = {
  name: "",
  description: "",
} as PaymentMethodFormSchema;
export const employeeIncidentFormDefault = {
  userCode: "",
  bucketCode: "",
  branchCode: "",
  effectivityDate: "" as any,
  incidentType: "",
  paymentMethod: "",
  amount: 0,
  balance: 0,
  isPaid: false,
  file: undefined,
  intervals: 0,
  paymentStart: "" as any,
  remarks: "",
} as EmployeeIncidentFormSchema;
export const incidentTypeFormDefault = {
  name: "",
  description: "",
} as IncidentTypeFormSchema;
export const salaryTypeFormDefault = {
  name: "",
  description: "",
} as SalaryTypeFormSchema;
export const employeeSalaryFormDefault = {
  userCode: "",
  bucketCode: "",
  salaryType: "",
  amount: 0,
  effectivityDate: "" as any,
  remarks: "",
} as EmployeeSalaryFormSchema;
export const employeeDeductionFormDefault = {
  userCode: "",
  bucketCode: "",
  incident: "",
  amount: 0,
  effectivityDate: "" as any,
  remarks: "",
} as EmployeeDeductionFormSchema;
export const employeePenaltyFormDefault = {
  userCode: "",
  bucketCode: "",
  amount: 0,
  effectivityDate: "" as any,
  remarks: "",
  file: undefined,
} as EmployeePenaltyFormSchema;
export const employeeTimelineFormDefault = {
  userCode: "",
  bucketCode: "",
  source: "",
  action: "",
  message: "",
} as EmployeeTimelineFormSchema;
export const payrollFormDefault = {
  userCode: "",
} as PayrollFormSchema;
export const employeeLedgerFormDefault = {
  userId: "",
  bucketId: "",
  ledgerValue: 0,
  ledgerAction: "",
  ledgerStatus: "APPROVED",
  transactionCode: "",
  ledgerRemarks: "",
  effectivityDate: "" as any,
} as EmployeeLedgerFormSchema;
export const employeeAttachmentFormDefault = {
  isActive: true,
  userCode: "",
  bucketCode: "",
  source: "",
  attachmentType: "",
  requestCode: "",
  file: undefined,
  mimeType: "",
  code: "",
} as EmployeeAttachmentFormSchema;
export const employeeIncentiveFormDefault = {
  userCode: "",
  bucketCode: "",
  amount: 0,
  effectivityDate: "" as any,
  remarks: "",
  file: undefined,
} as EmployeeIncentiveFormSchema;
export const employeeDiciplinaryFormDefault = {
  userCode: "",
  bucketCode: "",
  branchCode: "",
  incidentDate: "" as any,
  issuedDate: "" as any,
  violationCode: "",
  infractionCode: "",
  details: "",
  penaltyScheduleCode: "",
  actionPlan: "",
  followupDate: "" as any,
  issuer: "",
  supervisor: "",
  issuerAutocompleteLabel: "",
  supervisorAutocompleteLabel: "",
} as EmployeeDiciplinaryFormSchema;
export const violationFormDefault = {
  name: "",
  description: "",
} as ViolationFormSchema;
export const infractionFormDefault = {
  name: "",
  description: "",
} as InfractionFormSchema;
export const penaltyScheduleFormDefault = {
  name: "",
  description: "",
} as PenaltyScheduleFormSchema;
export const attachmentTypeFormDefault = {
  name: "",
  description: "",
} as AttachmentTypeFormSchema;
export const employeeLoanFormDefault = {
  userCode: "",
  bucketCode: "",
  amount: 0,
  effectivityDate: "" as any,
  subject: "",
  purpose: "",
  file: undefined,
  intervals: 0,
  paymentStart: "" as any,
} as EmployeeLoanFormSchema;
export const employeePaymentFormDefault = {
  userCode: "",
  bucketCode: "",
  loan: "",
  amount: 0,
  effectivityDate: "" as any,
  remarks: "",
} as EmployeePaymentFormSchema;
export const employeeBranchFormDefault = {
  userCode: "",
  bucketCode: "",
  branchCode: "",
  effectivityDate: "" as any,
} as EmployeeBranchFormSchema;
export const employeeOverageFormDefault = {
  userCode: "",
  bucketCode: "",
  amount: 0,
  effectivityDate: "" as any,
  remarks: "",
  file: undefined,
} as EmployeeOverageFormSchema;
export const faqFormDefault = {
  title: "",
  description: "",
  link: "",
} as FaqFormSchema;
export const deviceAuthorizationFormDefault = {
  authorize: false,
} as DeviceAuthorizationFormSchema;
export const favoriteFormDefault = {
  name: "",
  description: "",
} as FavoriteFormSchema;
export const employeeFavoriteFormDefault = {
  userCode: "",
  favoriteCode: "",
} as EmployeeFavoriteFormSchema;
export const employeeFavoriteArrayFormDefault = {
  groups: [],
} as EmployeeFavoriteArrayFormSchema;
// DEFAULT ADD
