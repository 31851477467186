import { ReactNode, createContext, useContext } from "react";
import { useFilterLeaveType } from "./filter-leave-type.hook";

const FilterLeaveTypeContext = createContext(
  {} as ReturnType<typeof useFilterLeaveType>
);
export function useFilterLeaveTypeContext() {
  return useContext(FilterLeaveTypeContext);
}

interface FilterLeaveTypeProviderProps {
  children: ReactNode;
}
export function FilterLeaveTypeProvider({
  children,
}: FilterLeaveTypeProviderProps) {
  return (
    <FilterLeaveTypeContext.Provider value={useFilterLeaveType()}>
      {children}
    </FilterLeaveTypeContext.Provider>
  );
}