import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeAttachment } from "./filter-employee-attachment.hook";

const FilterEmployeeAttachmentContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeAttachment>
);
export function useFilterEmployeeAttachmentContext() {
  return useContext(FilterEmployeeAttachmentContext);
}

interface FilterEmployeeAttachmentProviderProps {
  children: ReactNode;
}
export function FilterEmployeeAttachmentProvider({
  children,
}: FilterEmployeeAttachmentProviderProps) {
  return (
    <FilterEmployeeAttachmentContext.Provider value={useFilterEmployeeAttachment()}>
      {children}
    </FilterEmployeeAttachmentContext.Provider>
  );
}