import "./new-employee-score.scss";

import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeScore } from "@core/query/employee-score.query";
import {
  EmployeeScoreArrayFormSchema,
  EmployeeScoreArrayFormRule,
} from "@core/form/form.rule";
import { employeeScoreArrayFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormArrayEmployeeScore from "../form-array-employee-score/form-array-employee-score";
import { AddEmployeeScoreModel } from "@core/model/query-model-employee-score";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";

export interface EmployeeScoreDefaultProps {
  personId?: string;
  branchId?: string;
  bucketCode?: string;
  jobTitle?: string;
}
export interface EmployeeScoreProps {
  standalone?: boolean;
  defaultProps?: EmployeeScoreDefaultProps;
}
export function NewEmployeeScore({
  standalone,
  defaultProps,
}: EmployeeScoreProps) {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateEmployeeScore } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { personId, branchId } = useParams();
  const { selectedPersonData } = useFormDropdownPersonContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New EmployeeScore is created`);
    if (!standalone) {
      navigate(`../`);
    }
  };
  const { mutate: addEmployeeScore, isLoading: isCreating } =
    useQyCreateEmployeeScore(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeScoreArrayFormSchema>({
    defaultValues: employeeScoreArrayFormDefault,
    resolver: zodResolver(EmployeeScoreArrayFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeScoreArrayFormSchema) => {
    const score = form.scores.map((item) => mapCreateEmployeeScore(item));
    const payload = {
      score,
    } as AddEmployeeScoreModel;
    addEmployeeScore(payload);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeScoreArrayFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const getEmployeeScoreProps = () => {
    if (standalone) {
      return {
        personId: defaultProps?.personId,
        branchId: defaultProps?.branchId,
        bucketCode: defaultProps?.bucketCode,
        jobTitle: defaultProps?.jobTitle,
      };
    }

    const bucketCode = selectedPersonData?.bucket.code;
    const jobTitle = selectedPersonData?.bucket?.job_title;
    return {
      personId,
      branchId,
      bucketCode,
      jobTitle,
    };
  };

  return (
    <div id="EmployeeScore" className="employee-score">
      <HeaderContent
        title="New Employee Score"
        backIcon={standalone ? null : <FaChevronLeft />}
        onBack={() => navigate("../")}
        headingType={standalone ? "h3" : "h1"}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormArrayEmployeeScore
              personId={getEmployeeScoreProps().personId || ""}
              branchId={getEmployeeScoreProps().branchId || ""}
              bucketCode={getEmployeeScoreProps().bucketCode || ""}
              jobId={getEmployeeScoreProps().jobTitle || ""}
            />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeScore;
