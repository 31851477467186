import { 
    MessageResponseDto, 
    DeviceAuthorizationApiFp,
    DeviceAuthorization,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type MutateResponseDeviceAuthorizationModel = MessageResponseDto;
export type CreateDeviceAuthorizationModel = DeviceAuthorization;

export const apiDeviceAuthorizationFp = DeviceAuthorizationApiFp(apiConfig);
export const createDeviceAuthorizationController = apiDeviceAuthorizationFp.deviceRegistrationControllerRegister;

export const QueryKeyDeviceAuthorization = QueryKey.DeviceAuthorization;