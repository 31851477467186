import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetInfractionModel } from "@core/model/query-model-infraction";
import { infractionFormDefault } from "./form.default";
import { InfractionFormSchema } from "./form.rule";

export const useFormDefaultInfraction = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetInfractionModel | undefined) => {
    return !cachedValue
      ? infractionFormDefault
     : ({
        name: cachedValue.name,
        description: cachedValue.description,
      } as InfractionFormSchema);
  };

  return {
    getDefault,
  };
};
