import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeSalaryModel } from "@core/model/query-model-employee-salary";
import { employeeSalaryFormDefault } from "./form.default";
import { EmployeeSalaryFormSchema } from "./form.rule";

export const useFormDefaultEmployeeSalary = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeSalaryModel | undefined) => {
    return !cachedValue
      ? employeeSalaryFormDefault
      : ({
          userCode: cachedValue.user_code,
          bucketCode: cachedValue.bucket_code,
          salaryType: cachedValue.salary_type,
          amount: cachedValue.amount,
          effectivityDate: formatDate(cachedValue.effectivity_date) as any,
          remarks: "",
        } as EmployeeSalaryFormSchema);
  };

  return {
    getDefault,
  };
};
