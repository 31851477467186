import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeTimelineModel } from "@core/model/query-model-employee-timeline";
import { employeeTimelineFormDefault } from "./form.default";
import { EmployeeTimelineFormSchema } from "./form.rule";

export const useFormDefaultEmployeeTimeline = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeTimelineModel | undefined) => {
    return !cachedValue
      ? employeeTimelineFormDefault
     : ({
        userCode: cachedValue.user_code,
        bucketCode: cachedValue.bucket_code,
        source: cachedValue.source,
        action: cachedValue.action,
        message: cachedValue.message,
      } as EmployeeTimelineFormSchema);
  };

  return {
    getDefault,
  };
};
