import { 
    MessageResponseDto, 
    GetEmploymentTypeDto, 
    CreateEmploymentTypeDto, 
    EditEmploymentTypeDto,
    UtilEmploymentTypeControllerGet200Response,
    UtilityEmploymentTypeApiFp,
    DeleteEmploymentTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmploymentTypeModel = UtilEmploymentTypeControllerGet200Response;
export type GetEmploymentTypeModel = GetEmploymentTypeDto;
export type MutateResponseEmploymentTypeModel = MessageResponseDto;
export type CreateEmploymentTypeModel = CreateEmploymentTypeDto;
export type UpdateEmploymentTypeModel = EditEmploymentTypeDto;
export type DeleteEmploymentTypeModel = DeleteEmploymentTypeDto;

export const apiEmploymentTypeFp =  UtilityEmploymentTypeApiFp(apiConfig);
export const getEmploymentTypeController = apiEmploymentTypeFp.utilEmploymentTypeControllerGet;
export const createEmploymentTypeController = apiEmploymentTypeFp.utilEmploymentTypeControllerCreate;
export const updateEmploymentTypeController = apiEmploymentTypeFp.utilEmploymentTypeControllerEdit;
export const deleteEmploymentTypeController = apiEmploymentTypeFp.utilEmploymentTypeControllerDelete;

export const QueryKeyEmploymentType = QueryKey.EmploymentType;