import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import { useSessionContext } from "./session.context";
import {
  GeneratePayrollModel,
  GetPayrollSummaryModel,
  GetResponsePayrollModel,
  GetResponsePayrollSummaryModel,
  QueryKeyPayroll,
  QueryKeyPayrollSummary,
  getPayrollController,
  getPayrollSummmaryController,
} from "@core/model/query-model-payroll";

export function useQyGetPayroll(
  dateFrom: string,
  dateTo: string,
  branchCode: string,
  employmentType?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponsePayrollModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    dateFrom: string,
    dateTo: string,
    branchCode: string
  ) => {
    await checkSession();

    showProgress();
    const operation = await getPayrollController(
      dateFrom,
      dateTo,
      branchCode,
      employmentType,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["data"] as GetResponsePayrollModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyPayroll, dateFrom, dateTo, branchCode],
    queryFn: () => apiFn(dateFrom, dateTo, branchCode),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGeneratePayroll(
  onSuccess?:
    | ((data: GetResponsePayrollModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async ({
    dateFrom,
    dateTo,
    branchCode,
    employmentType,
  }: GeneratePayrollModel) => {
    await checkSession();

    showProgress();
    const operation = await getPayrollController(
      dateFrom,
      dateTo,
      branchCode,
      employmentType,
      authHeaders()
    );
    const response = (await operation()).data.data;
    return response as GetResponsePayrollModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response: any) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyPayroll);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetPayrollSummary(
  dateFrom: string,
  dateTo: string,
  branchCode: string,
  employmentType?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponsePayrollSummaryModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    dateFrom: string,
    dateTo: string,
    branchCode: string
  ) => {
    await checkSession();

    showProgress();
    const operation = await getPayrollSummmaryController(
      dateFrom,
      dateTo,
      branchCode,
      employmentType,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["data"] as GetResponsePayrollSummaryModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyPayrollSummary, dateFrom, dateTo, branchCode],
    queryFn: () => apiFn(dateFrom, dateTo, branchCode),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
