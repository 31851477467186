import { 
    MessageResponseDto, 
    GetEmployeeSalaryDto, 
    CreateEmployeeSalaryDto, 
    EditEmployeeSalaryDto,
    EmployeeSalaryControllerGet200Response,
    EmployeeSalaryApiFp,
    DeleteEmployeeSalaryDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeSalaryModel = EmployeeSalaryControllerGet200Response;
export type GetEmployeeSalaryModel = GetEmployeeSalaryDto;
export type MutateResponseEmployeeSalaryModel = MessageResponseDto;
export type CreateEmployeeSalaryModel = CreateEmployeeSalaryDto;
export type UpdateEmployeeSalaryModel = EditEmployeeSalaryDto;
export type DeleteEmployeeSalaryModel = DeleteEmployeeSalaryDto;

export const apiEmployeeSalaryFp = EmployeeSalaryApiFp(apiConfig);
export const getEmployeeSalaryController = apiEmployeeSalaryFp.employeeSalaryControllerGet;
export const createEmployeeSalaryController = apiEmployeeSalaryFp.employeeSalaryControllerCreate;
export const updateEmployeeSalaryController = apiEmployeeSalaryFp.employeeSalaryControllerEdit;
export const deleteEmployeeSalaryController = apiEmployeeSalaryFp.employeeSalaryControllerDelete;

export const QueryKeyEmployeeSalary = QueryKey.EmployeeSalary;