import { 
    MessageResponseDto, 
    GetRoleDto, 
    CreateRoleDto, 
    EditRoleDto,
    UtilRoleControllerGet200Response,
    UtilityRoleApiFp,
    DeleteRoleDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseRoleModel = UtilRoleControllerGet200Response;
export type GetRoleModel = GetRoleDto;
export type MutateResponseRoleModel = MessageResponseDto;
export type CreateRoleModel = CreateRoleDto;
export type UpdateRoleModel = EditRoleDto;
export type DeleteRoleModel = DeleteRoleDto;

export const apiRoleFp =  UtilityRoleApiFp(apiConfig);
export const getRoleController = apiRoleFp.utilRoleControllerGet;
export const createRoleController = apiRoleFp.utilRoleControllerCreate;
export const updateRoleController = apiRoleFp.utilRoleControllerEdit;
export const deleteRoleController = apiRoleFp.utilRoleControllerDelete;

export const QueryKeyRole = QueryKey.Role;