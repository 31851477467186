import { AxiosError } from "axios";
import { FieldErrors } from "react-hook-form";

export interface ApiResponseMessage {
  message: string[] | string;
  data?: Record<string, string>[];
}

export const getApiErrorMessage = (
  error: AxiosError,
  defaultMessage?: string
): string => {
  const responseError = error.response?.data as ApiResponseMessage;

  if (responseError.data && responseError.data.length > 0) {
    const messages = responseError.data.map((x) => x.message);
    return messages.join(". ");
  }

  if (
    Array.isArray(responseError.message) &&
    responseError.message.length > 0
  ) {
    return (responseError.message as string[]).join(". ");
  } else if (typeof responseError.message === "string") {
    return responseError.message;
  } else if (defaultMessage) {
    return defaultMessage;
  }

  return "Something went wrong. Kindly refresh the page and try again. Or if problem persist, contact the administrators";
};

export const getFormErrorMessage = (error: FieldErrors) => {
  const messages = Object.keys(error).map((key) => {
    if (typeof error[key] === "object" && !error[key]?.message) {
      return getFormErrorMessage(error[key] as FieldErrors);
    }

    return error[key]?.message || "Please fill in required fields";
  }) as string[];
  const formMessages = messages.join(". ");
  return formMessages;
};
