import { Outlet } from "react-router-dom";

import { FilterRequestTypeProvider } from "./filter-request-type/filter-request-type.context";
import './request-type.scss';

export function RequestType() {
  return (
    <div id="RequestType" className="request-type">
       <FilterRequestTypeProvider>
        <Outlet />
      </FilterRequestTypeProvider>
    </div>
  );
}

export default RequestType;
