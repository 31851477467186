import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownEmploymentType } from "./form-dropdown-employment-type.hook";

const FormDropdownEmploymentTypeContext = createContext(
  {} as ReturnType<typeof useFormDropdownEmploymentType>
);
export function useFormDropdownEmploymentTypeContext() {
  return useContext(FormDropdownEmploymentTypeContext);
}

interface FormDropdownEmploymentTypeProviderProps {
  children: ReactNode;
}
export function FormDropdownEmploymentTypeProvider({
  children,
}: FormDropdownEmploymentTypeProviderProps) {
  return (
    <FormDropdownEmploymentTypeContext.Provider value={useFormDropdownEmploymentType()}>
      {children}
    </FormDropdownEmploymentTypeContext.Provider>
  );
}
