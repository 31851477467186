import "./list-employee-bucket.scss";

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetEmployeeBucket } from "@core/query/employee-bucket.query";
import { GetEmployeeBucketModel } from "@core/model/query-model-employee-bucket";
import { TableSort } from "@core/model/table-sort";
import { FilterEmployeeBucket } from "../filter-employee-bucket/filter-employee-bucket";
import { useFilterEmployeeBucketContext } from "../filter-employee-bucket/filter-employee-bucket.context";
import { useQyGetEmployeeScoreAverage } from "@core/query/employee-score.query";

export interface ListEmployeeBucketProps {
  returnParam?: string;
}
export function ListEmployeeBucket({ returnParam }: ListEmployeeBucketProps) {
  // THIS IS THE LOCAL DECLARATION
  const navigate = useNavigate();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const [filterPanel, setFilterPanel] = useState(false);
  const { filterChipsElement, getFilterEntityValues, branchId } =
    useFilterEmployeeBucketContext();

  // THIS IS THE QUERY DECLARATION
  const {
    data: employeeBucketResponse,
    isLoading,
    isError,
    error,
  } = useQyGetEmployeeBucket(
    searchTerm,
    rowLimit,
    pageNumber,
    sort,
    getFilterEntityValues()
  );
  const employeeData = employeeBucketResponse?.data;

  const { data: scoreResponse } = useQyGetEmployeeScoreAverage();
  const scoreData = scoreResponse?.data;

  const getCombinedEmployee = useCallback(() => {
    const combinedData = (employeeData || []).map((emp) => {
      const matchedItem = (scoreData || []).find(
        (score) => score.user_code === emp.user_code
      );
      return {
        ...emp,
        score: matchedItem?.tally,
      };
    });
    return combinedData;
  }, [employeeData, scoreData]);

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetEmployeeBucketModel) => {
    navigate(
      `/employee/${item.branch}/${item.user_code}?return=${returnParam}`
    );
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleNew = () => {
    if (!branchId) {
      navigate(`/branch`);
      return;
    }

    navigate(`/employee/${branchId}/new`);
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <SearchInput
          clearIcon={<MdClear />}
          searchIcon={<CiSearch />}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          placeholder="Search items"
          className="w-full block"
        />
        {!branchId ? (
          <Button
            label="Filter"
            severity="secondary"
            badgeClassName="p-badge-danger"
            onClick={() => setFilterPanel(true)}
            outlined
          />
        ) : null}
      </div>

      {filterChipsElement}
      <Sidebar visible={filterPanel} onHide={() => setFilterPanel(false)}>
        <FilterEmployeeBucket />
      </Sidebar>
    </section>
  );
  const displayPersonCol = (cell: any) => {
    return (
      <span>
        {cell.person_last_name}, {cell.person_first_name}
      </span>
    );
  };
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{employeeBucketResponse?.count}</b>
      </h5>

      <DataTable
        value={getCombinedEmployee()}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord(e.value)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column body={displayPersonCol} header="Name" sortable></Column>
        <Column field="employee_no" header="EmployeeNo" sortable></Column>
        <Column field="role_name" header="Role" sortable></Column>
        <Column field="branch_name" header="Branch" sortable></Column>
        <Column field="employment_type_name" header="Status" sortable></Column>
        <Column field="job_title_name" header="Position" sortable></Column>
        <Column field="department_name" header="Department" sortable></Column>
        <Column header="Score" field="score"></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={employeeBucketResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="EmployeeBucket" className="employee-bucket">
      <HeaderContent title="Employees" headingType="h2">
        <span className="flex gap-2">
          <Button label="New" onClick={handleNew} />
        </span>
      </HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default ListEmployeeBucket;
