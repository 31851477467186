import { useState } from "react";
import { useQyGetQuestionnaire } from "@core/query/questionnaire.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { UtilQuestionnaireControllerGet200Response } from "@api/api";

export interface FormDropdownQuestionnaireProps {
  search?: string;
  limit?: number;
  recordFilter?: Record<string, string>;
  onApiSuccess?: (data: UtilQuestionnaireControllerGet200Response) => void;
}
export const useFormDropdownQuestionnaire = ({
  search,
  limit,
  recordFilter,
  onApiSuccess,
}: FormDropdownQuestionnaireProps) => {
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");

  const handleFilterInput = (event: any) => {
    if (event.key === "Enter") {
      setSidebar(true);
    }
  };
  const { data: questionnaireResponse } = useQyGetQuestionnaire(
    search || "",
    limit || 99,
    0,
    undefined,
    recordFilter,
    undefined,
    undefined,
    undefined,
    true,
    onApiSuccess
  );
  const mappedQuestionnaire = (questionnaireResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return {
    sidebar,
    filter,
    mappedQuestionnaire,
    questionnaireResponse,
    setSidebar,
    setFilter,
    handleFilterInput,
  };
};
