import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownEmploymentTypeContext } from "./form-dropdown-employment-type.context";

export interface FormDropdownEmploymentTypeProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownEmploymentType<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownEmploymentTypeProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const {
    sidebar,
    newEmploymentType,
    mappedEmploymentType,
    isCreating,
    setSidebar,
    setFilter,
    setNewEmploymentType,
    handleFilterInput,
    handleAdd,
  } = useFormDropdownEmploymentTypeContext();
  
  return (
    <div className="employment-type">
      {newEmploymentType && (
        <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
          <h2>Create new employmentType</h2>
          <p>
            You are creating a new employmentType. Please, fill the fields to create a
            new employmentType and apply to current item creation.
          </p>
          <div className="flex flex-col gap-2 mt-4">
            <InputText
              placeholder="EmploymentType Name"
              value={newEmploymentType.name}
              onChange={(e: any) =>
                setNewEmploymentType({ ...newEmploymentType, name: e.target.value })
              }
            />
            <InputText
              placeholder="EmploymentType Description"
              value={newEmploymentType.description}
              onChange={(e: any) =>
                setNewEmploymentType({ ...newEmploymentType, description: e.target.value })
              }
            />

            <Button
              label="Create"
              onClick={handleAdd}
              className="block"
              disabled={isCreating}
            />
          </div>
        </Sidebar>
      )}
      <DropdownControl<T>
        control={control}
        name={name}
        label="Employment Type"
        options={ mappedEmploymentType }
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your employment type"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
        onKeyDown={allowCreate ? handleFilterInput : () => {}}
      />
    </div>
  );
}

export default FormDropdownEmploymentType;