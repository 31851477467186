import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { HolidayFormRule, HolidayFormSchema } from "@core/form/form.rule";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import {
  useQyUpdateHoliday,
  useQyGetHolidayById,
  useQyDeleteHoliday,
} from "@core/query/holiday.query";
import { UtilHolidayControllerGet200Response } from "@api/api";
import { useFormDefaultHoliday } from "@core/form/form-default-holiday.hook";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { confirmDialog } from "primereact/confirmdialog";

type GetResponseHolidayModel = UtilHolidayControllerGet200Response;
export function useEditHoliday() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultHoliday();
  const navigate = useNavigate();
  const { mapUpdateHoliday, mapDeleteHoliday } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { holidayId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // GET REQUEST API
  const handleGetApiSuccess = (data: GetResponseHolidayModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
      setValue("date", formatDate(responseData?.date) as any, undefined);
      setValue("type", responseData?.type);
    }
  };
  const {
    data: holidayResponse,
    isLoading,
    isError: holidayError,
  } = useQyGetHolidayById(holidayId || "", handleGetApiSuccess);
  const holidayData = holidayResponse?.data?.[0];

  // API UPDATE Holiday
  const handleUpdateApiSuccess = () => {
    showSuccess("Holiday updated successfully");
  };
  const { mutate: updateHoliday, isLoading: isEditLoading } =
    useQyUpdateHoliday(handleUpdateApiSuccess);

  // API DELETE Holiday
  const handleDeleteApiSuccess = () => {
    showSuccess("Holiday successfully removed");
    handleBack();
  };
  const { mutate: deleteHoliday } = useQyDeleteHoliday(handleDeleteApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<HolidayFormSchema>({
    defaultValues: getDefault(holidayData),
    resolver: zodResolver(HolidayFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: HolidayFormSchema) => {
    if (!holidayData) {
      throw new Error("No holiday data");
    }

    const formData = mapUpdateHoliday(form, holidayData.code);
    updateHoliday(formData);
  };
  const handleValidateError = (err: FieldErrors<HolidayFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!holidayData) {
      throw new Error("No holiday data found");
    }

    const form = mapDeleteHoliday(holidayData.code);
    deleteHoliday(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    holidayId,
    holidayData,
    isLoading,
    holidayError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
