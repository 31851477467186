import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownPerson } from "./form-dropdown-person.hook";

const FormDropdownPersonContext = createContext(
  {} as ReturnType<typeof useFormDropdownPerson>
);
export function useFormDropdownPersonContext() {
  return useContext(FormDropdownPersonContext);
}

interface FormDropdownPersonProviderProps {
  children: ReactNode;
}
export function FormDropdownPersonProvider({
  children,
}: FormDropdownPersonProviderProps) {
  return (
    <FormDropdownPersonContext.Provider value={useFormDropdownPerson()}>
      {children}
    </FormDropdownPersonContext.Provider>
  );
}
