import classNames from "classnames";
import "./header-content";
import { ReactNode } from "react";

export interface HeaderContentProps {
  title: string;
  className?: string;
  children?: ReactNode;
  backIcon?: ReactNode;
  headingType?: "h1" | "h2" | "h3" | "h4" | "h5";
  onBack?: () => void;
}

export function HeaderContent({
  title,
  className,
  children,
  backIcon,
  headingType,
  onBack,
}: HeaderContentProps) {
  const headingDisplay = () => {
    switch (headingType) {
      case "h2":
        return <h2>{title}</h2>;
      case "h3":
        return <h3>{title}</h3>;
      case "h4":
        return <h4>{title}</h4>;
      case "h5":
        return <h5>{title}</h5>;
      default:
        return <h1>{title}</h1>;
    }
  };
  return (
    <header className={classNames('header-content bg-white p-7 flex flex-row justify-between items-center', className)}>
      <div className="flex gap-2 items-center">
        {!!backIcon && onBack ? (
          <button
            className="px-3 py-3 rounded-lg bg-gray-50 hover:bg-gray-100"
            onClick={() => onBack()}
          >
            {backIcon}
          </button>
        ) : null}
        {headingDisplay()}
      </div>
      <div>{children}</div>
    </header>
  );
}

export default HeaderContent;
