import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { DailyTimeRecordPayloadModel } from "@core/model/query-model-daily-time-record";
import { dailyTimeRecordFormDefault } from "./form.default";
import { DailyTimeRecordFormSchema } from "./form.rule";

export const useFormDefaultDailyTimeRecord = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: DailyTimeRecordPayloadModel | undefined) => {
    return !cachedValue
      ? dailyTimeRecordFormDefault
     : ({
      } as DailyTimeRecordFormSchema);
  };

  return {
    getDefault,
  };
};
