import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetPersonModel } from "@core/model/query-model-person";
import { personFormDefault } from "./form.default";
import { PersonFormSchema } from "./form.rule";

export const useFormDefaultPerson = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetPersonModel | undefined) => {
    return !cachedValue
      ? personFormDefault
      : ({
          firstName: cachedValue.first_name,
          middleName: cachedValue.middle_name,
          lastName: cachedValue.last_name,
          username: cachedValue.username,
          email: cachedValue.email,
          password: cachedValue.password,
          gender: cachedValue.gender,
          birthday: formatDate(cachedValue.birthday) as any,
          bucket: {
            userCode: cachedValue?.bucket?.user_code,
            employeeNo: cachedValue?.bucket?.employee_no,
            signedDate: formatDate(cachedValue?.bucket?.signed_date) as any,
            startDate: formatDate(cachedValue?.bucket?.start_date) as any,
            endDate: formatDate(cachedValue?.bucket?.end_date) as any,
            jobTitle: cachedValue?.bucket?.job_title,
            jobGrade: cachedValue?.bucket?.job_grade,
            employmentType: cachedValue?.bucket?.employment_type,
            department: cachedValue?.bucket?.department,
            branch: cachedValue?.bucket?.branch,
            reportingTo: cachedValue?.bucket?.reporting_to,
            offboardReason: cachedValue?.bucket?.offboard_reason,
            resignationDate: formatDate(
              cachedValue?.bucket?.resignation_date
            ) as any,
          },
          address: {
            userCode: cachedValue?.address?.user_code,
            streetName: cachedValue?.address?.street_name,
            subdivision: cachedValue?.address?.subdivision,
            zone: cachedValue?.address?.zone,
            sitio: cachedValue?.address?.sitio,
            purok: cachedValue?.address?.purok,
            barangay: cachedValue?.address?.barangay,
            municipality: cachedValue?.address?.municipality,
            province: cachedValue?.address?.province,
            region: cachedValue?.address?.region,
            country: cachedValue?.address?.country,
            zip: cachedValue?.address?.zip,
            longitude: cachedValue?.address?.longitude,
            latitude: cachedValue?.address?.latitude,
          },
        } as PersonFormSchema);
  };

  return {
    getDefault,
  };
};
