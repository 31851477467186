import { ReactNode, createContext, useContext } from "react";
import { useFilterEmployeeBranch } from "./filter-employee-branch.hook";

const FilterEmployeeBranchContext = createContext(
  {} as ReturnType<typeof useFilterEmployeeBranch>
);
export function useFilterEmployeeBranchContext() {
  return useContext(FilterEmployeeBranchContext);
}

interface FilterEmployeeBranchProviderProps {
  children: ReactNode;
}
export function FilterEmployeeBranchProvider({
  children,
}: FilterEmployeeBranchProviderProps) {
  return (
    <FilterEmployeeBranchContext.Provider value={useFilterEmployeeBranch()}>
      {children}
    </FilterEmployeeBranchContext.Provider>
  );
}