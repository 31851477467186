import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeFavoriteModel } from "@core/model/query-model-employee-favorite";
import { employeeFavoriteFormDefault } from "./form.default";
import { EmployeeFavoriteFormSchema } from "./form.rule";

export const useFormDefaultEmployeeFavorite = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeFavoriteModel | undefined) => {
    return !cachedValue
      ? employeeFavoriteFormDefault
     : ({
        userCode: cachedValue.user_code,
        favoriteCode: cachedValue.favorite_code,
      } as EmployeeFavoriteFormSchema);
  };

  return {
    getDefault,
  };
};
