import "./form-employee-favorite.scss";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  EmployeeFavoriteArrayFormSchema,
  EmployeeFavoriteFormSchema,
} from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import FormAutocompletePerson from "@domain/person/form-dropdown-person/form-autocomplete-person";
import { Button } from "primereact/button";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useQyGetFavorite } from "@core/query/favorite.query";
import { LabelValue } from "@shared/models/label-value.interface";

type InputFormSchema = EmployeeFavoriteArrayFormSchema;
export function FormEmployeeFavorite() {
  const { control } = useFormContext<InputFormSchema>();
  const { fields, append } = useFieldArray({
    control,
    name: "groups",
  });
  const { data } = useQyGetFavorite("", 9999);
  const groupOptions = (data?.data || []).map(
    (item) => new LabelValue(item.name || "-", item.code)
  );

  return (
    <div id="EmployeeFavorite" className="employee-favorite">
      <DropdownControl<InputFormSchema>
        control={control}
        name="favoriteId"
        label="Group Name"
        options={groupOptions}
        containerClassName="mb-9"
        className="w-full md:w-3/4"
      />
      {fields.map((field, id) => (
        <section key={id}>
          <FormAutocompletePerson<InputFormSchema>
            name={`groups.${id}.userCode`}
            label="User"
            hint="Type and wait for the suggestion dropdown to appear and then select the person"
          />
        </section>
      ))}
      <Button
        type="button"
        label="Add a User"
        outlined
        severity="secondary"
        className="block mt-6"
        onClick={() => append({ favoriteCode: "", userCode: "" })}
      />
    </div>
  );
}

export default FormEmployeeFavorite;
