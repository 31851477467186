import { useCallback, useEffect, useState } from "react";
import "./qr-scanner.scss";
import { Html5QrcodeScanner } from "html5-qrcode";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useQyBreakClockIn,
  useQyBreakClockOut,
  useQyClockIn,
  useQyClockOut,
  useQyPrivateBreakIn,
  useQyPrivateBreakOut,
  useQyPrivateClockIn,
  useQyPrivateClockOut,
} from "@core/query/daily-time-record.query";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import StorageService from "@shared/services/storage.service";
import { DEVICETOKEN } from "@core/utility/settings";

const clockInSound = "/sounds/clock-in.mp3";
const clockOutSound = "/sounds/clock-out.mp3";
const startBreakSound = "/sounds/start-break.mp3";
const endBreakSound = "/sounds/end-break.mp3";
const errorSound = "/sounds/error.mp3";

export function QrScanner() {
  let html5QrcodeScanner: any;
  const clockInAudio = new Audio(clockInSound);
  const clockOutAudio = new Audio(clockOutSound);
  const startBreakAudio = new Audio(startBreakSound);
  const endBreakAudio = new Audio(endBreakSound);
  const errorAudio = new Audio(errorSound);

  const navigate = useNavigate();
  const { showSuccess, showWarning } = useNotificationContext();
  const [scanResult, setScanResult] = useState("");
  const { action } = useParams();
  const deviceToken = StorageService.load(DEVICETOKEN);
  const warningMessage =
    "This device is not authorize to clock-in. This malicious attempt is being tracked and recorded to authorities";

  // QUERY API
  const handleFailed = () => {
    navigate("/clock");
    playError();
  };

  // BREAK IN
  const handleBreakClockInSuccess = () => {
    showSuccess(`You have successfully Break In`);
    playBreakIn();
    navigate("/clock");
  };
  const { mutate: breakIn } = useQyBreakClockIn(
    handleBreakClockInSuccess,
    handleFailed
  );
  // useQyPrivateBreakIn

  // BREAK OUT
  const handleBreakClockOutSuccess = () => {
    showSuccess(`You have successfully Break Out`);
    playBreakOut();
    navigate("/clock");
  };
  const { mutate: breakOut } = useQyBreakClockOut(
    handleBreakClockOutSuccess,
    handleFailed
  );
  // useQyPrivateBreakOut

  // CLOCK OUT
  const handleClockOutSuccess = () => {
    showSuccess(`You have successfully Clocked Out`);
    playClockOut();
    navigate("/clock");
  };
  const { mutate: clockOut } = useQyClockOut(
    handleClockOutSuccess,
    handleFailed
  );
  // useQyPrivateClockOut

  // CLOCK IN
  const handleClockInSuccess = () => {
    showSuccess(`You have successfully Clocked In`);
    playClockIn();
    navigate("/clock");
  };
  const { mutate: clockIn } = useQyClockIn(handleClockInSuccess, handleFailed);
  // useQyPrivateClockIn

  const showWarningMessage = useCallback(() => {
    navigate("/clock");
    showWarning(warningMessage);
  }, []);

  useEffect(() => {
    if (!html5QrcodeScanner?.getState()) {
      html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 550, height: 550 } },
        false
      );
      html5QrcodeScanner.render(success, undefined);
    }

    function success(result: any) {
      const split = result.split(":");

      // if (!deviceToken) {
      //   html5QrcodeScanner.clear();
      //   setScanResult(result);
      //   showWarningMessage();
      //   return;
      // }

      if (action === "in" && split.length > 0) {
        clockIn({ bucket_code: split[0] });
      } else if (action === "out" && split.length > 0) {
        clockOut({ bucket_code: split[0] });
      } else if (action === "breakin" && split.length > 0) {
        breakIn({ bucket_code: split[0] });
      } else if (action === "breakout" && split.length > 0) {
        breakOut({ bucket_code: split[0] });
      } else if (action === "leave" && split.length > 0) {
        const name = trimScan(result);
        navigate(`/leave-request?bucket=${split[0]}&name=${name}`);
      } else {
        showWarning("Invalid Code");
        navigate("/clock");
      }

      html5QrcodeScanner.clear();
      setScanResult(result);
    }
  }, []);
  const handleNavigate = () => {
    if (html5QrcodeScanner) {
      html5QrcodeScanner
        .clear()
        .then((x: any) => {
          console.log("CLEAR", { x });
        })
        .catch((error: any) => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
    }
    navigate("/clock");
  };
  const trimScan = useCallback((result: string) => {
    const split = result.split(":");
    return split.length > 0 ? split[1] : "User";
  }, []);

  const playClockIn = useCallback(() => {
    clockInAudio.play();
  }, []);
  const playClockOut = useCallback(() => {
    clockOutAudio.play();
  }, []);
  const playBreakIn = useCallback(() => {
    startBreakAudio.play();
  }, []);
  const playBreakOut = useCallback(() => {
    endBreakAudio.play();
  }, []);
  const playError = useCallback(() => {
    errorAudio.play();
  }, []);

  return (
    <div className="qr-scanner flex flex-col p-7">
      {scanResult ? (
        <section className="flex flex-col justify-center items-center">
          <h1>Thank you, {trimScan(scanResult)}, for scanning. Please Wait.</h1>
        </section>
      ) : (
        <div id="reader"></div>
      )}

      <Button
        className="mt-4"
        severity="secondary"
        label="Return"
        onClick={() => handleNavigate()}
        outlined
      />
    </div>
  );
}

export default QrScanner;
