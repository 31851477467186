export enum ActionEnum {
  Decide = "Decide",
  Approve = "Approve",
  Reject = "Reject",
  Update = "Update",
  Upload = "Upload",
  NewQr = "NewQr",
  New = "New",
  Delete = "Delete",
  Print = "Print",
  NewEmployee = "New Employee",
  Process = "Process",
  ChangeBucket = "ChangeBucket",
  TemporaryTransfer = "TemporaryTransfer",
}
