import { 
    MessageResponseDto, 
    GetEmployeeBranchDto, 
    CreateEmployeeBranchDto, 
    EditEmployeeBranchDto,
    EmployeeBranchControllerGet200Response,
    EmployeeBranchApiFp,
    DeleteEmployeeBranchDto,
    AddEmployeeBranchDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeBranchModel = EmployeeBranchControllerGet200Response;
export type GetEmployeeBranchModel = GetEmployeeBranchDto;
export type MutateResponseEmployeeBranchModel = MessageResponseDto;
export type CreateEmployeeBranchModel = CreateEmployeeBranchDto;
export type CreateEmployeeBranchArrayModel = AddEmployeeBranchDto;
export type UpdateEmployeeBranchModel = EditEmployeeBranchDto;
export type DeleteEmployeeBranchModel = DeleteEmployeeBranchDto;

export const apiEmployeeBranchFp = EmployeeBranchApiFp(apiConfig);
export const getEmployeeBranchController = apiEmployeeBranchFp.employeeBranchControllerGet;
export const createEmployeeBranchController = apiEmployeeBranchFp.employeeBranchControllerCreate;
export const updateEmployeeBranchController = apiEmployeeBranchFp.employeeBranchControllerEdit;
export const deleteEmployeeBranchController = apiEmployeeBranchFp.employeeBranchControllerDelete;

export const QueryKeyEmployeeBranch = QueryKey.EmployeeBranch;