import { 
    MessageResponseDto, 
    GetEmployeeOverageDto, 
    CreateEmployeeOverageDto, 
    EditEmployeeOverageDto,
    EmployeeOverageControllerGet200Response,
    EmployeeOverageApiFp,
    DeleteEmployeeOverageDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeOverageModel = EmployeeOverageControllerGet200Response;
export type GetEmployeeOverageModel = GetEmployeeOverageDto;
export type MutateResponseEmployeeOverageModel = MessageResponseDto;
export type CreateEmployeeOverageModel = CreateEmployeeOverageDto;
export type UpdateEmployeeOverageModel = EditEmployeeOverageDto;
export type DeleteEmployeeOverageModel = DeleteEmployeeOverageDto;

export const apiEmployeeOverageFp = EmployeeOverageApiFp(apiConfig);
export const getEmployeeOverageController = apiEmployeeOverageFp.employeeOverageControllerGet;
export const createEmployeeOverageController = apiEmployeeOverageFp.employeeOverageControllerCreate;
export const updateEmployeeOverageController = apiEmployeeOverageFp.employeeOverageControllerEdit;
export const deleteEmployeeOverageController = apiEmployeeOverageFp.employeeOverageControllerDelete;

export const QueryKeyEmployeeOverage = QueryKey.EmployeeOverage;