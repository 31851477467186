import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeDiciplinaryFormRule,
  EmployeeDiciplinaryFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeDiciplinary } from "@core/form/form-default-employee-diciplinary.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeDiciplinary,
  useQyGetEmployeeDiciplinaryById,
  useQyDeleteEmployeeDiciplinary,
} from "@core/query/employee-diciplinary.query";
import { GetResponseEmployeeDiciplinaryModel } from "@core/model/query-model-employee-diciplinary";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditEmployeeDiciplinary() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeDiciplinary();
  const navigate = useNavigate();
  const { mapUpdateEmployeeDiciplinary, mapDeleteEmployeeDiciplinary } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeDiciplinaryId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID EmployeeDiciplinary
  const handleGetApiSuccess = (data: GetResponseEmployeeDiciplinaryModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("branchCode", responseData?.branch_code || "");
      setValue(
        "incidentDate",
        formatDate(responseData?.incident_date) as any,
        undefined
      );
      setValue(
        "issuedDate",
        formatDate(responseData?.issued_date) as any,
        undefined
      );
      setValue("violationCode", responseData?.violation_code || "");
      setValue("infractionCode", responseData?.infraction_code || "");
      setValue("details", responseData?.details || "");
      setValue(
        "penaltyScheduleCode",
        responseData?.penalty_schedule_code || ""
      );
      setValue("actionPlan", responseData?.action_plan || "");
      setValue("followupDate", responseData?.followup_date || "");
      setValue("issuer", responseData?.issuer || "");
      setValue(
        "issuerAutocompleteLabel",
        `${responseData?.issuer_first_name} ${responseData?.issuer_last_name}` ||
          ""
      );
      setValue("supervisor", responseData?.supervisor || "");
      setValue(
        "supervisorAutocompleteLabel",
        `${responseData?.supervisor_first_name} ${responseData?.supervisor_last_name}` ||
          ""
      );
    }
  };
  const {
    data: employeeDiciplinaryResponse,
    isLoading,
    isError: employeeDiciplinaryError,
  } = useQyGetEmployeeDiciplinaryById(
    employeeDiciplinaryId || "",
    handleGetApiSuccess
  );
  const employeeDiciplinaryData = employeeDiciplinaryResponse?.data?.[0];

  // API UPDATE EmployeeDiciplinary
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeDiciplinary updated successfully");
  };
  const { mutate: updateEmployeeDiciplinary, isLoading: isEditLoading } =
    useQyUpdateEmployeeDiciplinary(handleUpdateApiSuccess);

  // API DELETE EmployeeDiciplinary
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeDiciplinary deleted successfully");
  };
  const { mutate: deleteEmployeeDiciplinary } = useQyDeleteEmployeeDiciplinary(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeDiciplinaryFormSchema>({
    defaultValues: getDefault(employeeDiciplinaryData),
    resolver: zodResolver(EmployeeDiciplinaryFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeDiciplinaryFormSchema) => {
    if (!employeeDiciplinaryData || !employeeDiciplinaryData.code) {
      throw new Error("No employeeDiciplinary data");
    }

    const formData = mapUpdateEmployeeDiciplinary(
      form,
      employeeDiciplinaryData.code
    );
    updateEmployeeDiciplinary(formData);
  };
  const handleValidateError = (
    err: FieldErrors<EmployeeDiciplinaryFormSchema>
  ) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!employeeDiciplinaryData || !employeeDiciplinaryData.code) {
      throw new Error("No employee Disciplinary data found");
    }

    const form = mapDeleteEmployeeDiciplinary(employeeDiciplinaryData.code);
    deleteEmployeeDiciplinary(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    employeeDiciplinaryId,
    employeeDiciplinaryData,
    isLoading,
    employeeDiciplinaryError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
