import "./edit-employee-loan.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormEmployeeLoan from "../form-employee-loan/form-employee-loan";
import { useEditEmployeeLoan } from "./edit-employee-loan.hook";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

export function EditEmployeeLoan() {
  const {
    employeeLoanData,
    isLoading,
    employeeLoanError,
    formMethod,
    showDecision,
    selectedDecisionStatus,
    decisionReason,
    mappedStatus,
    handleSubmitDecision,
    setSelectedDecisionStatus,
    setDecisionReason,
    setShowDecision,
    getActionButtons,
    handleBack,
    handleAction,
  } = useEditEmployeeLoan();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormEmployeeLoan />
      </section>
    </div>
  );

  return (
    <div id="EmployeeLoan" className="employee-loan">
      <HeaderContent
        title={"Edit Employee Loan"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={getActionButtons()} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <Sidebar visible={showDecision} onHide={() => setShowDecision(false)}>
          <h2 className="mb-6">Decision</h2>

          <section className="flex flex-col gap-2">
            <Dropdown
              value={selectedDecisionStatus}
              onChange={(e) => setSelectedDecisionStatus(e.value)}
              options={mappedStatus}
              optionLabel="label"
              placeholder="Select decision status"
              className="w-full md:w-14rem"
            />
            <span>
              <label className="text-xs text-gray-500">Decision</label>
              <InputTextarea
                value={decisionReason}
                onChange={(e) => setDecisionReason(e.target.value)}
                rows={5}
                cols={30}
              />
            </span>
          </section>
          <Button label="Submit" onClick={(e) => handleSubmitDecision()} />
        </Sidebar>
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {employeeLoanError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditEmployeeLoan;
