import { LabelValue } from "@shared/models/label-value.interface";
import { useNavigate, useParams } from "react-router-dom";

export function useEditEmployee() {
  const { employeeId, branchId } = useParams();
  const navigate = useNavigate();
  const quickSummaryHoliday = {
    label: "Holiday left this year",
    value: 22,
    payload: 50,
  } as LabelValue<number>;
  const quickSummaryAbsent = {
    label: "Absent this year",
    value: 7,
    payload: 28,
  } as LabelValue<number>;
  const quickSummaryTardiness = {
    label: "Tardiness this year",
    value: 9,
    payload: 17,
  } as LabelValue<number>;
  const quickSummarySick = {
    label: "Sick Leave this year",
    value: 22,
    payload: 78,
  } as LabelValue<number>;

  const handleBack = () => {
    navigate(`../${branchId}`);
  };

  return {
    quickSummaryHoliday,
    quickSummaryAbsent,
    quickSummaryTardiness,
    quickSummarySick,
    employeeId,
    handleBack,
  };
}
