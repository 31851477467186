import { 
    MessageResponseDto, 
    GetPaymentMethodDto, 
    CreatePaymentMethodDto, 
    EditPaymentMethodDto,
    UtilPaymentMethodControllerGet200Response,
    UtilityPaymentMethodApiFp,
    DeletePaymentMethodDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponsePaymentMethodModel = UtilPaymentMethodControllerGet200Response;
export type GetPaymentMethodModel = GetPaymentMethodDto;
export type MutateResponsePaymentMethodModel = MessageResponseDto;
export type CreatePaymentMethodModel = CreatePaymentMethodDto;
export type UpdatePaymentMethodModel = EditPaymentMethodDto;
export type DeletePaymentMethodModel = DeletePaymentMethodDto;

export const apiPaymentMethodFp =  UtilityPaymentMethodApiFp(apiConfig);
export const getPaymentMethodController = apiPaymentMethodFp.utilPaymentMethodControllerGet;
export const createPaymentMethodController = apiPaymentMethodFp.utilPaymentMethodControllerCreate;
export const updatePaymentMethodController = apiPaymentMethodFp.utilPaymentMethodControllerEdit;
export const deletePaymentMethodController = apiPaymentMethodFp.utilPaymentMethodControllerDelete;

export const QueryKeyPaymentMethod = QueryKey.PaymentMethod;