import { EditStatusDto } from "@api/api";
import { statusFormDefault } from "./form.default";
import { StatusFormSchema } from "./form.rule";

export const useFormDefaultStatus = () => {
  const getDefault = (cachedValue: EditStatusDto | undefined) => {
    return !cachedValue
      ? statusFormDefault
     : ({
				name: cachedValue.name,
				description: cachedValue.description,
      } as StatusFormSchema);
  };

  return {
    getDefault,
  };
};
