import { Outlet } from "react-router-dom";

import { FilterEmployeePenaltyProvider } from "./filter-employee-penalty/filter-employee-penalty.context";
import './employee-penalty.scss';

export function EmployeePenalty() {
  return (
    <div id="EmployeePenalty" className="employee-penalty">
       <FilterEmployeePenaltyProvider>
        <Outlet />
      </FilterEmployeePenaltyProvider>
    </div>
  );
}

export default EmployeePenalty;
