import './form-department.scss';
import { useFormContext } from "react-hook-form";
import { DepartmentFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = DepartmentFormSchema;
export function Department() {
  const { control } = useFormContext<DepartmentFormSchema>();
  return (
    <div id="Department" className="department">
      <InputControl<InputFormSchema>
        control={control}
        name="name"
        label="Name"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter name"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="head"
        label="Head"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter head"
      />
    </div>
  );
}

export default Department;