import './new-holiday.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateHoliday } from "@core/query/holiday.query";
import { HolidayFormRule, HolidayFormSchema } from "@core/form/form.rule";
import { holidayFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormHoliday from "../form-holiday/form-holiday";

export function Holiday() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateHoliday } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New Holiday is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addHoliday, isLoading: isCreating } =
    useQyCreateHoliday(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<HolidayFormSchema>({
    defaultValues: holidayFormDefault,
    resolver: zodResolver(HolidayFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: HolidayFormSchema) => {
    const formData = mapCreateHoliday(form);
    addHoliday(formData);
  };
  const handleValidateError = (err: FieldErrors<HolidayFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="Holiday" className="holiday">
      <HeaderContent
        title="New Holiday"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormHoliday />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default Holiday;