import { ReactNode, createContext, useContext } from "react";
import { useFilterFavorite } from "./filter-favorite.hook";

const FilterFavoriteContext = createContext(
  {} as ReturnType<typeof useFilterFavorite>
);
export function useFilterFavoriteContext() {
  return useContext(FilterFavoriteContext);
}

interface FilterFavoriteProviderProps {
  children: ReactNode;
}
export function FilterFavoriteProvider({
  children,
}: FilterFavoriteProviderProps) {
  return (
    <FilterFavoriteContext.Provider value={useFilterFavorite()}>
      {children}
    </FilterFavoriteContext.Provider>
  );
}