import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownIncidentType } from "./form-dropdown-incident-type.hook";

const FormDropdownIncidentTypeContext = createContext(
  {} as ReturnType<typeof useFormDropdownIncidentType>
);
export function useFormDropdownIncidentTypeContext() {
  return useContext(FormDropdownIncidentTypeContext);
}

interface FormDropdownIncidentTypeProviderProps {
  children: ReactNode;
}
export function FormDropdownIncidentTypeProvider({
  children,
}: FormDropdownIncidentTypeProviderProps) {
  return (
    <FormDropdownIncidentTypeContext.Provider value={useFormDropdownIncidentType()}>
      {children}
    </FormDropdownIncidentTypeContext.Provider>
  );
}
