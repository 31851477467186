import InputControl from "@shared/ui/hook-form/input-control/input-control";
import "./form-person-employment.scss";
import { PersonFormSchema } from "@core/form/form.rule";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import { useFormContext } from "react-hook-form";
import FormDropdownJobTitle from "@domain/job-title/form-dropdown-job-title/form-dropdown-job-title";
import FormDropdownJobGrade from "@domain/job-grade/form-dropdown-job-grade/form-dropdown-job-grade";
import FormDropdownEmploymentType from "@domain/employment-type/form-dropdown-employment-type/form-dropdown-employment-type";
import FormDropdownDepartment from "@domain/department/form-dropdown-department/form-dropdown-department";
import FormDropdownPerson from "../form-dropdown-person/form-dropdown-person";
import FormDropdownRole from "@domain/role/form-dropdown-role/form-dropdown-role";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import { useUserIdentityContext } from "@core/services/user-identity.context";

type InputFormSchema = PersonFormSchema;
export function FormPersonEmployment() {
  const { userIsOwner } = useUserIdentityContext();
  const { control } = useFormContext<InputFormSchema>();
  return (
    <div id="FormPersonEmployment" className="form-person-employment">
      {userIsOwner() && (
        <FormDropdownRole<InputFormSchema> name="bucket.role" />
      )}
      <InputControl<InputFormSchema>
        control={control}
        name="bucket.employeeNo"
        label="Employee Number"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter employee number"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="bucket.signedDate"
        label="SignedDate"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="bucket.startDate"
        label="StartDate"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="bucket.endDate"
        label="EndDate"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <FormDropdownJobTitle<InputFormSchema> name="bucket.jobTitle" />
      <FormDropdownJobGrade<InputFormSchema> name="bucket.jobGrade" />
      <FormDropdownEmploymentType<InputFormSchema> name="bucket.employmentType" />
      <FormDropdownDepartment<InputFormSchema> name="bucket.department" />
      {/* <FormDropdownPerson<InputFormSchema> name="bucket.reportingTo" /> */}
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="bucket.offboardReason"
        label="Offboard Reason"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter offboardReason"
      />
      <InputDateControl<InputFormSchema>
        control={control}
        name="bucket.resignationDate"
        label="ResignationDate"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default FormPersonEmployment;
