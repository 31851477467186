import { 
    MessageResponseDto, 
    CreateShiftTypeDto, 
    EditShiftTypeDto,
    UtilShiftTypeControllerGet200Response,
    UtilityShiftTypeApiFp,
    DeleteShiftTypeDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseShiftTypeModel = UtilShiftTypeControllerGet200Response;
export type GetShiftTypeModel = EditShiftTypeDto;
export type MutateResponseShiftTypeModel = MessageResponseDto;
export type CreateShiftTypeModel = CreateShiftTypeDto;
export type UpdateShiftTypeModel = EditShiftTypeDto;
export type DeleteShiftTypeModel = DeleteShiftTypeDto;

export const apiShiftTypeFp =  UtilityShiftTypeApiFp(apiConfig);
export const getShiftTypeController = apiShiftTypeFp.utilShiftTypeControllerGet;
export const createShiftTypeController = apiShiftTypeFp.utilShiftTypeControllerCreate;
export const updateShiftTypeController = apiShiftTypeFp.utilShiftTypeControllerEdit;
export const deleteShiftTypeController = apiShiftTypeFp.utilShiftTypeControllerDelete;

export const QueryKeyShiftType = QueryKey.ShiftType;