import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetEmployeeIncidentModel } from "@core/model/query-model-employee-incident";
import { employeeIncidentFormDefault } from "./form.default";
import { EmployeeIncidentFormSchema } from "./form.rule";

export const useFormDefaultEmployeeIncident = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetEmployeeIncidentModel | undefined) => {
    return !cachedValue
      ? employeeIncidentFormDefault
      : ({
          userCode: cachedValue.user_code,
          bucketCode: cachedValue.bucket_code,
          branchCode: cachedValue.branch,
          effectivityDate: formatDate(cachedValue.effectivity_date) as any,
          incidentType: cachedValue.incident_type,
          paymentMethod: cachedValue.payment_method,
          amount: cachedValue.amount,
          balance: cachedValue.balance,
          isPaid: cachedValue.is_paid,
          remarks: cachedValue.remarks,
        } as EmployeeIncidentFormSchema);
  };

  return {
    getDefault,
  };
};
