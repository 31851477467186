export class LabelValue<T = string> {
  label: string;
  value: T;
  payload?: any;

  constructor(label: string, value: T, payload?: any) {
    this.label = label;
    this.value = value;
    this.payload = payload;
  }

  static Map<T = string>(label: string, value: T, payload?: any) {
    return new LabelValue(label, value, payload);
  }

  static MapArray<R = any>(
    entities: R[],
    labelFunc: (item: R) => any,
    valueFunc: (item: R) => any
  ) {
    return entities.map(
      (item) => new LabelValue(labelFunc(item), valueFunc(item))
    );
  }

  static TransformAsRecord(
    record: Record<string, LabelValue>
  ): Record<string, string> {
    const transformed: Record<string, string> = {};

    for (const key in record) {
      if (record.hasOwnProperty(key)) {
        transformed[key] = record[key].value;
      }
    }

    return transformed;
  }
}
