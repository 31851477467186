import classNames from "classnames";
import "./list-item.scss";
import { ReactNode } from "react";

export interface ListItemProps {
  children: ReactNode;
  className?: string;
}

export function ListItem({ children, className }: ListItemProps) {
  return (
    <div
      id="ListItem"
      className={classNames(
        "flex justify-between items-center p-4 mb-2 bg-gray-200 hover:bg-gray-300 rounded",
        className
      )}
    >
      {children}
    </div>
  );
}

export default ListItem;
