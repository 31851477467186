import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  HolidayTypeFormRule,
  HolidayTypeFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultHolidayType } from "@core/form/form-default-holiday-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateHolidayType,
  useQyGetHolidayTypeById,
  useQyDeleteHolidayType,
} from "@core/query/holiday-type.query";
import { GetResponseHolidayTypeModel } from "@core/model/query-model-holiday-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";

export function useEditHolidayType() {
  // LOCAL DECLARATION
  const { getDefault } = useFormDefaultHolidayType();
  const navigate = useNavigate();
  const { mapUpdateHolidayType, mapDeleteHolidayType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { holidayTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID HolidayType
  const handleGetApiSuccess = (data: GetResponseHolidayTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
      setValue("percentage", responseData?.percentage || undefined);
    }
  };
  const {
    data: holidayTypeResponse,
    isLoading,
    isError: holidayTypeError,
  } = useQyGetHolidayTypeById(holidayTypeId || "", handleGetApiSuccess);
  const holidayTypeData = holidayTypeResponse?.data?.[0];

  // API UPDATE HolidayType
  const handleUpdateApiSuccess = () => {
    showSuccess("HolidayType updated successfully");
  };
  const { mutate: updateHolidayType, isLoading: isEditLoading } =
    useQyUpdateHolidayType(handleUpdateApiSuccess);

  // API DELETE HolidayType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("HolidayType deleted successfully");
  };
  const { mutate: deleteHolidayType } = useQyDeleteHolidayType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<HolidayTypeFormSchema>({
    defaultValues: getDefault(holidayTypeData),
    resolver: zodResolver(HolidayTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: HolidayTypeFormSchema) => {
    if (!holidayTypeData) {
      throw new Error("No holidayType data");
    }

    const formData = mapUpdateHolidayType(form, holidayTypeData.code);
    updateHolidayType(formData);
  };
  const handleValidateError = (err: FieldErrors<HolidayTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!holidayTypeData) {
      throw new Error("No holidayType data found");
    }

    const form = mapDeleteHolidayType(holidayTypeData.code);
    deleteHolidayType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    holidayTypeId,
    holidayTypeData,
    isLoading,
    holidayTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
