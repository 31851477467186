import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./quick-view-date.scss";
import { Sidebar } from "primereact/sidebar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQyGetDailyTimeShiftByBranch } from "@core/query/daily-time-record.query";
import {
  GetResponseShiftDataModel,
  ShiftResponseModel,
} from "@core/model/query-model-daily-time-record";
import {  useCalendarEvent } from "../calendar-helper.hook";
import { useState } from "react";
import { CiClock1 } from "react-icons/ci";
import { CalendarEvent } from "@core/model/calendar-event.interface";

export interface QuickViewDateProps {}

export function QuickViewDate({}: QuickViewDateProps) {
  const { calendarMap } = useCalendarEvent();
  let [searchParams] = useSearchParams();
  const [eventData, setEventData] = useState<CalendarEvent[]>([]);
  const dateParam = searchParams.get("date") || "";
  const branchParam = searchParams.get("branch") || "";
  const { formatDate, formatTime } = useDateTimeContext();
  const navigate = useNavigate();

  // QUERIES
  const handleShiftSuccess = (response: ShiftResponseModel) => {
    const mappedEvent = (response.data || [])
      .filter((item: GetResponseShiftDataModel) => !!item.shift_code)
      .map((item: GetResponseShiftDataModel) => {
        const mappedData = calendarMap(item);
        return {
          ...mappedData,
          extendedProps: {
            ...mappedData.extendedProps,
            branchId: branchParam,
          },
        };
      });

    setEventData(mappedEvent);
  };
  useQyGetDailyTimeShiftByBranch(
    formatDate(dateParam),
    formatDate(dateParam),
    branchParam,
    !!dateParam && !!branchParam,
    handleShiftSuccess
  );

  // LOCAL FUNCTION
  const handleView = (employee: CalendarEvent) => {
    console.log({ employee });
    navigate(
      `/employee/${employee?.extendedProps?.branchId}/${employee?.extendedProps?.userId}?return=event`
    );
  };
  return (
    <Sidebar visible={true} onHide={() => navigate("../")}>
      <div id="QuickViewDate" className="quick-view-date">
        <h2>{formatDate(dateParam)}</h2>
        <Accordion activeIndex={0} className="mt-4 block">
          <AccordionTab header="Employees">
            <ul className="m-0">
              {eventData.map((emp) => (
                <li
                  key={emp.id}
                  className="cursor-pointer flex gap-4 items-center p-2 rounded-lg hover:bg-gray-200"
                  onClick={() => handleView(emp)}
                >
                  <CiClock1 />
                  <section className="flex flex-col">
                    <h5>{emp.title}</h5>
                    <span>
                      {formatTime(emp.start)} - {formatTime(emp.end)}
                    </span>
                  </section>
                </li>
              ))}
            </ul>
          </AccordionTab>
        </Accordion>
      </div>
    </Sidebar>
  );
}

export default QuickViewDate;
