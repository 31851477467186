import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { IncidentTypeFormRule, IncidentTypeFormSchema } from "@core/form/form.rule";
import { useFormDefaultIncidentType } from "@core/form/form-default-incident-type.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateIncidentType, 
  useQyGetIncidentTypeById, 
  useQyDeleteIncidentType 
} from "@core/query/incident-type.query";
import { GetResponseIncidentTypeModel } from "@core/model/query-model-incident-type";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditIncidentType() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultIncidentType();
  const navigate = useNavigate();
  const { mapUpdateIncidentType, mapDeleteIncidentType } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { incidentTypeId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID IncidentType
  const handleGetApiSuccess = (data: GetResponseIncidentTypeModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: incidentTypeResponse,
    isLoading,
    isError: incidentTypeError,
  } = useQyGetIncidentTypeById(incidentTypeId || "", handleGetApiSuccess);
  const incidentTypeData = incidentTypeResponse?.data?.[0];

  // API UPDATE IncidentType
  const handleUpdateApiSuccess = () => {
    showSuccess("IncidentType updated successfully");
  };
  const { mutate: updateIncidentType, isLoading: isEditLoading } = useQyUpdateIncidentType(
    handleUpdateApiSuccess
  );

  // API DELETE IncidentType
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("IncidentType deleted successfully");
  };
  const { mutate: deleteIncidentType } = useQyDeleteIncidentType(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<IncidentTypeFormSchema>({
    defaultValues: getDefault(incidentTypeData),
    resolver: zodResolver(IncidentTypeFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: IncidentTypeFormSchema) => {
    if (!incidentTypeData) {
      throw new Error("No incidentType data");
    }

    const formData = mapUpdateIncidentType(form, incidentTypeData.code);
    updateIncidentType(formData);
  };
  const handleValidateError = (err: FieldErrors<IncidentTypeFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!incidentTypeData) {
      throw new Error("No incidentType data found");
    }

    const form = mapDeleteIncidentType(incidentTypeData.code);
    deleteIncidentType(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    incidentTypeId,
    incidentTypeData,
    isLoading,
    incidentTypeError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}