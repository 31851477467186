import { Outlet } from "react-router-dom";

import { FilterIncidentTypeProvider } from "./filter-incident-type/filter-incident-type.context";
import './incident-type.scss';

export function IncidentType() {
  return (
    <div id="IncidentType" className="incident-type">
       <FilterIncidentTypeProvider>
        <Outlet />
      </FilterIncidentTypeProvider>
    </div>
  );
}

export default IncidentType;
