import "./form-shift.scss";
import { useFormContext } from "react-hook-form";
import { ShiftFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import FormDropdownShiftType from "@domain/shift-type/form-dropdown-shift-type/form-dropdown-shift-type";
import { useCallback } from "react";
import { calculateDurationInMinutes } from "@core/utility/get-time-difference";
import { InputNumber } from "primereact/inputnumber";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = ShiftFormSchema;
export function Shift() {
  const { control, watch, setValue } = useFormContext<ShiftFormSchema>();
  const startTime = watch("start");
  const endTime = watch("end");
  const isOpen = watch("isOpen");

  const getWorkDay = useCallback(() => {
    const timeDifference =
      startTime && endTime ? calculateDurationInMinutes(startTime, endTime) : 0;

    if (timeDifference > 0) {
      setValue("workHours", timeDifference);
    }

    return timeDifference;
  }, [startTime, endTime]);

  return (
    <div id="Shift" className="shift">
      <FormDropdownShiftType<InputFormSchema> name="type" />
      <InputControl<InputFormSchema>
        control={control}
        name="title"
        label="Title"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter shift title"
      />
      <div className="flex gap-1">
        <InputDateControl<InputFormSchema>
          control={control}
          type="time"
          name="start"
          label="Start"
          className="w-full"
          containerClassName="pb-2"
        />
      </div>
      <div className="flex gap-1">
        <InputDateControl<InputFormSchema>
          control={control}
          type="time"
          name="end"
          label="End"
          className="w-full"
          containerClassName="pb-2"
        />
      </div>

      <div className="field mt-5 pb-2">
        <span className="p-float-label">
          <InputNumber value={getWorkDay()} disabled={true} />
          <label>Work Hours (In Minutes)</label>
        </span>
      </div>

      <InputDigitControl<InputFormSchema>
        control={control}
        name="halfDay"
        label="Half Day (In Minutes)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter halfDay"
      />
      <InputDigitControl<InputFormSchema>
        control={control}
        name="gracePeriod"
        label="Grace Period (In Minutes)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter gracePeriod"
      />

      <CheckboxControl<InputFormSchema>
        control={control}
        label="Is Open Break"
        name="isOpen"
        containerClassName="pb-2"
      />
      
      {isOpen ? (
        <InputDigitControl<InputFormSchema>
          control={control}
          name="break"
          label="Open Break Time (Minutes)"
          className="w-full md:w-3/4"
          containerClassName="pb-2"
          placeholder="Enter open break time in minutes"
        />
      ) : (
        <>
          <InputDateControl<InputFormSchema>
            control={control}
            type="time"
            name="breakStart"
            label="Break Start"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter break start"
          />
          <InputDateControl<InputFormSchema>
            control={control}
            type="time"
            name="breakEnd"
            label="Break End"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter break end"
          />
        </>
      )}
    </div>
  );
}

export default Shift;
