import { ReactNode, createContext, useContext } from "react";
import { useFilterEmploymentType } from "./filter-employment-type.hook";

const FilterEmploymentTypeContext = createContext(
  {} as ReturnType<typeof useFilterEmploymentType>
);
export function useFilterEmploymentTypeContext() {
  return useContext(FilterEmploymentTypeContext);
}

interface FilterEmploymentTypeProviderProps {
  children: ReactNode;
}
export function FilterEmploymentTypeProvider({
  children,
}: FilterEmploymentTypeProviderProps) {
  return (
    <FilterEmploymentTypeContext.Provider value={useFilterEmploymentType()}>
      {children}
    </FilterEmploymentTypeContext.Provider>
  );
}