import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { StatusFormRule, StatusFormSchema } from "@core/form/form.rule";
import { useFormDefaultStatus } from "@core/form/form-default-status.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateStatus, 
  useQyGetStatusById, 
  useQyDeleteStatus 
} from "@core/query/status.query";
import { GetResponseStatusModel } from "@core/model/query-model-status";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";

export function useEditStatus() {
  // LOCAL DECLARATION
  const { getDefault } = useFormDefaultStatus();
  const navigate = useNavigate();
  const { mapUpdateStatus, mapDeleteStatus } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { statusId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Status
  const handleGetApiSuccess = (data: GetResponseStatusModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: statusResponse,
    isLoading,
    isError: statusError,
  } = useQyGetStatusById(statusId || "", handleGetApiSuccess);
  const statusData = statusResponse?.data?.[0];

  // API UPDATE Status
  const handleUpdateApiSuccess = () => {
    showSuccess("Status updated successfully");
  };
  const { mutate: updateStatus, isLoading: isEditLoading } = useQyUpdateStatus(
    handleUpdateApiSuccess
  );

  // API DELETE Status
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Status deleted successfully");
  };
  const { mutate: deleteStatus } = useQyDeleteStatus(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<StatusFormSchema>({
    defaultValues: getDefault(statusData),
    resolver: zodResolver(StatusFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: StatusFormSchema) => {
    if (!statusData) {
      throw new Error("No status data");
    }

    const formData = mapUpdateStatus(form, statusData.code);
    updateStatus(formData);
  };
  const handleValidateError = (err: FieldErrors<StatusFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!statusData) {
      throw new Error("No status data found");
    }

    const form = mapDeleteStatus(statusData.code);
    deleteStatus(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    statusId,
    statusData,
    isLoading,
    statusError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}