import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  EmployeeRequestFormRule,
  EmployeeRequestFormSchema,
} from "@core/form/form.rule";
import { useFormDefaultEmployeeRequest } from "@core/form/form-default-employee-request.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateEmployeeRequest,
  useQyGetEmployeeRequestById,
  useQyDeleteEmployeeRequest,
  useQyProcessEmployeeRequest,
} from "@core/query/employee-request.query";
import { GetResponseEmployeeRequestModel } from "@core/model/query-model-employee-request";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useFormDropdownRequestType } from "@domain/request-type/form-dropdown-request-type/form-dropdown-request-type.hook";
import { useState } from "react";
import { useFormDropdownStatusContext } from "@domain/status/form-dropdown-status/form-dropdown-status.context";
import { useQyGetGoogleCloudAssetPreview } from "@core/query/google-cloud-storage.query";
import { useUserIdentityContext } from "@core/services/user-identity.context";

export function useEditEmployeeRequest() {
  // LOCAL DECLARATION
  const { userIsAdmin } = useUserIdentityContext();
  const { mappedStatus } = useFormDropdownStatusContext();
  const { getDesignationMapping, getDesignatedRequestTypeEnumValue } =
    useFormDropdownRequestType();
  const { formatDate, formatDateTimeForInputMeridian, formatDateTimeV2 } =
    useDateTimeContext();
  const { getDefault } = useFormDefaultEmployeeRequest();
  const navigate = useNavigate();
  const { mapUpdateEmployeeRequest, mapDeleteEmployeeRequest } =
    useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { employeeRequestId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Process", type: "primary", value: ActionEnum.Process },
    { label: "Delete", type: "danger", value: ActionEnum.Delete },
  ];
  const designatedRequestType = getDesignationMapping();
  const [showDecision, setShowDecision] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [decisionReason, setDecisionReason] = useState("");

  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // QUERY DECLARATION
  // API GETBYID EmployeeRequest
  const handleGetApiSuccess = (data: GetResponseEmployeeRequestModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("bucketCode", responseData?.bucket_code || "");
      setValue("requestType", responseData?.request_type || "");
      setValue("requestReason", responseData?.request_reason || "");
      setValue(
        "effectivityDate",
        formatDate(responseData?.effectivity_date) as any,
        undefined
      );
      setValue("leaveType", responseData?.leave_type || "");
      setValue("leaveCategory", responseData?.leave_category || "");
      setValue("leaveSubcategory", responseData?.leave_subcategory || "");

      const amendCurrentClockinMeridian = formatDateTimeForInputMeridian(
        responseData?.amend_current_clockin
      );
      const amendCurrentClockOutMeridian = formatDateTimeForInputMeridian(
        responseData?.amend_current_clockout
      );
      const amendClockinMeridian = formatDateTimeForInputMeridian(
        responseData?.amend_clockin
      );
      const amendClockOutMeridian = formatDateTimeForInputMeridian(
        responseData?.amend_clockout
      );

      setValue("amendCurrentClockin", amendCurrentClockinMeridian);
      setValue("amendCurrentClockout", amendCurrentClockOutMeridian);
      setValue("amendClockin", amendClockinMeridian);
      setValue("amendClockout", amendClockOutMeridian);
      setValue("workDate", formatDate(responseData?.amend_clockin));

      setValue(
        "shiftCurrentSchedule",
        responseData?.shift_current_schedule || ""
      );
      setValue("shiftSchedule", responseData?.shift_schedule || "");
      setValue("isPermanent", Boolean(responseData?.is_permanent));
      setValue("shiftDay", responseData?.shift_day || "");
      setValue("endDate", formatDate(responseData?.end_date) as any, undefined);
      setValue("otTime", responseData?.ot_time);
      setValue("status", responseData?.status || "");
      setValue(
        "amendBreakStart",
        formatDateTimeForInputMeridian(
          (responseData as any)?.amend_break_start || ""
        )
      );
      setValue(
        "amendBreakEnd",
        formatDateTimeForInputMeridian(
          (responseData as any)?.amend_break_end || ""
        )
      );
      setValue(
        "isBreakPermission",
        Boolean((responseData as any)?.is_break_permision)
      );

      const attachment = responseData?.attachments || [];
      if (attachment.length > 0 && attachment[0].file) {
        viewAsset(attachment[0].file, {
          onSuccess(data) {
            const asset = URL.createObjectURL(data);
            setValue("file", asset);
          },
        });
      }
    }
  };
  const {
    data: employeeRequestResponse,
    isLoading,
    isError: employeeRequestError,
  } = useQyGetEmployeeRequestById(employeeRequestId || "", handleGetApiSuccess);
  const employeeRequestData = employeeRequestResponse?.data?.[0];
  const selectedRequestTypeName = getDesignatedRequestTypeEnumValue(
    designatedRequestType,
    employeeRequestData?.request_type || ""
  );

  // API UPDATE EmployeeRequest
  const handleUpdateApiSuccess = () => {
    showSuccess("EmployeeRequest updated successfully");
  };
  const { mutate: updateEmployeeRequest, isLoading: isEditLoading } =
    useQyUpdateEmployeeRequest(handleUpdateApiSuccess);

  // API DELETE EmployeeRequest
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeRequest deleted successfully");
  };
  const { mutate: deleteEmployeeRequest } = useQyDeleteEmployeeRequest(
    handleDeleteApiSuccess
  );

  // API PROCESS EmployeeRequest
  const handleProcessApiSuccess = () => {
    handleBack();
    showSuccess("EmployeeRequest processed successfully");
  };
  const { mutate: processEmployeeRequest } = useQyProcessEmployeeRequest(
    handleProcessApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeRequestFormSchema>({
    defaultValues: getDefault(employeeRequestData),
    resolver: zodResolver(EmployeeRequestFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: EmployeeRequestFormSchema) => {
    const code = employeeRequestData?.code;
    if (!employeeRequestData || !code) {
      throw new Error("No employeeRequest data");
    }

    const formData = mapUpdateEmployeeRequest(form, code);
    updateEmployeeRequest(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeRequestFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    const code = employeeRequestData?.code;
    if (!employeeRequestData || !code) {
      throw new Error("No employeeRequest data");
    }

    const form = mapDeleteEmployeeRequest(code);
    deleteEmployeeRequest(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Process:
        setShowDecision(true);
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };
  const handleSubmitDecision = () => {
    const _code = employeeRequestData?.code;
    if (!_code) return;

    processEmployeeRequest({
      code: _code,
      status: selectedStatus,
      reason: decisionReason,
    });
  };

  return {
    employeeRequestId,
    employeeRequestData,
    isLoading,
    employeeRequestError,
    formMethod,
    actions,
    isEditLoading,
    selectedRequestTypeName,
    showDecision,
    mappedStatus,
    selectedStatus,
    decisionReason,
    setDecisionReason,
    handleSubmitDecision,
    setSelectedStatus,
    setShowDecision,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
    userIsAdmin,
    formatDateTimeV2,
  };
}
