
import { useMutation, useQuery, useQueryClient } from "react-query";
import { authHeaders } from "./auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import {
  CreateShiftModel,
  DeleteShiftModel,
  GetResponseShiftModel,
  MutateResponseShiftModel,
  QueryKeyShift,
  UpdateShiftModel,
  deleteShiftController,
  createShiftController,
  getShiftController,
  updateShiftController,
} from "@core/model/query-model-shift";
import { SETTINGS } from "@core/utility/settings";
import { useSessionContext } from "./session.context";

export function useQyGetShift(
  search: string,
  limit = 9999,
  offset = 0,
  order?: object,
  filter?: Record<string, string>,
  dateFilter?: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
  onSuccess?:
    | ((data: GetResponseShiftModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (
    search: string | undefined = undefined,
    limit: number | undefined = undefined,
    offset: number | undefined = undefined,
    order: object | undefined = undefined,
    filter: Record<string, string> | undefined = undefined,
    dateFilter: string | undefined = undefined,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ) => {
    await checkSession();

    showProgress();
    const operation = await getShiftController(
      search,
      limit,
      offset,
      order,
      { filter: JSON.stringify(filter) },
      dateFilter,
      startDate,
      endDate,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyShift]);
    return response["data"] as GetResponseShiftModel;
  };

  return useQuery({
    staleTime: SETTINGS.staleTime,
    enabled,
    queryKey: [
      QueryKeyShift, 
      search,
      limit,
      offset,
      order,
      filter,
      dateFilter,
      startDate,
      endDate
    ],
    queryFn: () => 
      apiFn(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate
      ),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetShiftById(
  id: string,
  onSuccess?:
    | ((data: GetResponseShiftModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (id: string, search = "", limit = 1, offset = 0) => {
    await checkSession();

    showProgress();
    const operation = await getShiftController(
      search,
      limit,
      offset,
      undefined,      
      { filter: JSON.stringify({ code: id }) },
      undefined,
      undefined,
      undefined,
      authHeaders()
    );
    const response = (await operation()).data;
    console.info("Query", [QueryKeyShift, id]);
    return response["data"] as GetResponseShiftModel;
  };

  return useQuery({
    queryKey: [QueryKeyShift, id],
    queryFn: () => apiFn(id),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyCreateShift(
  onSuccess?:
    | ((data: MutateResponseShiftModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: CreateShiftModel) => {
    await checkSession();

    showProgress();
    const operation = await createShiftController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response as MutateResponseShiftModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyShift);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyUpdateShift(
  onSuccess?:
    | ((data: MutateResponseShiftModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: UpdateShiftModel) => {
    await checkSession();

    showProgress();
    const operation = await updateShiftController(
      payload,
      authHeaders()
    );
    const response = (await operation()).data;
    return response["message"] as MutateResponseShiftModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyShift);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyDeleteShift(
  onSuccess?:
    | ((data: MutateResponseShiftModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: DeleteShiftModel) => {
    await checkSession();

    showProgress();
    const operation = await deleteShiftController(payload, authHeaders());
    const response = (await operation()).data;
    return response["message"] as MutateResponseShiftModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyShift);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
