import { Outlet } from "react-router-dom";

import { FilterJobTitleProvider } from "./filter-job-title/filter-job-title.context";
import './job-title.scss';

export function JobTitle() {
  return (
    <div id="JobTitle" className="job-title">
       <FilterJobTitleProvider>
        <Outlet />
      </FilterJobTitleProvider>
    </div>
  );
}

export default JobTitle;
