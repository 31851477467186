import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownJobTitle } from "./form-dropdown-job-title.hook";

const FormDropdownJobTitleContext = createContext(
  {} as ReturnType<typeof useFormDropdownJobTitle>
);
export function useFormDropdownJobTitleContext() {
  return useContext(FormDropdownJobTitleContext);
}

interface FormDropdownJobTitleProviderProps {
  children: ReactNode;
}
export function FormDropdownJobTitleProvider({
  children,
}: FormDropdownJobTitleProviderProps) {
  return (
    <FormDropdownJobTitleContext.Provider value={useFormDropdownJobTitle()}>
      {children}
    </FormDropdownJobTitleContext.Provider>
  );
}
