import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownSalaryType } from "./form-dropdown-salary-type.hook";

const FormDropdownSalaryTypeContext = createContext(
  {} as ReturnType<typeof useFormDropdownSalaryType>
);
export function useFormDropdownSalaryTypeContext() {
  return useContext(FormDropdownSalaryTypeContext);
}

interface FormDropdownSalaryTypeProviderProps {
  children: ReactNode;
}
export function FormDropdownSalaryTypeProvider({
  children,
}: FormDropdownSalaryTypeProviderProps) {
  return (
    <FormDropdownSalaryTypeContext.Provider value={useFormDropdownSalaryType()}>
      {children}
    </FormDropdownSalaryTypeContext.Provider>
  );
}
