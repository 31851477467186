import { ReactNode, createContext, useContext } from "react";
import { useFilterDeviceAuthorization } from "./filter-device-authorization.hook";

const FilterDeviceAuthorizationContext = createContext(
  {} as ReturnType<typeof useFilterDeviceAuthorization>
);
export function useFilterDeviceAuthorizationContext() {
  return useContext(FilterDeviceAuthorizationContext);
}

interface FilterDeviceAuthorizationProviderProps {
  children: ReactNode;
}
export function FilterDeviceAuthorizationProvider({
  children,
}: FilterDeviceAuthorizationProviderProps) {
  return (
    <FilterDeviceAuthorizationContext.Provider value={useFilterDeviceAuthorization()}>
      {children}
    </FilterDeviceAuthorizationContext.Provider>
  );
}