import { ReactNode, createContext, useContext } from "react";
import { useFormDropdownEmployeeIncident } from "./form-dropdown-employee-incident.hook";

const FormDropdownEmployeeIncidentContext = createContext(
  {} as ReturnType<typeof useFormDropdownEmployeeIncident>
);
export function useFormDropdownEmployeeIncidentContext() {
  return useContext(FormDropdownEmployeeIncidentContext);
}

interface FormDropdownEmployeeIncidentProviderProps {
  children: ReactNode;
  userCode?: string;
}
export function FormDropdownEmployeeIncidentProvider({
  children,
  userCode,
}: FormDropdownEmployeeIncidentProviderProps) {
  return (
    <FormDropdownEmployeeIncidentContext.Provider
      value={useFormDropdownEmployeeIncident({ userCode })}
    >
      {children}
    </FormDropdownEmployeeIncidentContext.Provider>
  );
}
