import './new-employee-deduction.scss';

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeDeduction } from "@core/query/employee-deduction.query";
import { EmployeeDeductionFormRule, EmployeeDeductionFormSchema } from "@core/form/form.rule";
import { employeeDeductionFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeDeduction from "../form-employee-deduction/form-employee-deduction";
import { useFormDropdownPersonContext } from '@domain/person/form-dropdown-person/form-dropdown-person.context';

export function EmployeeDeduction() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { mapCreateEmployeeDeduction } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New EmployeeDeduction is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addEmployeeDeduction, isLoading: isCreating } =
    useQyCreateEmployeeDeduction(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeDeductionFormSchema>({
    defaultValues: employeeDeductionFormDefault,
    resolver: zodResolver(EmployeeDeductionFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeDeductionFormSchema) => {
    const mutatedForm = {
      ...form,
      userCode: selectedPersonData?.code,
      bucketCode: selectedPersonData?.bucket.code,
    } as EmployeeDeductionFormSchema;
    const formData = mapCreateEmployeeDeduction(mutatedForm);
    addEmployeeDeduction(formData);
  };
  const handleValidateError = (err: FieldErrors<EmployeeDeductionFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return (
    <div id="EmployeeDeduction" className="employee-deduction">
      <HeaderContent
        title="New Employee Deduction"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormEmployeeDeduction />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default EmployeeDeduction;