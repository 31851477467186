import { Outlet } from "react-router-dom";

import { FilterEmployeeFavoriteProvider } from "./filter-employee-favorite/filter-employee-favorite.context";
import "./employee-favorite.scss";
import { FormDropdownFavoriteProvider } from "./form-dropdown-favorite/form-dropdown-favorite.context";

export function EmployeeFavorite() {
  return (
    <div id="EmployeeFavorite" className="employee-favorite">
      <FormDropdownFavoriteProvider>
        <FilterEmployeeFavoriteProvider>
          <Outlet />
        </FilterEmployeeFavoriteProvider>
      </FormDropdownFavoriteProvider>
    </div>
  );
}

export default EmployeeFavorite;
