import { Outlet } from "react-router-dom";

import { FilterAttachmentTypeProvider } from "./filter-attachment-type/filter-attachment-type.context";
import './attachment-type.scss';

export function AttachmentType() {
  return (
    <div id="AttachmentType" className="attachment-type">
       <FilterAttachmentTypeProvider>
        <Outlet />
      </FilterAttachmentTypeProvider>
    </div>
  );
}

export default AttachmentType;
