import {
  MessageResponseDto,
  GetEmployeeShiftDto,
  EditEmployeeShiftDto,
  EmployeeShiftControllerGet200Response,
  EmployeeShiftApiFp,
  DeleteEmployeeShiftDto,
  AddEmployeeShiftDto,
} from "@api/api";
import { apiConfig } from "@core/query/config.query";
import { QueryKey } from "@core/query/query-key.enum";

export type GetResponseEmployeeShiftModel =
  EmployeeShiftControllerGet200Response;
export type GetEmployeeShiftModel = GetEmployeeShiftDto;
export type MutateResponseEmployeeShiftModel = MessageResponseDto;
export type CreateEmployeeShiftModel = AddEmployeeShiftDto;
export type UpdateEmployeeShiftModel = EditEmployeeShiftDto;
export type DeleteEmployeeShiftModel = DeleteEmployeeShiftDto;

export const apiEmployeeShiftFp = EmployeeShiftApiFp(apiConfig);
export const getEmployeeShiftController =
  apiEmployeeShiftFp.employeeShiftControllerGet;
export const createEmployeeShiftController =
  apiEmployeeShiftFp.employeeShiftControllerCreate;
export const updateEmployeeShiftController =
  apiEmployeeShiftFp.employeeShiftControllerEdit;
export const deleteEmployeeShiftController =
  apiEmployeeShiftFp.employeeShiftControllerDelete;

export const QueryKeyEmployeeShift = QueryKey.EmployeeShift;
