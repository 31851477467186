import { Outlet } from "react-router-dom";
import "./holiday.scss";
import { FilterHolidayProvider } from "./filter-holiday/filter-holiday.context";
import { FormDropdownHolidayTypeProvider } from "@domain/holiday-type/form-dropdown-holiday-type/form-dropdown-holiday-type.context";

export function Holiday() {
  return (
    <div id="Holiday" className="holiday">
      <FormDropdownHolidayTypeProvider>
        <FilterHolidayProvider>
          <Outlet />
        </FilterHolidayProvider>
      </FormDropdownHolidayTypeProvider>
    </div>
  );
}

export default Holiday;
