import { getSeverity } from "@core/utility/get-severity";
import { LabelValue } from "@shared/models/label-value.interface";
import TextBlock from "@shared/ui/text-block/text-block";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { displayTag } from "@shared/utility/table-helper/display-column";
import { TableElement } from "@shared/utility/table-helper/table-element.enum";

export function useListEmployeeRequestColumn(isAll: boolean = false) {
  const { formatDate, formatDateTimeV2 } = useDateTimeContext();

  const leaveColumns = [
    { value: "effectivity_date", label: "Date", payload: TableElement.Date },
    { value: "branch_name", label: "Branch", payload: TableElement.Text },
    { value: "leave_type_name", label: "Type", payload: TableElement.Text },
    {
      value: "leave_category_name",
      label: "Category",
      payload: TableElement.Text,
    },
    {
      value: "leave_subcategory_name",
      label: "Subcategory",
      payload: TableElement.Text,
    },
    { value: "request_reason", label: "Reason", payload: TableElement.Text },
    { value: "status_name", label: "Status", payload: TableElement.Tag },
    { value: "create/update", label: "Created/Updated", payload: TableElement.Log },
  ] as LabelValue[];
  const overtimeColumns = [
    { value: "effectivity_date", label: "Date", payload: TableElement.Date },
    { value: "branch_name", label: "Branch", payload: TableElement.Text },
    { value: "ot_time", label: "Ot", payload: TableElement.Number },
    { value: "request_reason", label: "Reason", payload: TableElement.Text },
    { value: "status_name", label: "Status", payload: TableElement.Tag },
    { value: "create/update", label: "Created/Updated", payload: TableElement.Log },
  ] as LabelValue[];
  const amendmentColumns = [
    { value: "effectivity_date", label: "Date", payload: TableElement.Date },
    { value: "branch_name", label: "Branch", payload: TableElement.Text },
    {
      value: "amend_current_clockin",
      label: "Clockin",
      payload: TableElement.DateTime,
    },
    {
      value: "amend_current_clockout",
      label: "Clockout",
      payload: TableElement.DateTime,
    },
    {
      value: "amend_clockin",
      label: "New Clockin",
      payload: TableElement.DateTime,
    },
    {
      value: "amend_clockout",
      label: "New Clockout",
      payload: TableElement.DateTime,
    },
    { value: "request_reason", label: "Reason", payload: TableElement.Text },
    { value: "status_name", label: "Status", payload: TableElement.Tag },
    { value: "create/update", label: "Created/Updated", payload: TableElement.Log },
  ] as LabelValue[];
  const changeShiftColumns = [
    { value: "effectivity_date", label: "Date", payload: TableElement.Date },
    { value: "branch_name", label: "Branch", payload: TableElement.Text },
    {
      value: "shift_current_schedule_obj",
      label: "Shift Current Schedule",
      payload: TableElement.ShiftDetail,
    },
    {
      value: "shift_schedule_obj",
      label: "Shift New Schedule",
      payload: TableElement.ShiftDetail,
    },
    { value: "request_reason", label: "Reason", payload: TableElement.Text },
    { value: "status_name", label: "Status", payload: TableElement.Tag },
    { value: "create/update", label: "Created/Updated", payload: TableElement.Log },
  ] as LabelValue[];
  const breakAmendColumns = [
    { value: "effectivity_date", label: "Date", payload: TableElement.Date },
    { value: "branch_name", label: "Branch", payload: TableElement.Text },
    {
      value: "is_break_permision",
      label: "Is Break Permission",
      payload: TableElement.YesNo,
    },
    {
      value: "amend_break_start",
      label: "Start Break",
      payload: TableElement.DateTime,
    },
    {
      value: "amend_break_end",
      label: "End Break",
      payload: TableElement.DateTime,
    },
    { value: "status_name", label: "Status", payload: TableElement.Tag },
    { value: "create/update", label: "Created/Updated", payload: TableElement.Log },
  ] as LabelValue[];

  if (isAll) {
    const person = {
      value: "person_first_name",
      label: "Employee",
      payload: TableElement.Name,
    } as LabelValue;

    leaveColumns.unshift(person);
    amendmentColumns.unshift(person);
    overtimeColumns.unshift(person);
    changeShiftColumns.unshift(person);
    breakAmendColumns.unshift(person);
  }

  const displayColumn = (column: any, cell: LabelValue) => {
    const cellValue = column[cell.value];
    switch (cell.payload) {
      case TableElement.Date:
        return <div>{formatDate(cellValue)}</div>;
      case TableElement.DateTime:
        return <div>{formatDateTimeV2(cellValue)}</div>;
      case TableElement.Name:
        const name = `${column["person_first_name"]} ${column["person_last_name"]}`;
        return <div>{name}</div>;
      case TableElement.YesNo:
        const yesnoValue = cellValue === 1 || !!cellValue ? "Yes" : "No";
        return <div>{yesnoValue}</div>;
      case TableElement.Tag:
        let severity = getSeverity(cellValue || "PENDING");
        return <div>{displayTag(cellValue, severity)}</div>;
      case TableElement.Log:
        const createdAt = (
          <TextBlock
            label="Created At"
            value={formatDateTimeV2(column["created_at"])}
          ></TextBlock>
        );
        const createdBy = (
          <TextBlock
            label="Created By"
            value={`${column["created_first_name"]} ${column["created_last_name"]}`}
          ></TextBlock>
        );
        const updatedAt = (
          <TextBlock
            label="Updated At"
            value={formatDateTimeV2(column["updated_at"])}
          ></TextBlock>
        );
        const updatedBy = (
          <TextBlock
            label="Updated By"
            value={`${column["updated_first_name"]} ${column["updated_last_name"]}`}
          ></TextBlock>
        );
        return (
          <div>
            {createdAt} {createdBy} {updatedAt} {updatedBy}
          </div>
        );
      case TableElement.ShiftDetail:
        let cellObject = cellValue;

        if (typeof cellValue === "string") {
          cellObject = JSON.parse(cellValue);
        }

        const shiftName = cellObject?.shift_type_description
          ? `[${cellObject?.shift_type_description}]`
          : null;
        const shiftTime =
          cellObject?.start && cellObject?.end
            ? `${cellObject?.start}-${cellObject?.end}`
            : null;
        return (
          <div>
            {shiftName} {shiftTime}
          </div>
        );
      default:
        return <div>{cellValue}</div>;
    }
  };

  return {
    leaveColumns,
    overtimeColumns,
    amendmentColumns,
    changeShiftColumns,
    breakAmendColumns,
    displayColumn,
  };
}
