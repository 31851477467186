import { ReactNode } from "react";
import "./stat-card.scss";
import classNames from "classnames";

export interface StatCardProps {
  label: string;
  value: string | number;
  icon?: ReactNode;
  cardClass?: string;
  onClick?: (label: string, value: string | number) => void;
}

export function StatCard({
  label,
  value,
  icon,
  cardClass,
  onClick,
}: StatCardProps) {
  return (
    <div
      id="StatCard"
      className={classNames(
        "stat-card flex md:flex-wrap gap-4 items-center shadow-md p-6 rounded-md",
        cardClass
      )}
      onClick={() => {
        if (onClick) {
          onClick(label, value);
        }
      }}
    >
      {!!icon && icon}
      <section className="flex flex-col">
        <h2>{value}</h2>
        <small>{label}</small>
      </section>
    </div>
  );
}

export default StatCard;
