import { FieldValues, Path, useFormContext } from "react-hook-form";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DropdownFilterEvent } from "primereact/dropdown";

import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFormDropdownRoleContext } from "./form-dropdown-role.context";

export interface FormDropdownRoleProps<T extends FieldValues> {
  name: Path<T>;
  allowCreate?: boolean;
}

export function FormDropdownRole<T extends FieldValues>({
  name,
  allowCreate = false,
}: FormDropdownRoleProps<T>) {
  const hintText = allowCreate
    ? `Select from the created holidayType dropdown. Otherwise, hit ENTER to create a new holidayType. If the holidayType doesn't exist select N/A`
    : "";
  const { control } = useFormContext<T>();
  const {
    sidebar,
    newRole,
    mappedRole,
    isCreating,
    setSidebar,
    setFilter,
    setNewRole,
    handleFilterInput,
    handleAdd,
  } = useFormDropdownRoleContext();
  
  return (
    <div className="role">
      {newRole && (
        <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
          <h2>Create new role</h2>
          <p>
            You are creating a new role. Please, fill the fields to create a
            new role and apply to current item creation.
          </p>
          <div className="flex flex-col gap-2 mt-4">
            <InputText
              placeholder="Role Name"
              value={newRole.name}
              onChange={(e: any) =>
                setNewRole({ ...newRole, name: e.target.value })
              }
            />
            <InputText
              placeholder="Role Description"
              value={newRole.description}
              onChange={(e: any) =>
                setNewRole({ ...newRole, description: e.target.value })
              }
            />

            <Button
              label="Create"
              onClick={handleAdd}
              className="block"
              disabled={isCreating}
            />
          </div>
        </Sidebar>
      )}
      <DropdownControl<T>
        control={control}
        name={name}
        label="Role"
        options={ mappedRole }
        containerClassName="mb-9"
        className="w-full md:w-3/4"
        placeholder="Enter your role"
        hint={hintText}
        filter
        onFilter={(e: DropdownFilterEvent) => setFilter(e.filter)}
        onKeyDown={allowCreate ? handleFilterInput : () => {}}
      />
    </div>
  );
}

export default FormDropdownRole;