import { Outlet } from "react-router-dom";

import { FilterDepartmentProvider } from "./filter-department/filter-department.context";
import './department.scss';

export function Department() {
  return (
    <div id="Department" className="department">
       <FilterDepartmentProvider>
        <Outlet />
      </FilterDepartmentProvider>
    </div>
  );
}

export default Department;
