import "./print-qr.scss";
import QRCode from "react-qr-code";

export interface PrintQrProps {
  name: string;
  code: string;
  department: string;
  position: string;
}

export function PrintQr({ name, code, department, position }: PrintQrProps) {
  const qrValue = `${code}:${name}`;
  return (
    <div id="PrintQr" className="print-qr">
      <style type="text/css" media="print">
        {
          "\
      @page { size: 2.83in 4.02in; }\
    "
        }
      </style>
      <section className=" flex justify-center items-center flex-col gap-4 h-screen">
        <QRCode value={qrValue} size={150} />
        <div className="flex flex-col items-center">
          <h1>{name}</h1>
          <small>{department}</small>
          <small>{position}</small>
        </div>
      </section>
    </div>
  );
}

export default PrintQr;
