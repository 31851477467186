import { Outlet } from "react-router-dom";

import { FilterQuestionnaireProvider } from "./filter-questionnaire/filter-questionnaire.context";
import "./questionnaire.scss";
import { FormDropdownJobTitleProvider } from "@domain/job-title/form-dropdown-job-title/form-dropdown-job-title.context";

export function Questionnaire() {
  return (
    <div id="Questionnaire" className="questionnaire">
      <FilterQuestionnaireProvider>
        <FormDropdownJobTitleProvider>
          <Outlet />
        </FormDropdownJobTitleProvider>
      </FilterQuestionnaireProvider>
    </div>
  );
}

export default Questionnaire;
