import "./list-employee-request.scss";

import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetEmployeeRequest } from "@core/query/employee-request.query";
import { GetEmployeeRequestModel } from "@core/model/query-model-employee-request";
import { TableSort } from "@core/model/table-sort";
import { Dropdown } from "primereact/dropdown";
import { useFormDropdownRequestType } from "@domain/request-type/form-dropdown-request-type/form-dropdown-request-type.hook";
import { LabelValue } from "@shared/models/label-value.interface";
import { DesignatedRequestType } from "@domain/request-type/form-dropdown-request-type/designated-request-type.enum";
import { useListEmployeeRequestColumn } from "./list-employee-request-column";
import { useUserIdentityContext } from "@core/services/user-identity.context";
import {
  EmployeeRequestControllerGet200Response,
  GetEmployeeRequestDto,
} from "@api/api";
import { groupBy } from "lodash-es";
import { CiClock2 } from "react-icons/ci";
import StatCard from "@shared/ui/stat-card/stat-card";
import { IoIosPaper } from "react-icons/io";
import { FaUmbrellaBeach } from "react-icons/fa";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdOutlineCoffee } from "react-icons/md";
import { useFormDropdownBranch } from "@domain/branch/form-dropdown-branch/form-dropdown-branch.hook";

export interface EmployeeRequestProps {
  isAll?: boolean; // TRUE = for all employees
}
export function EmployeeRequest({ isAll = false }: EmployeeRequestProps) {
  // THIS IS THE LOCAL DECLARATION
  const { mappedBranch } = useFormDropdownBranch();
  const navigate = useNavigate();
  const {
    leaveColumns,
    overtimeColumns,
    amendmentColumns,
    changeShiftColumns,
    breakAmendColumns,
    displayColumn,
  } = useListEmployeeRequestColumn(isAll);
  const { getDesignationMapping } = useFormDropdownRequestType();
  const { personId } = useParams();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const { getUser, userIsAdmin } = useUserIdentityContext();
  const [selectedBranch, setSelectedBranch] = useState();

  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState<LabelValue[]>([]);
  const designatedRequestType = getDesignationMapping();
  const [stats, setStats] = useState<Record<string, GetEmployeeRequestDto[]>>(
    {}
  );

  // THIS IS THE QUERY DECLARATION
  const shouldFetch = useCallback(
    () => (isAll ? !!selectedRequestType : !!personId && !!selectedRequestType),
    [selectedRequestType]
  );
  const getFilters = useCallback(() => {
    const userBranch = getUser().branchId;
    const currentFilters = {} as any;
    if (!!personId) {
      currentFilters.user_code = personId;
    }
    if (!!selectedRequestType) {
      currentFilters.request_type = selectedRequestType;
    }
    if (!!userBranch && !userIsAdmin()) {
      currentFilters.branch_code = userBranch;
    }
    if (!!userIsAdmin() && selectedBranch) {
      currentFilters.branch_code = selectedBranch;
    }
    return currentFilters;
  }, [personId, selectedRequestType, selectedBranch]);

  const handleApiGetSuccess = (
    response: EmployeeRequestControllerGet200Response
  ) => {
    if (response.data?.length === 0) {
      return;
    }

    const categorized = groupBy(
      response.data,
      (x) => x.request_type_name
    ) as unknown as Record<string, GetEmployeeRequestDto[]>;
    setStats(categorized);
  };
  useQyGetEmployeeRequest(
    searchTerm,
    999999,
    0,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
    handleApiGetSuccess
  );

  const {
    data: employeeRequestResponse,
    isLoading,
    isError,
    error,
  } = useQyGetEmployeeRequest(
    searchTerm,
    rowLimit,
    pageNumber,
    sort,
    getFilters(),
    undefined,
    undefined,
    undefined,
    shouldFetch()
  );

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetEmployeeRequestModel) => {
    navigate(`${item.code}`);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleNew = () => {
    navigate(`new`);
  };
  const handleRequestType = (selected: string) => {
    setSelectedRequestType(selected);
    const selectedObject = designatedRequestType.filter(
      (x) => x.value === selected
    )[0];
    const isOvertime =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.Overtime) >= 0;
    const isDtrAmendment =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.DtrAmendment) >= 0;
    const isLeave =
      selectedObject.label.toLowerCase().indexOf(DesignatedRequestType.Leave) >=
      0;
    const isChangeShift =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.ChangeShift) >= 0;
    const isBreakAmendment =
      selectedObject.label
        .toLowerCase()
        .indexOf(DesignatedRequestType.BreakAmendment) >= 0;

    if (isOvertime) {
      setVisibleColumns(overtimeColumns);
    } else if (isDtrAmendment) {
      setVisibleColumns(amendmentColumns);
    } else if (isLeave) {
      setVisibleColumns(leaveColumns);
    } else if (isChangeShift) {
      setVisibleColumns(changeShiftColumns);
    } else if (isBreakAmendment) {
      setVisibleColumns(breakAmendColumns);
    }
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const statsElement = (
    <section className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
      <StatCard
        label="Overtime"
        value={stats?.OVERTIME?.length || 0}
        icon={
          <div className="bg-blue-200 p-4 rounded-lg">
            <CiClock2 />
          </div>
        }
      />
      <StatCard
        label="DTR Amendment"
        value={stats?.DTR_AMENDMENT?.length || 0}
        icon={
          <div className="bg-green-200 p-4 rounded-lg">
            <IoIosPaper />
          </div>
        }
      />
      <StatCard
        label="Leave"
        value={stats?.LEAVE?.length || 0}
        icon={
          <div className="bg-orange-200 p-4 rounded-lg">
            <FaUmbrellaBeach />
          </div>
        }
      />
      <StatCard
        label="Change Shift"
        value={stats?.CHANGE_SHIFT?.length || 0}
        icon={
          <div className="bg-yellow-200 p-4 rounded-lg">
            <HiOutlineOfficeBuilding />
          </div>
        }
      />
      <StatCard
        label="Break Amendment"
        value={stats?.BREAK_AMENDMENT?.length || 0}
        icon={
          <div className="bg-violet-200 p-4 rounded-lg">
            <MdOutlineCoffee />
          </div>
        }
      />
    </section>
  );
  const branchElement = (
    <div className="flex flex-col">
      <Dropdown
        value={selectedBranch}
        onChange={(e) => setSelectedBranch(e.value)}
        options={mappedBranch}
        optionLabel="label"
        placeholder="Select a branch"
        className="w-full md:w-14rem"
        filter
      />
    </div>
  );
  const requestTypeFilter = (
    <Dropdown
      value={selectedRequestType}
      onChange={(e) => handleRequestType(e.value)}
      options={designatedRequestType}
      optionLabel="label"
      placeholder="Select request type"
      className="w-full md:w-14rem"
    />
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <div>{requestTypeFilter}</div>
        {userIsAdmin() ? branchElement : null}
      </div>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{employeeRequestResponse?.count}</b>
      </h5>

      <DataTable
        value={employeeRequestResponse?.data}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord(e.value)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        {visibleColumns.map((cell) => (
          <Column
            key={cell.value}
            body={(col) => displayColumn(col, cell)}
            header={cell.label}
          />
        ))}
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={employeeRequestResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="EmployeeRequest" className="employee-request">
      <HeaderContent title="Employee Request">
        <span className="flex gap-2">
          <Button label="New" onClick={handleNew} />
        </span>
      </HeaderContent>

      <div className="p-7">
        {statsElement}
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default EmployeeRequest;
