import "./new-employee-shift.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useQyCreateEmployeeShift } from "@core/query/employee-shift.query";
import {
  EmployeeShiftFormRule,
  EmployeeShiftFormSchema,
} from "@core/form/form.rule";
import { employeeShiftFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormEmployeeShift from "../form-employee-shift/form-employee-shift";
import { useState } from "react";
import { MdClear } from "react-icons/md";
import { useFormDropdownShiftContext } from "@domain/shift/form-dropdown-shift/form-dropdown-shift.context";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import ListItem from "@shared/ui/list-item/list-item";
import classNames from "classnames";

export interface NewEmployeeShiftDefaultFormProps {
  title?: string;
  bucketCode?: string;
  userCode?: string;
  day?: string;
  effectivityDate?: string;
  onNewSuccess?: () => void;
}
export interface NewEmployeeShiftProps {
  standalone?: boolean; // If TRUE then it is not used in app route
  defaultFormProps?: NewEmployeeShiftDefaultFormProps;
}

export function NewEmployeeShift({
  standalone,
  defaultFormProps,
}: NewEmployeeShiftProps) {
  // LOCAL DECLARATION
  const { selectedPersonData } = useFormDropdownPersonContext();
  const { mappedShift } = useFormDropdownShiftContext();
  const navigate = useNavigate();
  const { mapCreateEmployeeShift } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();
  const [shifts, setShifts] = useState<EmployeeShiftFormSchema[]>([]);

  // QUERIES
  const handleApiSuccess = () => {
    showSuccess(`New Employee Shift is created`);
    navigate(`../`);
    if (standalone && defaultFormProps?.onNewSuccess) {
      defaultFormProps?.onNewSuccess();
    }
  };
  const { mutate: addEmployeeShift, isLoading: isCreating } =
    useQyCreateEmployeeShift(handleApiSuccess);

  // LOCAL FUNCTIONS
  const defaultValues = standalone
    ? {
        ...employeeShiftFormDefault,
        bucketCode: defaultFormProps?.bucketCode,
        userCode: defaultFormProps?.userCode,
        day: defaultFormProps?.day,
        effectivityDate: defaultFormProps?.effectivityDate,
      }
    : employeeShiftFormDefault;
  const formMethod = useForm<EmployeeShiftFormSchema>({
    defaultValues,
    resolver: zodResolver(EmployeeShiftFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeShiftFormSchema) => {
    const defaultFormData = {
      userCode: defaultFormProps?.userCode,
      bucketCode: defaultFormProps?.bucketCode,
    };

    if (!standalone) {
      defaultFormData.userCode = selectedPersonData?.code;
      defaultFormData.bucketCode = selectedPersonData?.bucket.code;
    }

    const shiftLabel =
      mappedShift.filter((x) => x.value === form.shiftCode)?.[0]?.label || "-";
    const updatedForm = {
      ...form,
      userCode: defaultFormData.userCode,
      bucketCode: defaultFormData.bucketCode,
      shiftDescription: shiftLabel,
    } as EmployeeShiftFormSchema;

    const currentDays = shifts.map((item) => item.day);
    const dayExistInShifts = currentDays.indexOf(form.day) >= 0;

    if (dayExistInShifts) {
      showWarning(`${form.day} is already added`);
      return;
    }
    setShifts((value) => [...value, updatedForm]);
  };
  const handleValidateError = (err: FieldErrors<EmployeeShiftFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const submit = () => {
    const formData = shifts.map((item) => mapCreateEmployeeShift(item));

    // if (formData.length !== 7) {
    //   showWarning(`You need to create a 7 day shift`);
    //   return;
    // }
    const payload = { shift: formData };
    addEmployeeShift(payload);
  };
  const handleClear = (shift: EmployeeShiftFormSchema) => {
    const newShifts = shifts.filter((item) => item.day !== shift.day);
    setShifts(newShifts);
  };

  // HTML
  const addedShift = (
    <section className="flex flex-col gap-2">
      <h5>Added Shifts:</h5>
      {shifts.map((item, id) => (
        <ListItem key={id}>
          <section>
            <h3>{item.day}</h3>
            <span>{item.shiftDescription}</span>
          </section>
          <span className="cursor-pointer" onClick={() => handleClear(item)}>
            <MdClear />
          </span>
        </ListItem>
      ))}
    </section>
  );

  return (
    <div id="EmployeeShift" className="employee-shift">
      <HeaderContent
        title={defaultFormProps?.title || "New Employee Shift"}
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <section className="flex gap-2 items-center">
          <Button
            className="block"
            label="Add"
            disabled={isCreating}
            severity="secondary"
            onClick={handleSubmit(handleValidate, handleValidateError)}
          ></Button>
          <Button
            className="block"
            label="Save"
            disabled={shifts.length === 0 || isCreating}
            onClick={submit}
          ></Button>
        </section>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <div
            className={classNames(
              "grid grid-cols-1 gap-2 mb-4 bg-white",
              !standalone ? "md:grid-cols-2" : ""
            )}
          >
            <section className="">
              <FormEmployeeShift />
            </section>
            {shifts.length > 0 ? addedShift : null}
          </div>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeShift;
