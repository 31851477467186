import "./form-employee-attachment.scss";
import { useFormContext } from "react-hook-form";
import { EmployeeAttachmentFormSchema } from "@core/form/form.rule";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import FormDropdownAttachmentType from "@domain/attachment-type/form-dropdown-attachment-type/form-dropdown-attachment-type";

type InputFormSchema = EmployeeAttachmentFormSchema;
export function EmployeeAttachment() {
  const { control } = useFormContext<EmployeeAttachmentFormSchema>();
  return (
    <div id="EmployeeAttachment" className="employee-attachment">
      <FormDropdownAttachmentType<InputFormSchema> name="attachmentType" />
      <ImageControl<InputFormSchema>
        control={control}
        name="file"
        label="File Attachment"
      />
      {/* <InputControl<InputFormSchema>
        control={control}
        name="mimeType"
        label="Mime Type"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter mimeType"
      /> */}
    </div>
  );
}

export default EmployeeAttachment;
