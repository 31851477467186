import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { LeaveCategoryFormRule, LeaveCategoryFormSchema } from "@core/form/form.rule";
import { useFormDefaultLeaveCategory } from "@core/form/form-default-leave-category.hook";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { ActionEnum } from "@core/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdateLeaveCategory, 
  useQyGetLeaveCategoryById, 
  useQyDeleteLeaveCategory 
} from "@core/query/leave-category.query";
import { GetResponseLeaveCategoryModel } from "@core/model/query-model-leave-category";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditLeaveCategory() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultLeaveCategory();
  const navigate = useNavigate();
  const { mapUpdateLeaveCategory, mapDeleteLeaveCategory } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { leaveCategoryId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID LeaveCategory
  const handleGetApiSuccess = (data: GetResponseLeaveCategoryModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("name", responseData?.name || "");
      setValue("description", responseData?.description || "");
    }
  };
  const {
    data: leaveCategoryResponse,
    isLoading,
    isError: leaveCategoryError,
  } = useQyGetLeaveCategoryById(leaveCategoryId || "", handleGetApiSuccess);
  const leaveCategoryData = leaveCategoryResponse?.data?.[0];

  // API UPDATE LeaveCategory
  const handleUpdateApiSuccess = () => {
    showSuccess("LeaveCategory updated successfully");
  };
  const { mutate: updateLeaveCategory, isLoading: isEditLoading } = useQyUpdateLeaveCategory(
    handleUpdateApiSuccess
  );

  // API DELETE LeaveCategory
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("LeaveCategory deleted successfully");
  };
  const { mutate: deleteLeaveCategory } = useQyDeleteLeaveCategory(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<LeaveCategoryFormSchema>({
    defaultValues: getDefault(leaveCategoryData),
    resolver: zodResolver(LeaveCategoryFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: LeaveCategoryFormSchema) => {
    if (!leaveCategoryData) {
      throw new Error("No leaveCategory data");
    }

    const formData = mapUpdateLeaveCategory(form, leaveCategoryData.code);
    updateLeaveCategory(formData);
  };
  const handleValidateError = (err: FieldErrors<LeaveCategoryFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!leaveCategoryData) {
      throw new Error("No leaveCategory data found");
    }

    const form = mapDeleteLeaveCategory(leaveCategoryData.code);
    deleteLeaveCategory(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    leaveCategoryId,
    leaveCategoryData,
    isLoading,
    leaveCategoryError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}