import "./edit-daily-time-record.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormDailyTimeRecord from "../form-daily-time-record/form-daily-time-record";
import { useEditDailyTimeRecord } from "./edit-daily-time-record.hook";

export function DailyTimeRecord() {
  const { formMethod, actions, handleBack, handleAction } =
    useEditDailyTimeRecord();

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
        <FormDailyTimeRecord />
      </section>
    </div>
  );

  return (
    <div id="DailyTimeRecord" className="daily-time-record">
      <HeaderContent
        title={"Edit Daily Time Record"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>{mainContent}</FormProvider>
      </section>
    </div>
  );
}

export default DailyTimeRecord;
