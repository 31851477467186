import { EmployeeScoreArrayFormSchema } from "@core/form/form.rule";
import "./form-array-employee-score.scss";
import { useFormDropdownQuestionnaire } from "@domain/questionnaire/form-dropdown-questionnaire/form-dropdown-questionnaire.hook";
import { useFieldArray, useFormContext } from "react-hook-form";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import { GetResponseQuestionnaireModel } from "@core/model/query-model-questionnaire";
import InputRatingControl from "@shared/ui/hook-form/input-rating-control/input-rating-control";

export interface FormArrayEmployeeScoreProps {
  personId: string;
  branchId: string;
  bucketCode: string;
  jobId: string;
}

type InputFormSchema = EmployeeScoreArrayFormSchema;
export function FormArrayEmployeeScore({
  personId,
  branchId,
  bucketCode,
  jobId,
}: FormArrayEmployeeScoreProps) {
  const { control } = useFormContext<EmployeeScoreArrayFormSchema>();
  const { fields, append } = useFieldArray({
    control,
    name: "scores",
  });

  const handleApiSuccess = (data: GetResponseQuestionnaireModel) => {
    data.data?.forEach((item) => {
      append({
        questionLabel: item.name,
        questionCode: item.code,
        branchCode: branchId,
        userCode: personId,
        bucketCode,
        score: 0,
        effectivityDate: new Date(),
      });
    });
  };
  useFormDropdownQuestionnaire({
    recordFilter: {
      job_title: jobId || "",
    },
    onApiSuccess: handleApiSuccess,
  });

  return (
    <div id="FormArrayEmployeeScore" className="form-array-employee-score">
      {fields.length > 0 ? (
        fields.map((field, id) => (
          <section key={id}>
            <InputRatingControl<InputFormSchema>
              control={control}
              name={`scores.${id}.score`}
              label={field.questionLabel}
              className="w-full md:w-3/4"
              containerClassName="pb-2"
            />
          </section>
        ))
      ) : (
        <h3>
          There is no fields for current position. Please add in Settings -
          Score
        </h3>
      )}
    </div>
  );
}

export default FormArrayEmployeeScore;
