import { Outlet } from "react-router-dom";

import { FilterEmployeeScoreProvider } from "./filter-employee-score/filter-employee-score.context";
import "./employee-score.scss";

export function EmployeeScore() {
  return (
    <div id="EmployeeScore" className="employee-score">
      <FilterEmployeeScoreProvider>
        <Outlet />
      </FilterEmployeeScoreProvider>
    </div>
  );
}

export default EmployeeScore;
