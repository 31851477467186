import "./form-faq.scss";
import { useFormContext } from "react-hook-form";
import { FaqFormSchema } from "@core/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import YoutubeEmbed from "../youtube-embed/youtube-embed";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";

type InputFormSchema = FaqFormSchema;
export function FormFaq() {
  const { control, getValues } = useFormContext<FaqFormSchema>();
  const youtubeLink = getValues("link");
  return (
    <div id="Faq" className="faq">
      <InputControl<InputFormSchema>
        control={control}
        name="title"
        label="Title"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter title"
      />
      <InputTextareaControl<InputFormSchema>
        control={control}
        name="description"
        label="Description"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter description"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="link"
        label="Video ID"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter video id"
      />
      {youtubeLink && (
        <YoutubeEmbed videoId={youtubeLink} height="500" width="600" />
      )}
    </div>
  );
}

export default FormFaq;
